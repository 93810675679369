import dayjs from 'dayjs';
import { message } from 'antd';
import {
  chainsModal,
  formWorkerModal,
  showModal,
  qrCodeModal,
  fileUploadModal,
  smsDetailsModal,
  downloadTraceModal,
  historyTableModal,
  conformityFormModal,
  CUSTOM_MODAL_WIDTH,
  PRODUCTS_ADD_TITLE,
  PRODUCTS_EDIT_TITLE,
  CHAIN_DETAILS_TITLE,
  QR_CODE_TITLE,
  USERS_EDIT_TITLE,
  COMPANIES_EDIT_TITLE,
  TRACES_SHOW_TITLE,
  TRACES_EDIT_TITLE,
  TRACES_HISTORY_TITLE,
  TRACES_SHOW_VERSION_TITLE,
  UPLOADS_SHOW_TITLE,
  UPLOADS_EDIT_TITLE,
  CUSTOMLIST_SHOW_TITLE,
  CUSTOMLIST_EDIT_TITLE,
  CUSTOMLIST_ADD_TITLE,
  SMSCOMFIRMATION_SHOW_TITLE,
  WORKFLOW_SHOW_TITLE,
  workflowObjectHistoryTableModal,
  WORKFLOW_OBJECT_HISTORY_TITLE,
  contactSupplierModal,
} from '../constants';
import {
  USERS_MODAL,
  CUSTOMLIST_MODAL,
  COMPANIES_MODAL,
  TRACES_MODAL,
  CHAINS_MODAL,
  PRODUCTS_MODAL,
  QR_CODE_MODAL,
  UPLOAD_MODAL,
  FILE_UPLOAD_MODAL,
  SMS_MODAL,
  HISTORY_MODAL,
  HISTORY_VERSION_MODAL,
  WORKFLOW_MODAL,
  WORKFLOW_OBJECT_HISTORY_MODAL,
  WORKFLOW_OBJECT_VERSION_MODAL,
} from 'components/Modal/constants';
import show_width_handler from '../../../lib/helpers/width_handlers/ShowWidthHandler';
import {
  ADD_ITEM_ACTION,
  ADD_ORGANIZATION_ACTION,
  ADD_PRODUCT_ACTION,
  ADD_TRACE_ACTION,
  ADD_UPLOAD_ACTION,
  ADD_USER_ACTION,
  CHAIN_GRAPH_ACTION,
  CHAIN_TABLE_ACTION,
  EDIT_COMPANY_ACTION,
  EDIT_ITEM_ACTION,
  EDIT_OBJECT_ACTION,
  EDIT_PRODUCT_ACTION,
  EDIT_TRACE_ACTION,
  EDIT_UPLOAD_ACTION,
  EDIT_USER_ACTION,
  SHOW_ITEM_ACTION,
  SHOW_OBJECT_ACTION,
  SHOW_LOG_UPLOAD_ACTION,
  SHOW_SMS_ACTION,
  SHOW_TRACE_ACTION,
  SHOW_FILE_UPLOAD_ACTION,
  SHOW_UPLOAD_ACTION,
  QR_CODE_PREVIEW,
  RESEND_ALL_SMS_CONFIRMATION_ACTION,
  SHOW_SMS_CONFIRMATION_ACTION,
  SHOW_USER_ACTION,
  RESEND_SMS_CONFIRMATION_ACTION,
  HISTORY_TRACE_ACTION,
  SHOW_TRACE_VERSION_ACTION,
  SHOW_ANALYSIS_ACTION,
  VALIDATE_DATA_ACTION,
  INVALIDATE_DATA_ACTION,
  VALIDATE_ALL_ACTION,
  EDIT_WORKFLOW_ACTION,
  SHOW_WORKFLOW_ACTION,
  ADD_WORKFLOW_ACTION,
  WORKFLOW_OBJECT_HISTORY_ACTION,
  WORKFLOW_OBJECT_VERSION_ACTION,
  SHOW_PRODUCT_STATUS,
  CONTACT_SUPPLIER,
  SHOW_TRACE_WITH_FETCH,
  EDIT_TRACE_WITH_FETCH,
} from '../actions';
import { define_width } from 'lib/util';
import { getCoherence } from 'lib/validations/util';
import {
  USERS_ADD_TITLE,
  COMPANIES_ADD_TITLE,
  UPLOADS_ADD_TITLE,
  TRACES_ADD_TITLE,
  ANALYSIS_SHOW_TITLE,
} from '../constants';
import { store } from 'store/nextStore';
import client from 'lib/apiClient';
import validationStatusHandler from './validationStatusHandler';
import { openGenericModalAction } from 'containers/GenericModal/reducer';

async function actionHandler(
  action: string,
  handleSubmit: Function,
  record: Object | any | undefined,
  args: any
) {
  const {
    data,
    traceId,
    traceType,
    blockNumber,
    txHash,
    id,
    company_id,
    company,
    name,
    kind,
    trace_type,
    created_at,
    displayTraceType,
    object_type,
  } = record;

  const {
    handleSubTitle,
    objectType,
    chainTableName,
    formSchema,
    user,
    errorMessage,
    t,
    historyObjectId
  } = args;
  const trace_data = { ...data, traceId, blockNumber, txHash };
  const businessName = args?.user?.business?.name;
  const isAdmin = user?.role === 'Admin';

  switch (action) {
    // USERS
    case ADD_USER_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: USERS_ADD_TITLE(t),
            prefixId: USERS_MODAL,
          },
          data: {
            type: 'user',
            user: args?.user,
            isEdit: false,
            formStep: 'formSelection',
            extraKeyName: 'company_id',
            selectLabel: `${t('form.selectNewUserOrganization')}:`,
            handleSubTitle: (title: string) => handleSubTitle(title, action, t),
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({ data, actions }),
          },
        })
      );
      break;
    case EDIT_USER_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${USERS_EDIT_TITLE(t)} ${company?.name}`,
            prefixId: USERS_MODAL,
          },
          data: {
            type: 'user',
            isEdit: true,
            userCompany: company?.name,
            userValues: record,
            formStep: 'formFilling',
            formType: company_id,
            extraKeyName: 'company_id',
            selectLabel: `${t('form.selectNewUserOrganization')}:`,
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({
                data: { ...data, id },
                actions,
                user: args?.user,
              }),
          },
        })
      );
      break;
    // CUSTOM LISTS
    case SHOW_OBJECT_ACTION:
    case SHOW_ITEM_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: downloadTraceModal,
          configuration: {
            title: `${CUSTOMLIST_SHOW_TITLE(t)} ${object_type || ''}`,
            width: CUSTOM_MODAL_WIDTH,
            prefixId: CUSTOMLIST_MODAL,
          },
          data: {
            data: record,
            isEdit: false,
          },
        })
      );
      break;
    case ADD_ITEM_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${CUSTOMLIST_ADD_TITLE(t)} ${objectType || ''}`,
            width: CUSTOM_MODAL_WIDTH,
            prefixId: CUSTOMLIST_MODAL,
          },
          data: {
            type: 'custom_list',
            listName: args?.path,
            isEdit: false,
            formStep: 'formFilling',
            formType: '',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data: { ...data },
                path: args?.path,
                actions,
              });
            },
          },
        })
      );
      break;
    case EDIT_OBJECT_ACTION:
    case EDIT_ITEM_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${CUSTOMLIST_EDIT_TITLE(t)} ${object_type || ''}`,
            width: CUSTOM_MODAL_WIDTH,
            prefixId: CUSTOMLIST_MODAL,
          },
          data: {
            type: 'custom_list',
            listName: args?.path,
            isEdit: true,
            userValues: record,
            formStep: 'formFilling',
            formType: '',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            title: record.file_name,
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data: { ...data, id },
                path: args?.path,
                actions,
              });
            },
          },
        })
      );
      break;
    // TRACES
    case SHOW_TRACE_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: downloadTraceModal,
          configuration: {
            title: `${TRACES_SHOW_TITLE(t)} ${displayTraceType || ''}`,
            width: define_width({
              target: show_width_handler.get_widths({ data: trace_data }),
            }),
            prefixId: TRACES_MODAL,
          },
          data: {
            data: trace_data,
            traceId,
            isEdit: false,
            isShowTrace: true,
            displayTraceType: displayTraceType,
          },
        })
      );
      break;
    case EDIT_TRACE_ACTION:
      const { traceType: formType } = data;
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${TRACES_EDIT_TITLE(t)} ${displayTraceType || ''}`,
            width: 1200,
            prefixId: TRACES_MODAL,
          },
          data: {
            user: args?.user,
            userValues: record.data,
            isEdit: true,
            isShowTrace: false,
            formStep: 'formFilling',
            type: 'trace',
            extraKeyName: 'traceType',
            traceId,
            displayTraceType: record.displayTraceType,
            created_at: record.displayDate,
            formType,
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data,
                traceId,
                created_at: record.displayDate,
                actions,
              });
            },
          },
        })
      );
      break;
    case ADD_TRACE_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: TRACES_ADD_TITLE(t),
            width: 1200,
            prefixId: TRACES_MODAL,
          },
          data: {
            user: args?.user,
            isEdit: false,
            formStep: 'formSelection',
            type: 'trace',
            extraKeyName: 'traceType',
            traceId,
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 1,
              },
            },
            handleSubTitle: (title: string) => handleSubTitle(title, action, t),
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({ data, actions });
            },
          },
        })
      );
      break;
    case HISTORY_TRACE_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: historyTableModal,
          configuration: {
            title: `${TRACES_HISTORY_TITLE(t)} ${displayTraceType || ''}`,
            prefixId: HISTORY_MODAL,
          },
          data: {
            traceId,
            displayTraceType: displayTraceType,
          },
        })
      );
      break;
    case SHOW_TRACE_VERSION_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: downloadTraceModal,
          configuration: {
            title: `${TRACES_SHOW_VERSION_TITLE(t)} ${displayTraceType || ''}`,
            width: define_width({
              target: show_width_handler.get_widths({ data: trace_data }),
            }),
            prefixId: HISTORY_VERSION_MODAL,
          },
          data: {
            data: trace_data,
            traceId,
            isEdit: false,
            isShowTrace: true,
          },
        })
      );
      break;
    case SHOW_TRACE_WITH_FETCH:
      if (record?.traceId === 'N/A') {
        message.error(errorMessage || t('errors.traceDoesNotHaveValidTraceId'))
      } else {
        store.dispatch(
          openGenericModalAction({
            name: downloadTraceModal,
            configuration: {
              title: TRACES_SHOW_TITLE(t),
              width: '650px',
            },
            data: {
              fetchTrace: true,
              isEdit: false,
              traceId: record?.traceId,
            },
          })
        );
      }
      break;
    case EDIT_TRACE_WITH_FETCH:
      if (record?.traceId === 'N/A') {
        message.error(errorMessage || t('errors.traceDoesNotHaveValidTraceId'))
      } else {
        try {
          const traceData = await client.getTraceById(record?.traceId);
          const { traceType: formType, displayTraceType } = traceData;

          store.dispatch(
            openGenericModalAction({
              name: formWorkerModal,
              configuration: {
                title: `${TRACES_EDIT_TITLE(t)} ${displayTraceType || ''}`,
                width: 1200,
                prefixId: TRACES_MODAL,
              },
              data: {
                user,
                userValues: JSON.parse(traceData?.data),
                isEdit: true,
                isShowTrace: false,
                formStep: 'formFilling',
                type: 'trace',
                extraKeyName: 'traceType',
                traceId: record?.traceId,
                displayTraceType,
                created_at: record.displayDate,
                formType,
                handleSubmit: (data: any, actions: any) => {
                  handleSubmit({
                    data,
                    traceId: record.traceId,
                    created_at: record.displayDate,
                    actions,
                    t,
                  });
                },
              },
            })
          );
        } catch (e) {
          console.log('Error in editTraceWithFetch action:', e);
          message.error(t('errors.somethingWentWrong'));
        }
      }
      break;
    // CHAINS
    case CHAIN_GRAPH_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: chainsModal,
          configuration: {
            title: `${CHAIN_DETAILS_TITLE(t)} ${traceType} ${traceId}`,
            prefixId: CHAINS_MODAL,
          },
          data: {
            mode: action,
            traceId,
            traceType,
            name: chainTableName,
          },
        })
      );
      break;
    case CHAIN_TABLE_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: chainsModal,
          configuration: {
            title: `${CHAIN_DETAILS_TITLE(t)} ${traceType} ${traceId}`,
            prefixId: CHAINS_MODAL,
          },
          data: {
            mode: action,
            traceId,
            traceType,
            name: chainTableName,
          },
        })
      );
      break;
    // COMPANY
    case EDIT_COMPANY_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${COMPANIES_EDIT_TITLE(t)} ${kind || ''}`,
            prefixId: COMPANIES_MODAL,
          },
          data: {
            isEdit: true,
            type: 'company',
            userValues: record,
            userCompany: name || '',
            formStep: 'formFilling',
            formType: kind,
            selectLabel: `${t('form.selectOrganizationKind')}:`,
            extraKeyName: 'kind',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({
                data: { ...data, id },
                actions,
              }),
          },
        })
      );
      break;
    case ADD_ORGANIZATION_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: COMPANIES_ADD_TITLE(t),
            prefixId: COMPANIES_MODAL,
          },
          data: {
            isEdit: false,
            type: 'company',
            formStep: 'formSelection',
            formType: kind,
            selectLabel: `${t('form.selectOrganizationKind')}:`,
            extraKeyName: 'kind',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubTitle: (title: string) => handleSubTitle(title, action, t),
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({ data, actions }),
          },
        })
      );
      break;
    // PRODUCTS
    case ADD_PRODUCT_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: PRODUCTS_ADD_TITLE(t),
            prefixId: PRODUCTS_MODAL,
          },
          data: {
            type: 'product',
            isEdit: false,
            formStep: 'formFilling',
            formType: '',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            selectLabel: `${t('form.chooseNewProductOrganization')}:`,
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({ data, actions }),
          },
        })
      );
      break;
    case EDIT_PRODUCT_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: PRODUCTS_EDIT_TITLE(t),
            prefixId: PRODUCTS_MODAL,
          },
          data: {
            type: 'product',
            isEdit: true,
            userValues: record,
            formStep: 'formFilling',
            formType: '',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            selectLabel: `${t('form.chooseNewProductOrganization')}:`,
            handleSubmit: (data: any, actions: any) =>
              handleSubmit({
                data: {
                  ...data,
                  id: record.id,
                },
                actions,
              }),
          },
        })
      );
      break;
    // QR CODE
    case QR_CODE_PREVIEW:
      store.dispatch(
        openGenericModalAction({
          name: qrCodeModal,
          configuration: {
            title: QR_CODE_TITLE(t),
            prefixId: QR_CODE_MODAL,
          },
          data: {
            url: args?.url,
            imageName: args?.imageName,
          },
        })
      );
      break;
    // UPLOADS
    case SHOW_UPLOAD_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: showModal,
          configuration: {
            title: `${UPLOADS_SHOW_TITLE(t)} ${trace_type || ''}`,
            width: 1200,
            prefixId: UPLOAD_MODAL,
          },
          data: {
            data: record,
            formType: record.trace_type,
          },
        })
      );
      break;
    case EDIT_UPLOAD_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: `${UPLOADS_EDIT_TITLE(t)} ${trace_type || ''}`,
            width: 700,
            prefixId: UPLOAD_MODAL,
          },
          data: {
            userValues: record,
            type: 'upload',
            formType: trace_type,
            isEdit: true,
            filter_params: args?.filter_params || { upload_type: 'trace' },
            user: args?.user,
            formStep: 'formFilling',
            extraKeyName: 'trace_type',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 1,
              },
            },
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data,
                traceId: record && record.id,
                actions,
              });
            },
          },
        })
      );
      break;
    case SHOW_FILE_UPLOAD_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: fileUploadModal,
          configuration: {
            title: `${t('form.uploadFile')} ${id || ''}`,
            width: 1200,
            prefixId: FILE_UPLOAD_MODAL,
          },
          data: {
            data: record,
            formType: trace_type,
            isShowFile: true,
            date: dayjs(created_at).format('DD MMM YYYY HH:mm'),
          },
        })
      );
      break;
    case SHOW_LOG_UPLOAD_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: fileUploadModal,
          configuration: {
            title: `${t('form.logFile')} ${id || ''}`,
            width: 1200,
            prefixId: FILE_UPLOAD_MODAL,
          },
          data: {
            data: record,
            formType: trace_type,
            isShowLog: true,
            date: dayjs(created_at).format('DD MMM YYYY HH:mm'),
          },
        })
      );
      break;
    case ADD_UPLOAD_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: UPLOADS_ADD_TITLE(t),
            width: 700,
            prefixId: UPLOAD_MODAL,
          },
          data: {
            type: 'upload',
            user: args?.user,
            formStep: 'formSelection',
            selectLabel: `${t('form.selectType')}`,
            isEdit: false,
            extraKeyName: 'trace_type',
            filter_params: args?.filter_params || { upload_type: 'trace' },
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 1,
              },
            },
            handleSubTitle: (title: string) => handleSubTitle(title, action, t),
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data,
                filter_params: args?.filter_params,
                actions,
              });
            },
          },
        })
      );
      break;
    // SMS
    case SHOW_SMS_CONFIRMATION_ACTION:
    case SHOW_SMS_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: smsDetailsModal,
          configuration: {
            title: `${id?.toString() || ''}`,
            prefixId: SMS_MODAL,
          },
          data: {
            data: record,
          },
        })
      );
      break;
    // SMS CONFIRMATION
    case RESEND_ALL_SMS_CONFIRMATION_ACTION:
      handleSubmit({
        filters: args?.filters,
        query: args?.searchField,
      });
      break;
    case SHOW_USER_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: showModal,
          configuration: {
            title: `${SMSCOMFIRMATION_SHOW_TITLE(t)}`,
            width: 900,
          },
          data: {
            isEdit: false,
            fetchTrace: true,
            traceId: record?.user_trace_id,
          },
        })
      );
      break;
    case RESEND_SMS_CONFIRMATION_ACTION:
      handleSubmit(record?.related_trace_id);
      break;
    // ANALYSIS
    case SHOW_ANALYSIS_ACTION:
      const res = await client.getTraceById(traceId);
      res &&
        store.dispatch(
          openGenericModalAction({
            name: showModal,
            configuration: {
              title: `${ANALYSIS_SHOW_TITLE(t)} ${res?.displayTraceType}`,
              width: 1100,
            },
            data: {
              isEdit: false,
              traceId,
              data: {
                ...JSON.parse(res?.data),
                traceId: res?.traceId,
                blockNumber: res?.blockNumber,
                txHash: res?.txHash,
              },
            },
          })
        );
      break;
    case VALIDATE_DATA_ACTION:
    case INVALIDATE_DATA_ACTION:
      handleSubmit({
        traceId,
        businessName,
        status: validationStatusHandler(action, businessName),
        validation: args?.user?.company?.kind === 'Admin',
      });
      break;
    case VALIDATE_ALL_ACTION:
      handleSubmit({
        data: args?.allData,
        status: validationStatusHandler(action, businessName),
        coherence: getCoherence(businessName),
        businessName,
      });
      break;
    // WORKFLOW
    case SHOW_WORKFLOW_ACTION:
      const workflowData = await client.getWorkflowById(record.id);

      store.dispatch(
        openGenericModalAction({
          name: downloadTraceModal,
          configuration: {
            title: WORKFLOW_SHOW_TITLE(t),
            prefixId: WORKFLOW_MODAL,
          },
          data: {
            data: workflowData?.data,
            scheme: workflowData?.scheme?.fields,
            id: record.id,
            isEdit: false,
            ordered: true,
          },
        })
      );
      break;
    case EDIT_WORKFLOW_ACTION:
      const workflowObject = await client.getWorkflowById(record.id);

      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: '',
            prefixId: WORKFLOW_MODAL,
          },
          data: {
            type: 'workflow',
            isEdit: true,
            userValues: workflowObject?.data,
            formStep: 'formFilling',
            formType: workflowObject?.workflow_id,
            defaultScheme: workflowObject?.scheme,
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubTitle: (title: string) => handleSubTitle(title, action, t),
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data,
                actions,
              });
            },
          },
        })
      );
      break;
    case ADD_WORKFLOW_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: formWorkerModal,
          configuration: {
            title: '',
            prefixId: WORKFLOW_MODAL,
          },
          data: {
            type: 'workflow',
            isEdit: false,
            formStep: 'formSelection',
            selectLabel: 'Select Type :',
            styles: {
              formFilling: {
                width: 400,
              },
            },
            view: {
              formSelection: {
                columns: 2,
              },
            },
            handleSubTitle: (title: string) => handleSubTitle(title, action, t),
            handleSubmit: (data: any, actions: any) => {
              handleSubmit({
                data,
                actions,
              });
            },
          },
        })
      );
      break;
    case WORKFLOW_OBJECT_HISTORY_ACTION:
      store.dispatch(
        openGenericModalAction({
          name: workflowObjectHistoryTableModal,
          configuration: {
            title: `${WORKFLOW_OBJECT_HISTORY_TITLE(t)}`,
            prefixId: WORKFLOW_OBJECT_HISTORY_MODAL,
          },
          data: {
            id,
          },
        })
      );
      break;
    case WORKFLOW_OBJECT_VERSION_ACTION:
      const versionData = await client.getWorkflowObjectVersion({
        historyObjectId,
        id: record.id,
      });

      store.dispatch(
        openGenericModalAction({
          name: downloadTraceModal,
          configuration: {
            title: WORKFLOW_SHOW_TITLE,
            prefixId: WORKFLOW_OBJECT_VERSION_MODAL,
          },
          data: {
            data: versionData?.data,
            scheme: versionData?.scheme?.fields,
            id: record.id,
            isEdit: false,
            ordered: true,
          },
        })
      );
      break;
      // CONFORMITY & QUALITY DASH
    case SHOW_PRODUCT_STATUS:
      store.dispatch(
        openGenericModalAction({
          name: conformityFormModal,
          configuration: {
            title: `${t('common.view')} :`,
            width: '850px',
          },
          data: {
            userValues: record,
            isEdit: isAdmin,
            type: 'conformity_dashboard',
            formSchema,
            handleSubmit: (data: any) => {
              handleSubmit({
                ...data,
              });
            },
          },
        })
      );
      break;
    case CONTACT_SUPPLIER:
      store.dispatch(
        openGenericModalAction({
          name: contactSupplierModal,
          configuration: {
            title: record.Company,
            width: '700px',
          },
          data: {
            data: record,
          },
        })
      );
      break;
    default:
      throw new Error(`Action ${action} not found`);
  }
}

export default actionHandler;
