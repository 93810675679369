import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import construction from 'images/construction.png';
import './styles.css';

class CommonNetworkFailure extends Component {
  componentWillUnmount() {
    const { cleanup } = this.props;
    cleanup();
  }

  render() {
    const { t } = this.props;
    
    const msg = t('errorPage.errorMaintenance');
    return (
      <div className={`common-network-failure-root`}>
        <div>
          <img
            src={construction}
            className={`common-network-failure-root-img`}
            alt="common network failure"
          />
        </div>
        <h1>{msg}</h1>
      </div>
    );
  }
}

CommonNetworkFailure.propTypes = {
  cleanup: PropTypes.func,
};

export default withTranslation()(CommonNetworkFailure);
