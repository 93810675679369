import { FC } from 'react';
import { Image } from 'antd';
import { SImageWrapper } from './styled-components';

interface FormImageProps {
  src: string;
}

const FormImage: FC<FormImageProps> = ({ src }) => {
  if (!src) return null;

  return (
    <SImageWrapper justify="center">
      <Image src={src} className="product-image" />
    </SImageWrapper>
  );
};

export default FormImage;
