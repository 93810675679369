import styled from 'styled-components';
import { Flex } from 'antd';

export const SImageWrapper = styled(Flex)`
  width: 100%;
  
  .product-image {
    width: 350px;
    border-radius: 10px;
  }

  .ant-image .ant-image-mask {
    border-radius: 10px;
  }
`;
