import styled from 'styled-components';
import { colors, font } from 'App/styleVariables';

export const SContentWrapper = styled.div`
  max-width: 380px;
  padding: 15px;

  .content {
    max-height: 250px;
    overflow-y: auto;
    white-space: pre-line;
    font-family: ${font};
  }

  .content-string {
    margin: 0 0 5px;
  }

  .close-button {
    font-size: ${({ theme }) => theme.button?.default?.size} !important;
    font-weight: ${({ theme }) => theme.button?.default?.weight} !important;
    font-family: ${({ theme }) => theme.button?.default?.family} !important;
    color: ${({ theme }) =>
      theme.button?.default?.passiveColor || colors.primary} !important;
    background: ${({ theme }) =>
      theme.button?.default?.passiveBackground} !important;
    border: ${({ theme }) =>
      theme.button?.default?.passiveBorder ||
      `1px solid ${colors.primary}`} !important;
    padding: 0 10px !important;
    height: 28px;
    margin-top: 10px;

    &:hover {
      color: ${({ theme }) =>
        theme.button?.default?.activeColor || colors.secondary} !important;
      background: ${({ theme }) =>
        theme.button?.default?.activeBackground} !important;
      border: ${({ theme }) =>
        theme.button?.default?.activeBorder ||
        `1px solid ${colors.secondary}`} !important;
    }
  }
`;