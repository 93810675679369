import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import client from 'lib/apiClient';

const { Option } = Select;

const MIN = 2;
const LIMIT = 50;

const generateOptions = (options) => {
  return options?.map((item) => ({
    label: item,
    value: item,
  }));
};

const DebounceSelect = ({
  name,
  endpoint,
  setFieldTouched,
  debounceTimeout = 800,
  ...props
}) => {
  const [fetching, setFetching] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [next, setNext] = useState();
  const fetchRef = React.useRef(0);
  const { t } = useTranslation();

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      setSearchValue(value);
      if (value.length >= MIN) {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        client
          .getOptions(value, endpoint, LIMIT)
          .then(({ options, next_cursor }) => ({
            newOptions: generateOptions(options),
            next_cursor,
          }))
          .then(({ newOptions, next_cursor }) => {
            if (fetchId !== fetchRef.current) {
              // for fetch callback order
              return;
            }
            setOptions(newOptions);
            setNext(next_cursor);
            setFetching(false);
          });
      } else {
        setOptions([]);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout, endpoint]);

  const reset = () => {
    setOptions([]);
    setNext(null);
  };

  const handleScroll = (event) => {
    const target = event.target;

    if (next !== null && !loadingMore) {
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        setLoadingMore(true);
        client
          .getOptions(searchValue, endpoint, LIMIT, next)
          .then(({ options, next_cursor }) => ({
            newOptions: generateOptions(options),
            next_cursor,
          }))
          .then(({ newOptions, next_cursor }) => {
            setTimeout(() => {
              return setOptions([...options, ...newOptions]);
            }, 3000);
            setNext(next_cursor);
            setLoadingMore(false);
            // target.scrollTo(0, target.scrollTop + 25);
          });
      }
    }
  };

  return (
    <Select
      {...props}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <div style={{ textAlign: 'center' }}>
            <Spin size="small" />
          </div>
        ) : searchValue >= MIN ? (
          t('common.noResults')
        ) : null
      }
      onBlur={() => {
        setFieldTouched(name, true);
        reset();
      }}
      dropdownRender={(menu) => (
        <div className="scroll_wrapper">
          {menu}
          {loadingMore ? (
            <div style={{ textAlign: 'center' }}>
              <Spin size="small" />
            </div>
          ) : null}
        </div>
      )}
      onPopupScroll={handleScroll}
    >
      {options?.map((option) => {
        return (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        );
      })}
    </Select>
  );
};

const SelectLargeList = (props) => {
  const {
    name,
    placeholder,
    value,
    setFieldValue,
    setFieldTouched,
    isError,
    endpoint,
    disabled,
  } = props;

  const unique_id = `select_${name}`;

  return (
    <DebounceSelect
      id={unique_id}
      showSearch
      showArrow={false}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      value={value || undefined}
      defaultActiveFirstOption={false}
      filterOption={false}
      endpoint={endpoint}
      setFieldTouched={setFieldTouched}
      onChange={(value) => {
        setFieldValue(name, value);
      }}
      className={isError ? 'has-error' : ''}
      getPopupContainer={triggerNode => triggerNode.parentElement}
      data-name={name}
    />
  );
};

DebounceSelect.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchOptions: PropTypes.func,
  endpoint: PropTypes.string,
  setFieldTouched: PropTypes.func,
  debounceTimeout: PropTypes.func,
};

SelectLargeList.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  value: PropTypes.any,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  isError: PropTypes.string,
  endpoint: PropTypes.string,
};

export default SelectLargeList;
