import { EXPORT_ALL_ITEMS_ACTION, EXPORT_TEMPLATES_ACTION, HEADER_ACTIONS } from '../actions';
import { cloneDeep } from 'lodash';

const getHeaderAction = (name: string, schema: any, business: string, t?: any) => {
  const actionsObject = HEADER_ACTIONS(t);
  const actionsTemplate =
    actionsObject[schema?.component ? `${schema?.component}` : name];

  if (!actionsTemplate) {
    return null;
  }

  const actions = cloneDeep(actionsTemplate);

  actions.forEach((obj) => {
    const isExportItemsBtn = obj.action.includes(EXPORT_ALL_ITEMS_ACTION);
    const isExportTemplatesBtn = obj.action.includes(EXPORT_TEMPLATES_ACTION);
    const isAddBtn = !isExportItemsBtn && !isExportTemplatesBtn;

    if (isAddBtn) {
      obj.label =
        schema?.addButton || specificHeaderPerBusiness(business) || obj.label;
      obj.display = schema?.options?.display_add_item_button;
    }

    if (isExportItemsBtn) {
      obj.label = schema?.options?.export_items_button_label || obj.label;
      obj.display = schema?.options?.display_export_items_button;
    }

    if (isExportTemplatesBtn) {
      obj.label = schema?.export_templates_button_label || obj.label;
      obj.display = schema?.display_export_templates_button;
      obj.uploadType = schema?.filter_params?.upload_type;
    }
  });

  return actions;
};

const specificHeaderPerBusiness = (business: string) => {
  switch (business) {
    case 'sb-nm':
      return 'Valider cohérentes';
  }
};

export default getHeaderAction;
