import { FC } from 'react';
import { STitleWrapper } from './styled-components';

interface PopoverTitleProps {
  title?: string;
}

const PopoverTitle: FC<PopoverTitleProps> = ({ title }) => {
  if (!title) return null;

  return <STitleWrapper>{title}</STitleWrapper>;
};

export default PopoverTitle;
