import styled from 'styled-components';

const StyledRoot = styled.div`
  height: 50vh;
  overflow-y: scroll;
`;

const StyledNoMessages = styled.div`
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  font-size: 23px;
  margin: 16vh 0;
`;

export { StyledRoot, StyledNoMessages };
