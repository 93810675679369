import { FC } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import DotLegend from '../DotLegend';
import { SContentWrapper } from './styled-components';

interface PopoverContentProps {
  content: any;
  closeFn: () => void;
  type?: 'dot_legend' | 'text_array';
  displayClose: boolean;
  closeBtnLabel?: string;
}

const PopoverContent: FC<PopoverContentProps> = ({
  content,
  type,
  closeFn,
  displayClose,
  closeBtnLabel,
}) => {
  const { t } = useTranslation();

  const getContent = () => {
    switch (type) {
      case 'dot_legend':
        return <DotLegend data={content} />;
      case 'text_array':
        return content.map((string) => (
          <p className="content-string">{string}</p>
        ));
      default:
        return content;
    }
  };

  return (
    <SContentWrapper>
      <div className="content">{getContent()}</div>
      {displayClose && (
        <Button onClick={closeFn} className="close-button">
          {closeBtnLabel || t('common.close')}
        </Button>
      )}
    </SContentWrapper>
  );
};

export default PopoverContent;
