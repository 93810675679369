const mapper = (t) => {
  return ({
    file_hash: t('media.fileHash'),
    file_url: t('media.fileURL'),
    file_url_href: '',
    file_name: t('media.fileName'),
    comment: t('media.comment'),
    company: t('common.company'),
    protected: t('media.protectedFile'),
  })
}

export default mapper;
