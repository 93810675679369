import { FC } from 'react';
import { Select } from 'antd';
import ReactCountryFlag from 'react-country-flag';
import { SSelect } from './styled-components';

const { Option } = Select;

const LanguageSelector: FC<any> = ({ language, languageList, handleLanguageChange }) => {
  const handleChange = (value: string) => {
    handleLanguageChange(value);
  };

  return (
    <SSelect
      defaultValue={language}
      optionLabelProp="children"
      onChange={handleChange}
    >
      {languageList?.map((lang) => (
        <Option key={lang.value} value={lang.value}>
          <ReactCountryFlag countryCode={lang.value} svg /> {lang.label}
        </Option>
      ))}
    </SSelect>
  );
};

export default LanguageSelector;
