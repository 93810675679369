// @ts-nocheck
import { Badge, Modal } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationList from '../../components/notification-list';
import { useSelector, useDispatch, connect } from 'react-redux';
import {
  getNotifications,
  // markAsRead,
  markNotificationAsRead,
} from './reducer';
import { SNotificationWrapper } from './styled-components';

export type Props = {
  business: {
    notifications_polling_period: number;
  };
};

const Bell = ({ business }: Props) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { notifications } = useSelector(
    (state: any) => state.notificationsSlice
  );
  const [pollingPeriod, setPollingPeriod] = useState();
  const intervalRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    setPollingPeriod(+business?.notifications_polling_period);

    if (pollingPeriod && pollingPeriod > 0) {
      dispatch(getNotifications());
      intervalRef.current = setInterval(
        async () => dispatch(getNotifications()),
        pollingPeriod * 1000
      );
    }
    return () => {
      clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business, pollingPeriod]);

  if (!pollingPeriod || pollingPeriod <= 0) {
    return null;
  }

  if (!Object.keys(notifications)) {
    return null;
  }

  const actualNotifications = Object.keys(notifications).filter(
    (key) => !notifications[key].read && !notifications[key].expired
  );

  const BellComponent = (
    <div className="notification-bell">
      <BellOutlined />
    </div>
  );

  return (
    <div>
      <SNotificationWrapper
        onClick={() => setVisible(true)}
        className="rounded"
      >
        {actualNotifications.length === 0 && BellComponent}
        {actualNotifications.length > 0 && (
          <Badge
            count={actualNotifications.length}
            dot
            className="notification-badge-container"
            offset={[-5, 5]}
          >
            {BellComponent}
          </Badge>
        )}
      </SNotificationWrapper>
      <Modal
        title={t('notifications.title')}
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={'40vw'}
      >
        <NotificationList
          notifications={actualNotifications.map((key) => notifications[key])}
          markAsRead={(id) => dispatch(markNotificationAsRead(id))}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = ({
  auth: {
    currentUser: {
      attributes: { business },
    },
  },
}) => ({
  business,
});

export default connect(mapStateToProps)(Bell);
