import styled from 'styled-components';
import { Select } from 'antd';
import { colors, font } from 'App/styleVariables';

export const SSelect = styled(Select)<any>`
  font-family: ${({ theme }) => theme.header.languageSelector?.family || font};
  width: 130px;

  &.ant-select:not(.ant-select-disabled).ant-select-focused {
    .ant-select-selector {
      border: ${({ variant }) => {
        return variant === 'borderless'
          ? 'none'
          : `1px solid ${colors.secondary}`;
      }} !important;
      box-shadow: none !important;
    }
  }

  .ant-select-arrow {
    color: ${({ theme }) =>
      theme.header.languageSelector?.current?.color || '#ffffff'};
  }

  .ant-select-selector {
    color: ${({ theme }) =>
      theme.header.languageSelector?.current?.color || '#ffffff'};
    border: ${({ variant }) => {
      return variant === 'borderless' ? 'none' : '1px solid #d9d9d9';
    }} !important;
    background-color: ${({ variant }) => {
      return variant === 'borderless' ? 'transparent' : '#ffffff';
    }} !important;
  }

  .ant-select-selection-item {
    font-size: 14px;
    font-weight: 600;
  }

  &.ant-select.ant-select-open {
    .ant-select-selection-item {
      color: inherit !important;
    }
  }

  .current-language-flag {
    width: 18px !important;
    height: 18px !important;
  }
`;
