import TraceResourceFactory from './trace-resource-factory';
import UserResourceFactory from './user-resource-factory';
import UploadResourceFactory from './upload-resource-factory';
import ChainResourceFactory from './chain-resource-factory';
import CompanyResourceFactory from './company-resource-factory';
import CustomListResourceFactory from './custom-list-resource-factory';
import { PageSchema } from '../../../types';
import SmsResourceFactory from './sms-resource-factory';
import ProductResourceFactory from './product-resource-factory';
import SmsConfirmationResourceFactory from './sms-confirmation-resource-factory';
import HistoryDetailsResourceFactory from './history-resource-factory';
import Workflow from './workflow-resource-factory';
import WorkflowObjectHistory from './workflow-object-history-resource-factory';
import DashboardTableResourceFactory from './dashboard-table-resource-factory';
import {
  SludgeAnalysisResourceFactory,
  CompAnalysisResourceFactory,
  SoilAnalysisResourceFactory,
} from './analysis';
import {
  GreasyWoolLotResourceFactory,
  SalesStockResourceFactory,
  WoolTopLotsResourceFactory,
  SpinningSalesResourceFactory,
} from './stocks';

type Extra = {
  pageSchema: PageSchema | undefined;
};

/**
 * This function creates new factory object and returns it
 * @param resource - resource name
 * @param extra - object with additional information
 * @returns {object} - new resource factory
 */
const getResourceFactory = function (resource: string, extra: Extra) {
  switch (true) {
    case resource === 'Traces':
      return new TraceResourceFactory();
    case resource === 'Users':
      return new UserResourceFactory();
    case resource === 'FileUpload':
      return new UploadResourceFactory();
    case resource === 'Chains':
      return new ChainResourceFactory();
    case resource === 'Companies':
      return new CompanyResourceFactory();
    case resource === 'CustomList':
      return new CustomListResourceFactory();
    case resource === 'Sms':
      return new SmsResourceFactory();
    case resource === 'Products':
      return new ProductResourceFactory();
    case resource === 'SmsConfirmation':
      return new SmsConfirmationResourceFactory();
    case resource === 'HistoryDetails':
      return new HistoryDetailsResourceFactory();
    case resource === 'SludgeAnalysis':
      return new SludgeAnalysisResourceFactory();
    case resource === 'CompAnalysis':
      return new CompAnalysisResourceFactory();
    case resource === 'SoilAnalysis':
      return new SoilAnalysisResourceFactory();
    case resource === 'GreasyWoolLot':
      return new GreasyWoolLotResourceFactory();
    case resource === 'SalesStock':
      return new SalesStockResourceFactory();
    case resource === 'WoolTopLots':
      return new WoolTopLotsResourceFactory();
    case resource === 'SpinningSales':
      return new SpinningSalesResourceFactory();
    case resource === 'Workflow':
      return new Workflow();
    case resource === 'WorkflowObjectHistory':
        return new WorkflowObjectHistory();
    case resource.includes('DashboardBackendTable'):
      return new DashboardTableResourceFactory();
    default:
      throw new Error(`Resource name ${resource} not found`);
  }
};

export default getResourceFactory;
