import React from 'react';
import { withTranslation } from 'react-i18next';
import { CustomListItemModal } from 'sections/CustomList/containers/CustomListItemModal/custom-list-item-modal';
import CustomModal from 'components/Modal';
import { connect } from 'react-redux';
import { closeModalAction } from 'sections/CustomList/containers/CustomListItemModal/reducer';
import DocumentsItemForm from '../DocumentListForm';
import ShowList from 'components/ShowTraceList';
import { DOCUMENTS_MODAL } from '../../../../components/Modal/constants';

class DocumentsListModal extends CustomListItemModal {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  render() {
    const { isEdit, isModalOpen, isCommonItem, itemData, t } = this.props;

    let title = isEdit ? t('form.editInfo') : t('form.addFile');
    if (isCommonItem) {
      title = `${itemData.file_name}`;
    }

    const DocumentsListModalRef = React.forwardRef((props, ref) => (
      <CustomModal
        title={title}
        closeModal={this.handleOnClose}
        modalVisible={isModalOpen}
        prefixId={DOCUMENTS_MODAL}
      >
        {isModalOpen ? (
          isCommonItem ? (
            <ShowList data={itemData} />
          ) : (
            <DocumentsItemForm {...this.props} itemData={itemData} />
          )
        ) : null}
      </CustomModal>
    ));

    return <DocumentsListModalRef ref={this.modalRef} />;
  }
}

const ConnectedModal = connect(
  ({ documentsModal }) => ({
    isModalOpen: documentsModal.isModalOpen,
    isEdit: documentsModal.isEdit,
    isCommonItem: documentsModal.isCommonItem,
    itemData: documentsModal.itemData,
    title: documentsModal.title,
  }),
  {
    closeModal: closeModalAction,
  }
)(withTranslation()(DocumentsListModal));

export default ConnectedModal;
