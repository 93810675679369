import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { store } from 'store/nextStore';
import { galleryModal } from 'components/Modal/constants';
import {
  closeGenericModalAction,
  openGenericModalAction,
} from '../../GenericModal/reducer';
import { capitalizeFirstLetter } from 'lib/util';

const FileSelect = ({
  value: filename,
  name,
  placeholder,
  handleBlur,
  disabled,
  isError,
  setFieldValue,
  handleChange,
  values,
  parent,
}) => {
  const { t } = useTranslation();

  const getFullFieldName = (name, replaceValue) => {
    return name.replace(/(filename|Filename)/g, (value) => {
      return value.charAt(0) === 'f'
        ? replaceValue
        : capitalizeFirstLetter(replaceValue);
    });
  };

  const getFieldName = (name) => {
    let lastOpenBracketIndex = name.lastIndexOf('[');
    let lastCloseBracketIndex = name.lastIndexOf(']');

    if (lastOpenBracketIndex !== -1 && lastCloseBracketIndex !== -1) {
      return name.substring(lastOpenBracketIndex + 1, lastCloseBracketIndex);
    } else {
      return name;
    }
  };

  const isFieldNameExist = (obj, fieldName, parent) => {
    const name = getFieldName(fieldName);
    const parentName = parent || 'Composition';

    if (obj.hasOwnProperty(name)) return true;

    if (Array.isArray(obj[parentName])) {
      for (let i = 0; i < obj[parentName].length; i++) {
        const compositionObj = obj[parentName][i];
        if (compositionObj.hasOwnProperty(name)) return true;
      }
    }

    return false;
  };

  const handlerFieldValue = (replaceValue) => {
    const hashFullFieldName = getFullFieldName(name, 'hash');
    const urlFullFieldName = getFullFieldName(name, 'url');

    if (name.indexOf('DocumentFilename') > -1) {
      setFieldValue(
        name.replace('DocumentFilename', 'DocumentHash'),
        replaceValue.file_hash || replaceValue
      );
      setFieldValue(
        name.replace('DocumentFilename', 'DocumentUrl'),
        replaceValue.file_url || replaceValue
      );
    } else {
      isFieldNameExist(values, hashFullFieldName, parent) &&
        setFieldValue(hashFullFieldName, replaceValue.file_hash || replaceValue);
      isFieldNameExist(values, urlFullFieldName, parent) &&
        setFieldValue(urlFullFieldName, replaceValue.file_url || replaceValue);
    }
  };

  const onChange = (event) => {
    if (event.target.value === '') {
      handlerFieldValue('');
      handleChange(event);
    }
  };

  const onSelect = (file) => {
    // TODO replace field values with values from the schema (should be developed on back-end side)
    setFieldValue(name, file.file_name);
    handlerFieldValue(file);

    store.dispatch(closeGenericModalAction({ name: galleryModal }));
  };

  // Test for value coming from parent
  if (typeof filename !== 'string') {
    return null;
  }

  return (
    <div>
      <Input
        name={name}
        placeholder={placeholder}
        value={filename}
        onChange={onChange}
        onBlur={handleBlur}
        disabled={disabled}
        allowClear={!disabled}
        className={isError ? 'has-error' : ''}
        onClick={() =>
          store.dispatch(
            openGenericModalAction({
              name: galleryModal,
              configuration: {
                title: t('form.chooseFile'),
                width: '80vw',
                prefixId: 'gallery',
              },
              data: {
                parent: 'FileSelect',
                item: filename,
                modalName: galleryModal,
                onSelect: onSelect,
                headerActionsSchema: [
                  {
                    label: t('form.addItem'),
                    action: 'addItem',
                    icon: 'plus-circle',
                  },
                ],
              },
            })
          )
        }
        data-name={name}
      />
    </div>
  );
};

FileSelect.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setFieldValue: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  isError: PropTypes.string,
};

const FileSelectWrapper = (props) => <FileSelect {...props} />;

export default FileSelectWrapper;
