import {
  CHAINS_TABLE,
  SMSCOMFIRMATION_TABLE,
  TRACES_TABLE,
  UPLOAD_TABLE,
  CUSTOMLIST_TABLE,
  WORKFLOW_TABLE,
  WORKFLOW_OBJECT_HISTORY_TABLE,
  DASHBOARD_BACKEND_TABLE,
} from '../constants';

const checkTableIsApiPaginated = (name: string, schema: any) => {
  const API_TABLES: any = {
    [TRACES_TABLE]: true,
    [UPLOAD_TABLE]: true,
    [SMSCOMFIRMATION_TABLE]: true,
    [CUSTOMLIST_TABLE]: true,
    [WORKFLOW_TABLE]: true,
    [WORKFLOW_OBJECT_HISTORY_TABLE]: true,
    [CHAINS_TABLE]: schema?.options?.api_pagination || false,
    [DASHBOARD_BACKEND_TABLE]: true,
  };

  const table = schema?.component
    ? `${schema?.component}`
    : name.includes(DASHBOARD_BACKEND_TABLE)
    ? DASHBOARD_BACKEND_TABLE
    : name;

  return API_TABLES[table] || false;
};

export default checkTableIsApiPaginated;
