import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import sections from '../sections';
import RouteWrapper from './RouteWrapper';

const registerOptionalParamRoute = (optionalParams, element) => {
  const param = optionalParams[0];
  optionalParams.splice(0, 1);

  return <Route path={param} element={element}>
    {optionalParams.length !== 0 && registerOptionalParamRoute(optionalParams, element)}
  </Route>;
};

const routeGenerator = ({ pagesSchema, isAdmin, kind, pathname }) => {
  return pagesSchema !== undefined && pagesSchema !== null ? (
    Object.keys(pagesSchema)
      .filter((pageName) => pagesSchema[pageName].path)
      .map((pageName) => {
        const { path: defaultPath } = pagesSchema[pageName];
        const Component =
          pagesSchema[pageName].component === 'CommonIframe' ||
          pagesSchema[pageName].component === 'CommonDashboard' ||
          pagesSchema[pageName].component === 'Cockpit' ||
          pagesSchema[pageName].component === 'WorkflowBackoffice' ||
          pagesSchema[pageName].component === 'Documents'
            ? sections[pagesSchema[pageName].component]
            : sections[pageName] || sections['GenericTablePage'];
        const path = defaultPath.includes('?')
          ? `/${defaultPath.split('/')[1]}`
          : defaultPath;
        const params = defaultPath.includes('?')
          ? defaultPath
              .split('/')
              .slice(2)
              .map((param) => param.substring(0, param.length - 1))
          : null;

        return (
          <Route
            key={pageName}
            exact
            path={path}
            element={
              <RouteWrapper
                component={Component}
                pageSchema={pagesSchema[pageName]}
                pageName={pageName}
                isAdmin={isAdmin}
                kind={kind}
              />
            }
          >
            {Array.isArray(params) && params.length
              ? registerOptionalParamRoute(
                  params,
                  <RouteWrapper
                    component={Component}
                    pageSchema={pagesSchema[pageName]}
                    pageName={pageName}
                    isAdmin={isAdmin}
                    kind={kind}
                  />
                )
              : null}
          </Route>
        );
      })
  ) : (
    <Route
      path="*"
      element={<Navigate to="/sign_in" replace state={{ path: pathname }} />}
    />
  );
};

export default routeGenerator;
