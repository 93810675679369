import styled from 'styled-components';
import { Collapse } from 'antd';
import { font } from 'App/styleVariables';

export const SCollapseWrapper = styled(Collapse)`
  width: 100%;
  font-family: ${font};
  background-color: #ffffff;
  padding-bottom: 16px;

  &.status-section {
    .ant-collapse-header {
      padding: 0 !important;
    }

    .ant-collapse-content .ant-collapse-content-box {
      padding: 10px 0 0;
    }

    .status-section-label {
      font-family: ${font};

      p {
        margin-bottom: 0;
        margin-right: 10px;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
`;
