import React, { Component, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
  fallbackContent?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ContentErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ContentErrorBoundary caught an error:', error, errorInfo);
  }

  render() {
    const { fallbackContent } = this.props;
    if (this.state.hasError) {
      return (
        <div>{fallbackContent}</div>
      );
    }

    return this.props.children;
  }
}

export default ContentErrorBoundary;
