import AbstractResourceFactory from './abstract-resource-factory';
import client from '../../../../../lib/apiClient';
import { Table, TableFilters } from '../../../types';

class SmsConfirmationResourceFactory implements AbstractResourceFactory {
  defaultFilterState = {
    sorting: '%5B%5D',
    lines_per_page: 10,
  };

  async getAll(
    page = 1,
    searchField = '',
    filters: TableFilters,
    path,
    filter_params
  ): Promise<Table> {
    const sms = await client.getSmsConfirmations({
      query: searchField,
      ...filters,
      page_number: page,
      filters: filters?.filters || '',
    });

    return {
      data: sms?.content,
      schema: await client.getSmsConfirmationsTable(),
      total: sms.numberOfElements,
    };
  }

  async resendOne(traceId: string) {
    try {
      await client.resendOneSms(traceId);
    } catch (e) {
      console.log('err',e);
      throw e;
    }
  }

  async resendAll({ filters, query }: any) {
    try {
      await client.resendAllSms({
        query,
        ...filters,
        filters: filters?.filters || encodeURIComponent(JSON.stringify({})),
      });
    } catch (e) {
      console.log('err', e);
      throw e;
    }
  }
}

export default SmsConfirmationResourceFactory;
