import { WidgetProps } from '../../utils/type';
import type { ColumnsType } from 'antd/es/table';
import { add_keys_to_json } from '../../utils/utils';
import { useEffect, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import { Table } from 'antd';
import client from 'lib/apiClient';


interface Props extends WidgetProps {
    columns: ColumnsType<any>;
    method: string;
    filter?: (record: any) => boolean;
}

export default function TableView({ date, period, title, columns, method, filter }: Props) {
    const [data, setData] = useState([]);

    async function getData() {
        client.getMatomoData(method, date, period)
            .then((res) => {
                if (res?.error) {
                    return null;
                } else {
                    if (res['result'] !== 'error') {
                        if (filter) {
                            res = res?.filter(val => filter(val));
                        }
    
                    }
                    add_keys_to_json(res, '');
                    setData(res);
                }
            })
    }

    useEffect(() => {
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, period])

    return (
        <>
            <Title level={3}>{title?.toUpperCase()}</Title>
            <Table
                columns={columns}
                dataSource={data}
                childrenColumnName={'subtable'}
            />
        </>
    )
}