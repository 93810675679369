import { json2csv } from 'json-2-csv';
import { message } from 'antd';
import { downloadFile } from '../../lib/util';

export const getDashboardName = (name, pageSchema) => {
  switch (name) {
    case 'weturn':
      return 'weturn';
    default:
      return pageSchema?.path?.split('/')[1];
  }
};

export const downloadCsv = (
  data,
  schema,
  filename,
  exportFullData,
  { expandArrayObjects, unwindArrays, excludeKeys },
  t
) => {
  const headerOrder: string[] = schema.map((item) => item.fieldName);

  json2csv(data, {
    delimiter: { field: ';' },
    emptyFieldValue: '',
    expandArrayObjects: expandArrayObjects,
    unwindArrays: unwindArrays,
    excludeKeys: excludeKeys,
    prependHeader: true,
    keys: !exportFullData && headerOrder,
  })
    .then((csv) => {
      downloadFile(csv, filename || `table_export_${Date.now()}.csv`);
      message.success(t('messages.fileDownloaded'));
    })
    .catch((err) => message.error(`${err.name}: ${err.message}`));
};
