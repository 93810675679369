import React from 'react';
import PropTypes from 'prop-types';
import { List, Form } from 'antd';
import { SDetailsList, SButton, s } from './style';
import Composition from '../Composition';
import getContentOrHttpLink from '../check-content-on-http';
import { useTranslation } from 'react-i18next';

const excludedItems = [
  'file_url_href',
  'searchable_index',
  'hash_for_duplicates',
];

const TraceList = ({
  data,
  modalWidth = {},
  is_all = false,
  no_empty = true,
  handleToggle,
  business,
}) => {
  const { t } = useTranslation();
  
  return (
    <SDetailsList modalWidth={modalWidth}>
      <Form.Item>
        <SButton onClick={handleToggle} disabled={no_empty}>
          {(is_all && t('common.hideEmpty')) || t('common.showAll')}
        </SButton>
      </Form.Item>
      <List
        grid={s.grid}
        dataSource={data}
        renderItem={({ title, content }) => {
          if (excludedItems.includes(title)) {
            return null;
          }
          return (
            <List.Item style={Array.isArray(content) ? s.compos : {}}>
              <div className="trace-list-wrapper">
                <div className="trace-list-title" data-name={title}>
                  {title}:
                </div>
                <div className="trace-list-desc">
                  {Array.isArray(content)
                    ? Composition(content, business?.id)
                    : getContentOrHttpLink(content, business?.id)}
                </div>
              </div>
            </List.Item>
          );
        }}
      />
    </SDetailsList>
  );
};

TraceList.propTypes = {
  /**
   * Data to display.
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ),
  /**
   * Property that sets the dimensions for the label field and the value field in the row.
   */
  modalWidth: PropTypes.any,
  /**
   * Property that determines which button is displayed: *"Show All"* or *"Hide Empty"*.
   */
  is_all: PropTypes.bool,
  /**
   * Enable or disable button. If `true` then button is disabled.
   */
  no_empty: PropTypes.bool,
  /**
   * Callback function for toggling `is_all` property by clicking on the button.
   */
  handleToggle: PropTypes.func,
};

export default TraceList;
