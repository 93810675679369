import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withRouter from '../../../components/HOC/withRouter';
import logo from 'images/logo_C_black.png';
import { resetAllDataAction } from '../../../store/nextStore';
import styled from 'styled-components';
import './styles.css';

const StyledParagraph = styled.p`
  margin: 0;
`;

const CleanUpButton = styled.span`
  cursor: pointer;
  font-weight: bold;
`;

const Divider = styled.hr`
  margin: 128px 256px;
`;

const ErrorHandler = ({ resetAllData, fixed, error, router }) => {
  const { t } = useTranslation();

  const tryToFix = () => {
    resetAllData();
    router.navigate('/sign_in');
    fixed();
  };

  return (
    <div className={`error-handler-root`}>
      <Empty
        image={<img src={logo} className={`error-handler-logo`} alt="logo" />}
        description={
          <div>
            <h2>{t('errorPage.sorry')}</h2>
            <Divider />
            <div>
              <h4>{t('errorPage.description')}</h4>
              <div>
                <StyledParagraph>{t('errorPage.refresh')};</StyledParagraph>
                <StyledParagraph>{t('errorPage.restart')};</StyledParagraph>
                <StyledParagraph>
                  <CleanUpButton onClick={tryToFix}>
                    {t('errorPage.click')}
                  </CleanUpButton>{' '}
                  {t('errorPage.cleanup')}
                </StyledParagraph>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

ErrorHandler.propTypes = {
  fixed: PropTypes.func.isRequired,
  error: PropTypes.string,
  resetAllData: PropTypes.func,
  router: PropTypes.object,
};

const mapDispatchToProps = {
  resetAllData: resetAllDataAction,
};

export default withRouter(connect(null, mapDispatchToProps)(ErrorHandler));
