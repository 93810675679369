import _ from 'lodash';
import dayjs from 'dayjs';
import client from 'lib/apiClient';
import { PROTECTED_BUCKET } from './constants';

const removeSpaces = (someData, noTrimming) => {
  const arr = [];
  // eslint-disable-next-line
  someData.map((item) => {
    const res = {};
    // eslint-disable-next-line
    Object.keys(item).map((key) => {
      let valTrimmed;
      if (
        typeof item[key] === 'string' &&
        noTrimming &&
        !noTrimming.includes(key)
      ) {
        valTrimmed = item[key].trim();
      } else {
        valTrimmed = item[key];
      }
      res[key] = valTrimmed;
    });
    arr.push(res);
  });
  return arr;
};

const removeGaps = (data) => {
  const noTrimming = JSON.parse(localStorage.getItem('noTrimming'));
  return Object.keys(data).reduce((result, key) => {
    let valTrimmed;
    if (
      typeof data[key] === 'string' &&
      noTrimming &&
      !noTrimming.includes(key)
    ) {
      valTrimmed = data[key].trim();
    } else if (key === 'Composition') {
      valTrimmed = data[key] = removeSpaces(data[key], noTrimming);
    } else {
      valTrimmed = data[key];
    }
    result[key] = valTrimmed;

    return result;
  }, {});
};

const replaceEmptyStringsWithNullInObject = (data) => {
  if (typeof data !== 'object') {
    throw new Error(
      `Error in replaceEmptyStringsWithNullInObject function. Typeof of data is not valid`
    );
  }
  const next = Object.assign({}, data);
  Object.keys(next).forEach((key) => {
    if (typeof next[key] === 'string' && next[key].length === 0) {
      next[key] = null;
    }
  });
  return next;
};

const grabToken = function () {
  const accessToken = sessionStorage.getItem('access-token');
  const client = sessionStorage.getItem('client');
  const uid = sessionStorage.getItem('uid');
  return `access-token=${accessToken}&client=${client}&uid=${uid}`;
};

const prepareValidationData = ({
  trace,
  status,
  traceId,
  validation = null,
  statusField = 'Validated',
}) => {
  const current_data = JSON.parse(trace.data);
  const stringified_data = JSON.stringify({
    ...current_data,
    [statusField]: status,
  });
  return { data: stringified_data, traceId, validation };
};

const renameKeys = (obj, sym, repl) => {
  const newObj = {};
  for (const key in obj) {
    if (key.includes(sym)) {
      const new_key = key.replace(sym, repl);
      newObj[new_key] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

const get_widths = ({ data, composition }) => {
  const max_by = (arr) => _.maxBy(arr, (str) => str && str.length).length;
  const to_percentage = (n, total) => `${Math.floor((n * 100) / total)}%`;
  const key_max = max_by(Object.keys(data));
  let common;

  if (Array.isArray(composition)) {
    const arr = [];
    for (const n of composition) {
      for (const [k, v] of Object.entries(n)) arr.push(`${k} ${v}`);
    }

    const compos_max = max_by([...arr, ...Object.values(data)]);
    common = key_max + compos_max;

    return {
      left: to_percentage(key_max, common),
      right: to_percentage(compos_max, common),
      common,
    };
  }

  const value_max = max_by(Object.values(data));
  common = key_max + value_max;

  return {
    left: to_percentage(key_max, common),
    right: to_percentage(value_max, common),
    common,
  };
};

const to_query = (obj = {}) => {
  const query = {};
  for (const [k, v] of Object.entries(obj))
    v !== null && (!!v.length || !!Object.keys(v).length) && (query[k] = v);

  return encodeURIComponent(JSON.stringify(query));
};

const get_range = ({ from, to, format = 'DD-MM-YYYY' }) => ({
  gte: dayjs(from).format(format),
  lte: dayjs(to).format(format),
});

const is_forbidden = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const define_width = ({ target = {}, min = 650, max = 1420, def = 1200 }) => {
  let current = target.common ? target.common * 9 : def;
  if (current < min) current = min;
  else if (current > max) current = max;
  return current;
};

const openProtectedLink = (link, businessId, dashboard = false) => {
  if (
    typeof link === 'string' &&
    link.startsWith(`${PROTECTED_BUCKET}`)
  ) {
    client
      .openProtectedLink({
        fileName: link?.slice(link?.lastIndexOf('/') + 1),
        businessId,
        dashboard,
      })
      .then(({ url }) => window.open(url, '_blank'))
      .catch((err) => console.log('err', err));
  } else if (link.includes('expiring_url')) {
    client
      .openExtLink(`${link}`)
      .then(({ url }) => window.open(url, '_blank'))
      .catch((err) => console.log(err));
  } else {
    window.open(link, '_blank');
  }
};

const openLink = (link) => {
  const localIPAddressesRegex = /(localhost)|(127\.)|(10\.)|(172\.1[6-9]\.)|(172\.2[0-9]\.)|(172\.3[0-1]\.)|(192\.168\.)/;
  if (link.includes(window.location.host) || link.match(localIPAddressesRegex)) {
    client.openExtLink(`${link}`)
      .then(({ url }) => {
        window.location = url;
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    window.open(link, '_blank');
  }
};

const handleCollection = (obj, collection) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      handleCollection(obj[key], collection);
    } else {
      return collection.push(obj[key]);
    }
  });
};

const transformCollections = (trace) => {
  Object.keys(trace).forEach((key) => {
    const collection = [];
    if (typeof trace[key] === 'object' && trace[key] !== null && key !== 'C') {
      handleCollection(trace[key], collection);

      if (trace.hasOwnProperty(key)) {
        Object.defineProperty(trace, key, { value: collection.join(';') });
      }
    }
  });
};

const renameTraceProperty = (obj, currentName, newName) => {
  Object.defineProperty(
    obj,
    newName,
    Object.getOwnPropertyDescriptor(obj, currentName)
  );
  delete obj[currentName];
};

const prepareTraceForCsv = (obj) => {
  const trace = _.cloneDeep(obj);

  if (!trace) {
    return null;
  }

  if (trace.hasOwnProperty('Composition')) {
    renameTraceProperty(trace, 'Composition', 'C');
  }
  if (trace.hasOwnProperty('object_type')) {
    renameTraceProperty(trace, 'object_type', 'TraceType');
  }

  transformCollections(trace);

  return trace;
};

const downloadFile = (blobParts, filename, fileType = 'text/csv;charset=utf-8;') => {
  const blob = new Blob([blobParts], {
    type: fileType,
  });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const clearSessionStorage = () => {
  sessionStorage.clear();
};

const getHexFromString = (string) => {
  const hexValue = string?.match(/#([0-9a-fA-F]{6})/);

  return hexValue ? hexValue[0] : null;
};

const sortObjectByOrder = (obj) => {
  const dataObject = _.cloneDeep(obj);
  const entries = Object.entries(dataObject);

  entries.sort((a, b) => {
      const orderA = parseInt(a[1]?.order) || 0;
      const orderB = parseInt(b[1]?.order) || 0;
      return orderA - orderB;
  });

  const sortedObj = {};
  entries.forEach(([key, value]) => {
    if (value?.type === 'FieldArray') {
      const sortedFields = sortObjectByOrder(value.fields);
      sortedObj[key] = { ...value, fields: sortedFields };
    } else {
      sortedObj[key] = value;
    }
  });

  return sortedObj;
};

const filterNonEmptyObjectValues = (obj) => {
  if (!obj || typeof obj !== 'object') return null;

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_key, value]) => value !== undefined && value !== null)
  )
}

export {
  removeGaps,
  grabToken,
  prepareValidationData,
  renameKeys,
  get_widths,
  to_query,
  is_forbidden,
  define_width,
  get_range,
  replaceEmptyStringsWithNullInObject,
  openLink,
  openProtectedLink,
  prepareTraceForCsv,
  downloadFile,
  capitalizeFirstLetter,
  clearSessionStorage,
  renameTraceProperty,
  transformCollections,
  getHexFromString,
  sortObjectByOrder,
  filterNonEmptyObjectValues,
};
