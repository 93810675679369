import { FC } from 'react';
import { Descriptions as ADescriptions } from 'antd';
import DotItem from 'components/library/DotItem';
import { SDescriptions } from './styled-components';

interface DescriptionsProps {
  value: { [key: string]: string }[];
  fields: { [key: string]: string };
  statusOptions?: {
    value: string;
    text?: string;
    customColor?: {
      background: string;
      text: string;
    };
  }[];
}

const Descriptions: FC<DescriptionsProps> = ({ value, fields, statusOptions }) => {
  if (!value || value.length === 0) return null;

  const getStatusProps = (status) =>
    statusOptions?.filter((item) => item.value === status)[0];

  return (
    <div>
      {value.map((item) => {
        return (
          <SDescriptions column={1}>
            {Object.entries(fields)?.map(
              ([key, value]: [key: string, value: string], index) => {
                return (
                  <ADescriptions.Item label={value}>
                    {item[key]}
                    {index === 0 && item?.Status ? (
                      <div className="dot">(<DotItem {...getStatusProps(item?.Status)} />)</div>
                    ) : null}
                  </ADescriptions.Item>
                );
              }
            )}
          </SDescriptions>
        );
      })}
    </div>
  );
};

export default Descriptions;
