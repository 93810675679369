import styled from 'styled-components';
import { Avatar } from 'antd';
import { colors, font } from 'App/styleVariables';

interface AvatarStyles {
  $backgroundColor?: string;
  $color?: string;
  context?: 'header' | 'table';
}

export const SAvatar = styled(Avatar)<AvatarStyles>`
  background-color: ${({ $backgroundColor, theme, context }) => {
    const defaultColor = colors.secondary20;

    if ($backgroundColor) return $backgroundColor;

    if (context === 'header')
      return theme.header.user?.avatar?.bgColor || 'rgba(255, 255, 255, 0.7)';

    return defaultColor;
  }};
  color: ${({ $color, theme, context }) => {
    const defaultColor = colors.primary;

    if ($color) return $color;

    if (context === 'header')
      return theme.header.user?.avatar?.color || defaultColor;

    return defaultColor;
  }};
  font-weight: 600;
  font-family: ${({ theme }) => theme.business?.family || font};
  font-size: 20px !important;
`;
