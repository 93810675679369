import { FC } from 'react';
import { SWapper } from './styled-components';
import { Spin } from 'antd';

const Loader: FC = () => {
  return (
    <SWapper>
      <Spin size="large" />
    </SWapper>
  );
};

export default Loader;
