import React from 'react';
import FormWorker from 'containers/FormWorker';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withRouter from '../../../../components/HOC/withRouter';
import { handleSubmitAction } from './reducer';
import { get_custom_list_items } from '../CustomListTable/reducer';

const styles = {
  formFilling: {
    width: 400,
  },
  formSelection: {
    width: 600,
  },
};

export class CustomListItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'custom_list',
      isLoading: false,
    };
    this.onSuccess = this.onSuccess.bind(this);
  }

  onSuccess() {
    const { closeModal, get_custom_list_items, router } = this.props;
    closeModal();
    this.setState({
      isLoading: false,
    });
    try {
      const list_name = router.location.pathname.split('/')[1];
      get_custom_list_items({ list_name });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { isEdit, handleSubmit, userValues, router, title } = this.props;

    const { type, isLoading } = this.state;

    try {
      const list_name = router.location ? router.location.pathname.split('/')[1] : null;

      return (
        <FormWorker
          type={type}
          listName={list_name}
          isEdit={isEdit}
          userValues={userValues}
          formStep="formFilling"
          formType=""
          styles={styles}
          handleSubmit={(data, actions) => {
            this.setState({
              isLoading: true,
            });
            handleSubmit({
              data,
              list_item_id: userValues && userValues.id,
              actions,
              isEdit,
              onSuccess: this.onSuccess,
              list_name,
            });
          }}
          isLoading={isLoading}
          title={title}
        />
      );
    } catch (e) {
      console.log(e);
    }
  }
}

const mapStateToProps = (state) => ({
  userValues: state.customListModal.itemData,
});

const mapDispatchToProps = {
  handleSubmit: handleSubmitAction,
  get_custom_list_items,
};

const composeResult = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CustomListItemForm);

export default composeResult;
