import styled from 'styled-components';
import { font } from 'App/styleVariables';

export const SFormWrapper = styled.div`
  margin-top: 20px;
  font-family: ${font};
  display: flex;
  width: 100%;

  .ant-form-item-label {
    color: #101010;
    font-weight: 500;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    &:hover,
    &:focus {
      border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
    }
  }

  .conformity-form-select:not(.ant-select-disabled) {
    &:hover,
    &:focus {
      .ant-select-selector {
        border: ${({ theme }) =>
          theme.form.item?.input?.borderActive} !important;
      }
    }
  }

  .conformity-form-select:not(.ant-select-disabled).ant-select-focused {
    .ant-select-selector {
      border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
    }
  }

  .ant-descriptions,
  .conformity-form-select {
    font-family: ${font};
  }

  .conformity-form-item {
    width: 100%;
  }

  .ant-descriptions-item.collapse {
    padding-bottom: 0;

    .ant-descriptions-row:last-child {
      .ant-descriptions-item {
        padding-bottom: 0;
      }
    }
  }

  .ant-descriptions-item-label {
    font-weight: 500;
    color: #101010;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-descriptions-item.textarea {
    .ant-descriptions-item-content {
      width: 100%;

      .ant-form-item {
        width: 100%;
      }
    }
  }

  .ant-descriptions-item.fileslist {
    .ant-descriptions-item-content {
      display: block;
    }
  }

  .submit-button {
    font-size: ${({ theme }) => theme.form.submitButton?.size} !important;
    font-weight: ${({ theme }) => theme.form.submitButton?.weight} !important;
    font-family: ${({ theme }) => theme.form.submitButton?.family} !important;
    color: ${({ theme }) => theme.form.submitButton?.color} !important;
    border: none !important;
    background: ${({ theme }) => theme.form.submitButton?.passive} !important;

    &:hover {
      color: ${({ theme }) => theme.form.submitButton?.color} !important;
      background: ${({ theme }) => theme.form.submitButton?.active} !important;
    }
  }

  .ant-list-empty-text {
    .ant-empty {
      margin: 0;
    }
  }

  .ant-spin {
    margin: 0 auto;
  }
`;
