import { FC, useEffect, useState } from 'react';
import { Flex, Form, Input, Spin } from 'antd';
import { QueryObserverResult, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { store } from 'store/nextStore';
import { closeGenericModalAction } from 'containers/GenericModal/reducer';
import apiClient from 'lib/apiClient';
import { docReaderModal } from 'components/Modal/constants';
import { handleFieldValue } from './util';
import { SButton, SReaderWrapper } from './styled-components';

interface DocReaderProps {
  fileUrl: string;
  fileName: string;
  setFieldValue: (field: string, value: any) => Promise<void>;
  setFieldTouched: (field: string, isTouched?: boolean) => Promise<void>;
  values: { [key: string]: any };
  name: string;
  traceType: string;
  formSchema: { [key: string]: any };
}

const DocReader: FC<DocReaderProps> = ({
  fileUrl,
  fileName,
  setFieldValue,
  setFieldTouched,
  values,
  name,
  traceType,
  formSchema,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { data, isFetching }: QueryObserverResult<{ data: object }, any> =
    useQuery(
      ['documentContent', fileUrl],
      async () => {
        const res = await await apiClient.getRecognizeDocument({
          fileUrl,
          data: values,
          name,
          traceType,
        });

        return res?.data;
      },
      {
        cacheTime: 0,
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: false,
        onError: (error) => {
          setError(true);
        },
      }
    );

  useEffect(() => {
    if (!fileUrl) {
      setError(true);
    }
  }, [fileUrl]);

  const onFinish = () => {
    setIsSubmitting(true);
    const allValues = form.getFieldsValue(true);

    Object.entries(allValues).forEach(
      ([key, value]: [key: string, value: any]) => {
        const field = formSchema[key];

        const handledValue = handleFieldValue(field, value);

        setFieldValue(key, handledValue).then(() => {
          setFieldTouched(key, true);
        });
      }
    );

    store.dispatch(closeGenericModalAction({ name: docReaderModal }));
  };

  return (
    <SReaderWrapper>
      <iframe
        title={fileName}
        src={fileUrl}
        className="document-view-container"
      />
      <div className="document-content-form-wrapper">
        {isFetching && <Spin size="large" />}
        {error && !data && !isFetching && <div>Something went wrong.</div>}
        {!!data && !isFetching && (
          <Form
            name="document_content"
            layout="vertical"
            initialValues={data}
            onFinish={onFinish}
            form={form}
            className="document-content-form"
          >
            <div className="document-fields">
              {Object.entries(data).map(([key, value]) => {
                return (
                  <Form.Item key={key} name={key} label={key}>
                    <Input allowClear />
                  </Form.Item>
                );
              })}
            </div>

            <Form.Item>
              <Flex gap="small" justify="space-between">
                <SButton
                  onClick={() =>
                    store.dispatch(
                      closeGenericModalAction({ name: docReaderModal })
                    )
                  }
                  className="cancel-button"
                >
                  {t('common.refuse')}
                </SButton>
                <Spin size="small" spinning={isSubmitting}>
                  <SButton
                    type="primary"
                    htmlType="submit"
                    className="submit-button"
                  >
                    {t('common.validate')}
                  </SButton>
                </Spin>
              </Flex>
            </Form.Item>
          </Form>
        )}
      </div>
    </SReaderWrapper>
  );
};

export default DocReader;
