import { FC } from 'react';
import { Input, Form } from 'antd';

interface TextAreaProps {
  fieldName: string;
  value: string | undefined;
  isEdit: boolean;
}

const TextArea: FC<TextAreaProps> = ({ fieldName, value, isEdit }) => {
  return isEdit ? (
    <Form.Item name={fieldName}>
      <Input.TextArea allowClear />
    </Form.Item>
  ) : (
    <>{value}</>
  );
};

export default TextArea;
