import styled from 'styled-components';
import { font } from 'App/styleVariables';

export const SWrapper = styled.div`
  margin-top: 20px;
  font-family: ${font};

  .contact-description {
    margin-bottom: 15px;
    font-family: ${font};

    .ant-descriptions-header {
      margin-bottom: 10px;
    }

    .ant-descriptions-item {
      padding-bottom: 8px;
    }

    .ant-descriptions-item-label {
      font-weight: 500;
      color: #101010;
    }
    
    .email-link {
      color: #101010;
      text-decoration: underline;
    }
  }
`;
