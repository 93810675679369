export const LANGUAGES = {
  en: {
    label: 'English',
    countryCode: 'GB',
  },
  fr: {
    label: 'Français',
    countryCode: 'FR',
  },
  cn: {
    label: '中文',
    countryCode: 'CN',
  }
};
