import { FC } from 'react';
import { Descriptions, Flex } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import Badge from 'components/Badge';
import { BadgeType } from 'components/Badge/badge';
import * as components from 'components/ConformityForm/components';
import { SCollapseWrapper } from './styled-components';

interface CollapseProps {
  collapseLabel?: string;
  sectionStatus?: {
    key: string;
    type?: BadgeType;
    title: string;
    customColor?: {
      background: string;
      text: string;
    };
  };
  allData: object;
  content: string[];
  isEdit: boolean;
  formSchema: object;
}

const Collapse: FC<CollapseProps> = ({
  collapseLabel,
  sectionStatus,
  content,
  isEdit,
  formSchema,
  allData,
}) => {
  const isEmpty = content?.every((contentName) => {
    if (Array.isArray(allData[contentName])) {
      return !(allData[contentName].length > 0)
    }

    return !allData[contentName]
  });

  return !isEmpty && (
    <SCollapseWrapper
      items={[
        {
          key: '1',
          label: (
            <Flex align="center" className="status-section-label">
              <p> {collapseLabel} </p>
              {sectionStatus && <Badge {...sectionStatus} />}
            </Flex>
          ),
          children: (
            <Descriptions column={1}>
              {content?.map((contentName, index) => {
                const contentType = formSchema[contentName]?.type;
                const Component = components[contentType];

                const statusOptions =
                  formSchema[contentName]?.fields?.status?.options ||
                  formSchema[contentName]?.statuses;

                return (
                  <Descriptions.Item
                    key={`${collapseLabel}-${contentName}-${index}`}
                    label={formSchema[contentName]?.label}
                    className={`${contentType.toLowerCase()}`}
                  >
                    {Component ? (
                      <Component
                        list={allData[contentName]}
                        value={allData[contentName]}
                        type={contentType}
                        fieldName={contentName}
                        isEdit={isEdit}
                        fileStatusEditable={formSchema[contentName]?.fileStatusEditable}
                        statusOptions={statusOptions}
                        statusType={formSchema[contentName]?.fields?.status?.statusType}
                        options={formSchema[contentName]?.options}
                        fields={formSchema[contentName]?.fields}
                        disabled={formSchema[contentName]?.disabled}
                      />
                    ) : (
                      allData[contentName]
                    )}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          ),
        },
      ]}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      bordered={false}
      className="status-section"
    />
  );
};

export default Collapse;
