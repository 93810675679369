import React from 'react';
import {
  BarChart as BChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { Empty, Typography } from 'antd';
import { StyledChartWrapper, StyledCustomTooltip } from './styled-components';

const BarChart = ({
  styles,
  title,
  data,
  stacked = true,
  multiBar = false,
  customTooltip = false,
  yaxis,
  xaxis,
}: any) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const content = multiBar ? (
        payload.map((item) => (
          <p style={{ color: item.color }}>{`${
            item.payload.tooltip?.[item.dataKey] ||
            item.payload.tooltip?.label ||
            item.name
          } : ${item.value} ${item.unit ? item.unit : ''}`}</p>
        ))
      ) : (
        <p>{`${payload[0].payload.tooltip?.label || payload[0].name} : ${
          payload[0].value
        } ${payload[0].unit ? payload[0].unit : ''}`}</p>
      );
      return (
        <StyledCustomTooltip>
          <p className={'label'}> {label} </p>
          {content}
        </StyledCustomTooltip>
      );
    }

    return null;
  };

  return (
    <StyledChartWrapper styles={styles}>
      {title && (
        <Typography.Title level={3} className="title">
          {title}
        </Typography.Title>
      )}
      {data.length > 0 ? (
        <div className="chart-container">
          <ResponsiveContainer>
            <BChart
              data={data}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" vertical={false} />
              <XAxis
                dataKey="name"
                tickLine={false}
                interval={xaxis?.interval}
                tick={{ fontSize: xaxis?.fontSize }}
              />
              <YAxis
                label={{
                  value: yaxis?.label || 'tonnes',
                  position: 'top',
                  offset: 10,
                }}
                width={55}
                tick={{ fontSize: yaxis?.fontSize }}
              />
              <Tooltip content={customTooltip && <CustomTooltip />} />
              {stacked && data?.some((item) => item?.invisible) && (
                <Bar
                  dataKey="invisible"
                  stackId="a"
                  fill="transparent"
                  legendType="none"
                />
              )}
              {stacked &&
                data?.map((entry, index) => (
                  <Bar
                    dataKey={entry.dataKey}
                    key={`bar-${entry.dataKey}-${index}`}
                    stackId={entry.stackId}
                    fill={entry.color}
                  >
                    {entry.label && (
                      <LabelList
                        dataKey={entry.dataKey}
                        position="center"
                        fill={entry.label?.color || '#000000'}
                      />
                    )}
                  </Bar>
                ))}
              {multiBar &&
                data[0].dataKey?.map((key) => {
                  return (
                    <Bar
                      dataKey={key}
                      fill={data[0].color?.[key]}
                      unit={data[0].unit?.[key]}
                    />
                  );
                })}
              {!stacked && !multiBar && (
                <Bar
                  dataKey={data[0].dataKey}
                  fill={data[0].color}
                  unit={data[0].unit}
                />
              )}
            </BChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </StyledChartWrapper>
  );
};

export default BarChart;
