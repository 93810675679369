import styled from 'styled-components';
import { Descriptions } from 'antd';

export const SDescriptions = styled(Descriptions)`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .ant-descriptions-row {
    &:not(:first-child) .ant-descriptions-item-container {
        margin-left: 20px;
    }

    .ant-descriptions-item-content {
        align-items: center;
    }

    .dot {
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        font-weight: 500;
    }
  }
`;
