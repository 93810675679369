import * as yup from 'yup';
import dayjs from 'dayjs';
import generateValidationObject from './validateGenerator';

//* * */ I add a custom for the yup library. it will check the value and replace the comma with a dot to pass validation
yup.addMethod(yup.number, 'delocalize', function () {
  return this.transform((currentValue, originalValue) =>
    /^-?[0-9]*[.,]?[0-9]+$/.test(originalValue)
      ? parseFloat(originalValue.replace(',', '.'))
      : false
  );
});

const validationOptions = (
  ruleName,
  validationObject,
  fieldName,
  schema,
  isEdit,
  t
) => {
  const isRequired =
    schema[fieldName].rules.required && schema[fieldName].disabled !== true;
  const forbiddenChars = schema[fieldName]?.rules?.forbidden_chars;
  const hasForbiddenChars =
    Array.isArray(forbiddenChars) && forbiddenChars.length > 0;
  const field = schema[fieldName];

  switch (ruleName) {
    case 'required':
      const isNumber = schema[fieldName].rules.number;
      const isEditingDisabled = schema[fieldName].isEditingDisabled;
      const isUploadInput = schema[fieldName].type === 'UploadInput';
      const isEmailInput = schema[fieldName].type === 'EmailInput';
      const isDate = field.type === 'DatePicker';
      const isSelect = field.type === 'Select';
      const isMultiSelect = field.type === 'MultiSelect';
      const isTimePicker = field.type === 'TimePicker';

      if (
        (fieldName === 'password' || fieldName === 'password_confirmation') &&
        (isEdit || !isRequired)
      ) {
        validationObject[fieldName] =
          !(isEditingDisabled && isEdit) &&
          validationObject[fieldName]
            .string()
            .nullable()
            .test('len', `must be `, (val = '') => {
              // ugly solution
              if (fieldName === 'password') {
                localStorage.setItem('val', val);
              }
              return true;
            });
      } else if (isNumber && isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .number()
          .delocalize()
          .typeError(t('validationMessages.invalidNumber'))
          .required(t('validationMessages.requiredField'));
      } else if (isNumber && !isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .number()
          .delocalize()
          .nullable()
          .transform((value, originalValue) => {
            return originalValue === null ? null : value;
          })
          .typeError(t('validationMessages.invalidNumber'));
      } else if (isUploadInput && isRequired) {
        validationObject[fieldName] =
          !(isEditingDisabled && isEdit) &&
          validationObject[fieldName]
            .mixed()
            .required(t('validationMessages.requiredField'))
            .nullable();
      } else if (isEmailInput && !isRequired) {
        validationObject[fieldName] =
          !(isEditingDisabled && isEdit) &&
          validationObject[fieldName].string().nullable();
      } else if (isSelect && isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .string()
          .required(t('validationMessages.requiredField'))
          .nullable()
          .test(
            'valid-select',
            t('validationMessages.notLegalValue'),
            (value) => {
              return field?.options?.some((option) => option.value === value);
            }
          );
      } else if (isSelect && !isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .string()
          .nullable()
          .test(
            'valid-select',
            t('validationMessages.notLegalValue'),
            (value) => {
              // If no value which is valid case for non-required field, then skip the validation
              if (value === null || value === undefined || value === '') {
                return true;
              }

              return field?.options?.some((option) => option.value === value);
            }
          );
      } else if (isMultiSelect && isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .array()
          .of(
            yup
              .string()
              .test(
                'valid-multi-select',
                t('validationMessages.notLegalValues'),
                (value) => {
                  return field?.options?.some(
                    (option) => option.value === value || option.text === value
                  );
                }
              )
          )
          .min(1, t('validationMessages.requiredField'))
          .required(t('validationMessages.requiredField'));
      } else if (isMultiSelect && !isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .array()
          .of(
            yup
              .string()
              .test(
                'valid-multi-select',
                t('validationMessages.notLegalValues'),
                (value) => {
                  // If no value which is valid case for non-required field, then skip the validation
                  if (value === null || value === undefined || value === '') {
                    return true;
                  }

                  return field?.options?.some(
                    (option) => option.value === value || option.text === value
                  );
                }
              )
          )
          .nullable();
      } else if (isDate && isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .date()
          .required(t('validationMessages.requiredField'))
          .nullable()
          .typeError(t('validationMessages.invalidDate'));
      } else if (isDate && !isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .date()
          .nullable()
          .typeError(t('validationMessages.invalidDate'));
      } else if (isTimePicker && isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .string()
          .required(t('validationMessages.requiredField'))
          .nullable()
          .test('valid-time-picker', t('validationMessages.invalidDateAndTime'), (value) =>
            dayjs(value).isValid()
          );
      } else if (isTimePicker && !isRequired) {
        validationObject[fieldName] = validationObject[fieldName]
          .string()
          .nullable()
          .test('valid-time-picker', t('validationMessages.invalidDateAndTime'), (value) =>
            dayjs(value).isValid()
          );
      } else if (
        (hasForbiddenChars && !isRequired) ||
        (hasForbiddenChars && isRequired && isEditingDisabled && isEdit)
      ) {
        validationObject[fieldName] = validationObject[fieldName].string();
      } else {
        validationObject[fieldName] =
          isRequired &&
          !(isEditingDisabled && isEdit) &&
          validationObject[fieldName]
            .string()
            .required(t('validationMessages.requiredField'))
            .nullable();
      }
      break;
    case 'isEmail':
      validationObject[fieldName] = validationObject[fieldName]
        .min(3, t('validationMessages.tooShort'))
        .max(255)
        .email(t('validationMessages.validEmail'));
      break;
    case 'match':
      const matchField = schema[fieldName].rules.match;

      if ((isEdit || !isRequired) && fieldName === 'password_confirmation') {
        validationObject[fieldName] = validationObject[fieldName].test(
          'len',
          t('validationMessages.doesNotMatchField', { field: matchField }),
          (val = '') => {
            // ugly solution
            const pass = localStorage.getItem('val') || '';
            return val === pass;
          }
        );
      } else {
        validationObject[fieldName] = validationObject[fieldName]
          .oneOf([yup.ref(matchField)], t('validationMessages.doesNotMatch'))
          .required(t('validationMessages.doesNotMatch'));
      }

      break;
    case 'exact':
      const exactValue = schema[fieldName].rules.exact;

      validationObject[fieldName] = validationObject[fieldName].test(
        'len',
        t('validationMessages.exactCharacters', { num: exactValue }),
        (val = '') => val.length === exactValue
      );
      break;
    case 'min_length':
      // if (fieldName === 'password' && isEdit) break;

      const minValue = schema[fieldName].rules.min_length;

      validationObject[fieldName] = validationObject[fieldName].min(
        minValue,
        t('validationMessages.minCharacters', { minValue })
      );
      break;
    case 'forbidden_chars':
      validationObject[fieldName] = hasForbiddenChars
        ? validationObject[fieldName].matches(
            new RegExp(`^[^${forbiddenChars.join('')}]*$`),
            t('validationMessages.forbiddenChars', { forbiddenChars: forbiddenChars.join(', ') })
          )
        : validationObject[fieldName];
      break;
    default:
      validationObject[fieldName] = false;
      break;
  }
};

const getValidationSchema = (schema, isEdit, t) =>
  yup
    .object()
    .shape(generateValidationObject(schema, validationOptions, isEdit, t));

export default getValidationSchema;
