// Table Names
export const USERS_TABLE = 'Users';
export const COMPANIES_TABLE = 'Companies';
export const CUSTOMLIST_TABLE = 'CustomList';
export const PRODUCTS_TABLE = 'Products';
export const SMS_TABLE = 'Sms';
export const TRACES_TABLE = 'Traces';
export const CHAINS_TABLE = 'Chains';
export const UPLOAD_TABLE = 'FileUpload';
export const SMSCOMFIRMATION_TABLE = 'SmsConfirmation';
export const HISTORY_DETAILS_TABLE = 'HistoryDetails';
export const SLUDGEANALYSIS_TABLE = 'SludgeAnalysis';
export const COMPANALYSIS_TABLE = 'CompAnalysis';
export const SOILANALYSIS_TABLE = 'SoilAnalysis';
export const WORKFLOW_TABLE = 'Workflow';
export const WORKFLOW_OBJECT_HISTORY_TABLE = 'WorkflowObjectHistory';
export const DASHBOARD_BACKEND_TABLE = 'DashboardBackendTable';

// Modal Names
export const showModal = 'SHOW_ITEM_MODAL';
export const downloadTraceModal = 'HOTKEY_SHOW_TRACE_MODAL';
export const formWorkerModal = 'FORM_WORKER_MODAL';
export const chainsModal = 'SHOW_CHAINS';
export const qrCodeModal = 'QRCODE_PREVIEW_MODAL';
export const fileUploadModal = 'FILE_UPLOAD_MODAL';
export const smsDetailsModal = 'SMS_DETAILS_MODAL';
export const addFileGalleryModal = 'ADD_FILE_GALLERY_MODAL';
export const historyTableModal = 'HISTORY_TABLE_MODAL';
export const workflowObjectHistoryTableModal = 'WORKFLOW_OBJECT_HISTORY_TABLE_MODAL';
export const conformityFormModal = 'CONFORMITY_FORM_MODAL';
export const contactSupplierModal = 'CONTACT_SUPPLIER_MODAL';
export const supplierStatusFormModal = 'SUPPLIER_STATUS_FORM_MODAl';

export const CUSTOM_MODAL_WIDTH = 1000;

// Modal Titles
export const USERS_ADD_TITLE = (t) => `${t('form.addUser')} :`;
export const USERS_EDIT_TITLE = (t) => `${t('form.editUser')} :`;
export const COMPANIES_ADD_TITLE = (t) => `${t('form.addOrganization')} :`;
export const COMPANIES_EDIT_TITLE = (t) => `${t('form.editOrganization')} :`;
export const CUSTOMLIST_SHOW_TITLE = (t) => `${t('form.showItem')} :`;
export const CUSTOMLIST_EDIT_TITLE = (t) => `${t('form.editItem')} :`;
export const CUSTOMLIST_ADD_TITLE = (t) => `${t('form.addItem')} :`;
export const UPLOADS_ADD_TITLE = (t) => `${t('form.addUpload')} :`;
export const UPLOADS_SHOW_TITLE = (t) => `${t('form.showUploadDetails')} :`;
export const UPLOADS_EDIT_TITLE = (t) => `${t('form.editUpload')} :`;
export const TRACES_ADD_TITLE = (t) => `${t('form.addTrace')} :`;
export const TRACES_SHOW_TITLE = (t) => `${t('form.showTrace')} :`;
export const TRACES_EDIT_TITLE = (t) => `${t('form.editTrace')} :`;
export const TRACES_HISTORY_TITLE = (t) => `${t('form.historyDetails')} :`;
export const TRACES_SHOW_VERSION_TITLE = (t) => `${t('form.edited')} :`;
export const PRODUCTS_ADD_TITLE = (t) => `${t('form.addProduct')} :`;
export const PRODUCTS_EDIT_TITLE = (t) => `${t('form.editProduct')} :`;
export const CHAIN_DETAILS_TITLE = (t) => `${t('chains.chain')} :`;
export const QR_CODE_TITLE = (t) => `${t('qrCode.qrCode')} :`;
export const ANALYSIS_SHOW_TITLE = (t) => `${t('common.view')} :`;
export const SMSCOMFIRMATION_SHOW_TITLE = (t) => `${t('form.viewUser')} :`;
export const WORKFLOW_SHOW_TITLE = (t) => `${t('form.showItem')} :`;
export const WORKFLOW_OBJECT_HISTORY_TITLE = (t) => `${t('form.history')} :`;
