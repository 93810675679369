import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import { getHomePage } from './reducer';
import { Spin } from 'antd';
import {
  BlocksComponent,
  HeaderLink,
  JimoPlatformTourButton,
} from './containers';

class Home extends React.Component {
  componentDidMount() {
    const { getPage } = this.props;
    getPage();
  }

  componentDidUpdate(prevProps, _prevState) {
    const { getPage, businessName, language } = this.props;

    if (
      businessName !== prevProps.businessName ||
      prevProps.language !== language
    ) {
      getPage();
    }
  }

  render() {
    const { homepage, schema, isLoading } = this.props;

    const options = {
      replace: (domNode) => {
        if (domNode?.attribs?.id === 'reactComponent' && schema) {
          const Component = domNode?.attribs?.component;
          const props = schema[Component];

          if (!props) {
            return <div style={{ display: 'none' }} />;
          }

          switch (Component) {
            case 'BlocksComponent':
              return <BlocksComponent data={props} />;
            case 'HeaderLink':
              return <HeaderLink />;
            case 'PlatformTourButton':
              return <JimoPlatformTourButton {...props} />;
            default:
              return null;
          }
        }
      },
    };

    const loader = (
      <div
        style={{
          padding: '80px 55px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );

    if (!homepage) {
      return loader;
    }

    if (!isLoading) {
      const homepageString = schema
        ? Object.keys(schema).reduce(
            (acc, varName) => acc.replace(`{${varName}}`, schema[varName]),
            homepage
          )
        : homepage;
      const Homepage = Parser(homepageString, options) || '';
      return <div className={'homepage-wrapper'}>{Homepage && Homepage}</div>;
    } else {
      return loader;
    }
  }
}

Home.propTypes = {
  /**
   * Function that sends request to the api to receive data for the Homepage.
   */
  getPage: PropTypes.func,
  /**
   * String with the html layout of the Homepage to be parsed.(Located in the global Redux state)
   */
  homepage: PropTypes.string,
  /**
   * Data for the dynamic Homepage.(Located in the global Redux state)
   */
  schema: PropTypes.object,
  /**
   * Name of the business
   */
  businessName: PropTypes.string,
  /**
   * Determines whether the home page content is loading or not
   */
  isLoading: PropTypes.bool,
};

const mapDispatchToProps = {
  getPage: getHomePage,
};

const mapStateToProps = (state) => ({
  businessName: state.auth.currentUser.attributes.business.name,
  isLoading: state.homePage.isLoading,
  homepage: state.homePage.page,
  schema: state.homePage.homepage_schema,
  language: state.auth.currentUser.attributes.lang,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
