import dayjs from 'dayjs';

export const handleFieldValue = (field, value) => {
  if (!field) return null;

  switch (field.type) {
    case 'Select':
      const selectOption = field.options.filter(
        (option) => option.value === value || option.text === value
      )?.[0];
      const selectValue = selectOption ? selectOption.value : value;
      return selectValue;

    case 'MultiSelect':
      const filteredOption = field.options.filter(
        (option) => option.value === value || option.text === value
      )?.[0];
      const selectValues = filteredOption ? [filteredOption.value] : [value];
      return selectValues;

    case 'DatePicker':
      return dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : value;

    case 'TimePicker':
      return dayjs(value).isValid()
        ? dayjs(value).format('YYYY-MM-DD HH:mm:ss')
        : value;

    default:
      return value;
  }
};
