import * as yup from "yup";

const generateValidationObject = (schema, validateOptions, isEdit, t) => {
  let validationObject = {};

  Object.keys(schema).forEach(fieldName => {
    if (!validationObject[fieldName] && schema[fieldName].type === 'FieldArray') {
      validationObject[fieldName] = yup;
      const compositionFields = schema[fieldName].fields;
      let shapeObj = {};

      Object.keys(compositionFields).forEach(compositionFieldName => {
        shapeObj[compositionFieldName] = yup;

        Object.keys(compositionFields[compositionFieldName].rules).forEach(ruleName => {
          validateOptions(
            ruleName,
            shapeObj,
            compositionFieldName,
            compositionFields,
            isEdit,
            t
          );
        })
      })

      validationObject[fieldName] = validationObject[fieldName]
        .array()
        .of(yup.object().shape(shapeObj))
        .required();
    }
    if (!validationObject[fieldName]) {
      validationObject[fieldName] = yup;

      Object.keys(schema[fieldName].rules).forEach(ruleName => {
        validateOptions(
          ruleName,
          validationObject,
          fieldName,
          schema,
          isEdit,
          t
        );
      })
    }
  })

  return validationObject;
};

export default generateValidationObject;