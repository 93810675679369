import { FC } from 'react';
import { connect } from 'react-redux';
import {
  EDIT_OBJECT_ACTION,
  EDIT_TRACE_ACTION,
  HISTORY_TRACE_ACTION,
} from '../actions';
import { LikeLink } from '../styled-components';

type RowActionsProps = {
  actions: [];
  onRowAction: Function;
  record: any;
  historyObjectId?: number | string;
  isAdmin: boolean;
  businessName: string;
};

const statuses = {
  SUCCESS: true,
  REJECTED: true,
  INTERRUPTED: true,
  ERROR: true,
};

const excluded = {
  edit: { PB: true },
  show: { Kizéo: true },
};

const RowActions: FC<RowActionsProps> = ({
  actions,
  onRowAction,
  record,
  historyObjectId,
  isAdmin,
  businessName,
}) => {
  return (
    <>
      {actions.map(({ label, action, errorMessage }, fieldName) => {
        const { status, DisplayTraceTtype } = record;
        if (label === 'log' && !statuses[status]) return null;

        if (
          DisplayTraceTtype &&
          excluded[action] &&
          excluded[action][DisplayTraceTtype]
        )
          return null;

        if (action === EDIT_TRACE_ACTION && !record.isEditable) {
          return null;
        }

        if (action === HISTORY_TRACE_ACTION && !record.isEdited) {
          return null;
        }

        if (
          action === EDIT_OBJECT_ACTION &&
          record.object_type === 'KPI-IMPACT' &&
          !isAdmin &&
          businessName === 'agec'
        ) {
          return null;
        }

        return (
          <LikeLink
            key={fieldName}
            onClick={(e) => {
              e.preventDefault();
              onRowAction({ record, action, errorMessage, historyObjectId });
            }}
            data-name={`row-action-${action}`}
          >
            {label}
          </LikeLink>
        );
      })}
    </>
  );
};

const mapStateToProps = ({
  auth: {
    currentUser: {
      attributes: { role, company, business },
    },
  },
}) => ({
  isAdmin:
    (role === 'Admin' || role === 'SuperAdmin') &&
    company &&
    company.kind === 'Admin',
  businessName: business.name,
});

export default connect(mapStateToProps, null)(RowActions);
