import styled from 'styled-components';
import { Form as FForm } from 'formik';
import { Button, Spin, List } from 'antd';
import { getHexFromString } from 'lib/util';
import { borderRadius, colors, font, transition } from 'App/styleVariables';

export const StyledButton = styled(Button)`
  font-size: ${({ theme }) => theme.form.submitButton?.size} !important;
  font-weight: ${({ theme }) => theme.form.submitButton?.weight} !important;
  font-family: ${({ theme }) => theme.form.submitButton?.family} !important;
  color: ${({ theme }) => theme.form.submitButton?.color} !important;
  border: none !important;
  background: ${({ theme }) => theme.form.submitButton?.passive} !important;

  &:hover {
    color: ${({ theme }) => theme.form.submitButton?.color} !important;
    background: ${({ theme }) => theme.form.submitButton?.active} !important;
  }
`;

export const StyledSpin = styled(Spin)`
  font-size: ${({ theme }) => theme.form.submitButton?.size} !important;
  font-weight: ${({ theme }) => theme.form.submitButton?.weight} !important;
  font-family: ${({ theme }) => theme.form.submitButton?.family} !important;
  color: ${({ theme }) => theme.form.submitButton?.color} !important;
  border: none !important;
`;

export const StyledBackButton = styled(Button)`
  font-size: ${({ theme }) => theme.form.backButton.size} !important;
  font-weight: ${({ theme }) => theme.form.backButton.weight} !important;
  font-family: ${({ theme }) => theme.form.backButton.family} !important;
  color: ${({ theme }) => theme.form.backButton.colorPassive} !important;
  border: ${({ theme }) => theme.form.backButton.borderPassive} !important;

  &:hover {
    color: ${({ theme }) => theme.form.backButton.colorActive || colors.secondary} !important;
    border: ${({ theme }) => theme.form.backButton.borderActive || `1px solid ${colors.secondary}`} !important;
  }
`;

export const StyledFForm = styled(FForm)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  
  #form-filling-form-content {
    font-family: ${({ theme }) => theme.form.item?.label?.family};
    color: ${({ theme }) => theme.form.item?.label?.color};
    
    .hidden-field {
      display: none !important;
    }
  }

  .ant-input,
  .ant-picker,
  .ant-upload .ant-btn,
  .ant-select .ant-select-selector{
    font-family: ${(props) => props.theme.form.item?.label?.family};
    
    &::placeholder {
      font-family: ${(props) => props.theme.form.item?.label?.family};
    }
  }

  .ant-input:not(.ant-input-disabled),
  .ant-picker {
    &:hover,
    &:focus {
      border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
    }

    &:focus {
      box-shadow: ${({ theme }) => `0 0 0 2px ${theme.form.item?.input?.boxShadowActiveColor}`} !important;
    }
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
  .ant-picker.ant-picker-focused,
  .ant-select:not(.ant-select-disabled).ant-select-focused .ant-select-selector {
    border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
    box-shadow: ${({ theme }) => `0 0 0 2px ${theme.form.item?.input?.boxShadowActiveColor}`} !important;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    &:hover,
    &:focus {
        border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
    }

    .ant-input:not(.ant-input-disabled) {
      &:hover,
      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  .ant-upload .ant-btn:not(.ant-btn-disabled) {
    &:hover,
    &:focus {
      border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
      color: ${({ theme }) => theme.button.default?.activeColor} !important;
    }
  }

  .ant-select {
    width: 100%;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
  }
  
  .form-checkbox-wrapper {
    padding-top: 5px
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background: transparent;
  }
  
  .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
    &:hover {
      background: transparent;
    }    
  }

  .document-select-btn {
    width: 100%;

    &:hover,
    &:focus {
      border-color: ${({ theme }) => 
        getHexFromString(theme.form.item?.input?.borderActive)} !important;
      color: ${({ theme }) => theme.button.default?.activeColor} !important;
    }
  }
`;

export const SListItem = styled(List.Item)`
  padding: 10px !important;
  border-radius: ${borderRadius};
  border: 1px solid #d9d9d9;
  margin: 10px 0;
  transition: ${transition};

  &:hover {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  }

  &:last-child {
    margin-bottom: 0;
  }

  .ant-list-item-meta {
    align-items: center !important;
  }

  .ant-list-item-meta-avatar {
    margin-right: 10px !important;
    font-size: 16px;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: #eaecf8;
    color: #3643ba !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-item-title {
    margin-bottom: 0;
    margin-right: 15px;
    font-family: ${font};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 270px;
    font-weight: 400;
  }

  .list-item-title-link {
    font-family: ${font};
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2;
  }

  .ant-list-item-action .ant-form-item {
    margin-bottom: 0;
  }

  .list-action-btn {
    padding: 0 10px;
  }
`;

export default {
  form: {
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  choice: {
    position: 'absolute',
    top: -78,
    left: '50%',
    color: 'rgba(0, 0, 0, 0.85)',
    fontweight: 500,
    fontSize: '16px',
    lineHeight: '22px',
  },
  arrow: {
    position: 'absolute',
    top: -66,
    left: -25,
    cursor: 'pointer',
    fontSize: '13px',
  },
  spin: {
    display: 'block',
  },
  star: {
    position: 'absolute',
    top: -5,
    left: -7,
    color: 'red',
  },
  stars: {
    position: 'absolute',
    top: -3,
    left: -8,
    color: 'red',
  },
  wrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'flex-start',
    marginBottom: '12px',
  },
  wrapTitle: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  composTitle: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: '0px',
    alignContent: 'center',
    textDecoration: 'underline',
  },
  wrapItem: {
    marginRight: '0',
    width: '43%',
    textAlign: 'right',
    marginBottom: '0px',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '10px',
  },
  label: {
    position: 'relative',
    lineHeight: '1.5',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.65)',
    fontVariant: 'tabular-nums',
  },
  labelWrapper: {
    display: 'block',
    minHeight: '32px',
    marginRight: '20px',
  },

  input: {
    position: 'relative',
    width: '370px',
    marginBottom: 0,
  },

  inputCompos: {
    position: 'relative',
    width: '370px',
    marginBottom: 0,
    paddingLeft: '30px',
    // paddingLeft: '40px',
  },
  error: {
    position: 'absolute',
    display: 'block',
    width: '300px',
    left: 398,
    color: 'red',
  },
  composError: {
    display: 'block',
    lineHeight: 1.2,
    color: '#f5222d',
  },
  compos: {
    width: '100%',
    paddingTop: '2px',
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '32%',
  },
  seperatorLine: {
    marginTop: '25px',
    marginBottom: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '2px',
    background: 'grey',
    borderRadius: '30%',
    // opacity: '0.8',
    width: '70%',
    opacity: '0.5',
  },
  topLine: {
    marginTop: '25px',
    marginBottom: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '2px',
    width: '100%',
    background: 'grey',
    borderRadius: '20px',
    opacity: '0.8',
  },
};
