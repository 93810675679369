import { FC } from 'react';
import { SItem } from './styled-components';

export type DotItemType =
  | 'success'
  | 'in_progress'
  | 'interrupted'
  | 'critical';

interface DotItemProps {
    color?: string;
    type?: DotItemType;
    text?: string;
}

const DotItem: FC<DotItemProps> = ({ color, text, type }) => {
  return (
    <SItem color={color} type={type}>
      {text && <span className="text">{text}</span>}
    </SItem>
  );
};

export default DotItem;
