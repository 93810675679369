import { FC } from 'react';

interface IconProps {
  color?: string;
  className?: string;
}

const SupportIcon: FC<IconProps> = ({ color = '#000000', ...props }) => {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.144 6.30723C8.57804 6.07535 9.04511 6.00076 9.49986 6.00073C10.0263 6.0007 10.6377 6.17363 11.1315 6.57829C11.6485 7.00192 11.9999 7.65186 11.9999 8.5C11.9999 9.47459 11.3112 10.0037 10.9229 10.302C10.8927 10.3252 10.8644 10.347 10.8383 10.3675C10.4137 10.7012 10.2499 10.8795 10.2499 11.25C10.2499 11.6642 9.91411 12 9.4999 12C9.08569 12 8.7499 11.6642 8.7499 11.25C8.7499 10.116 9.46115 9.54195 9.9115 9.18806C10.4248 8.78469 10.4999 8.69484 10.4999 8.5C10.4999 8.10296 10.3513 7.87825 10.1808 7.73853C9.98714 7.57985 9.72352 7.50072 9.49994 7.50073C9.20469 7.50075 9.00176 7.54962 8.8508 7.63027C8.70735 7.7069 8.55557 7.84146 8.40923 8.11137C8.21181 8.47551 7.75657 8.61066 7.39244 8.41324C7.0283 8.21582 6.89315 7.76058 7.09057 7.39644C7.35423 6.91012 7.70245 6.54311 8.144 6.30723Z"
        fill={color}
      />
      <path
        d="M9.50007 15.0001C10.0524 15.0001 10.5001 14.5524 10.5001 14.0001C10.5001 13.4477 10.0524 13 9.50007 13C8.94775 13 8.5 13.4477 8.5 14.0001C8.5 14.5524 8.94775 15.0001 9.50007 15.0001Z"
        fill={color}
      />
      <path
        d="M9.50005 3C5.35792 3 2.00005 6.35786 2.00005 10.5C2.00005 11.6329 2.25172 12.7088 2.70271 13.6734C2.44867 14.6714 2.16486 15.7842 1.97847 16.5147C1.74544 17.428 2.56787 18.2588 3.48221 18.039C4.23244 17.8587 5.38546 17.5819 6.41294 17.337C7.35525 17.7631 8.40089 18 9.50005 18C13.6422 18 17.0001 14.6421 17.0001 10.5C17.0001 6.35786 13.6422 3 9.50005 3ZM3.50005 10.5C3.50005 7.18629 6.18634 4.5 9.50005 4.5C12.8138 4.5 15.5001 7.18629 15.5001 10.5C15.5001 13.8137 12.8138 16.5 9.50005 16.5C8.53932 16.5 7.63335 16.2748 6.83009 15.8749L6.58667 15.7537L6.32216 15.8167C5.39943 16.0363 4.33336 16.2919 3.5344 16.4838C3.73315 15.7047 3.99632 14.6724 4.22448 13.7757L4.29467 13.4998L4.16415 13.2468C3.73993 12.4246 3.50005 11.4914 3.50005 10.5Z"
        fill={color}
      />
      <path
        d="M14.5001 21.0001C12.5307 21.0001 10.7386 20.241 9.40039 18.9995C9.43359 18.9999 9.46683 19.0001 9.50011 19.0001C10.2181 19.0001 10.9152 18.911 11.5812 18.7434C12.4451 19.2254 13.4405 19.5001 14.5001 19.5001C15.4608 19.5001 16.3668 19.2748 17.17 18.8749L17.4135 18.7537L17.678 18.8167C18.5995 19.0361 19.6431 19.2625 20.4171 19.4262C20.2422 18.6757 20.0035 17.6711 19.7756 16.7757L19.7055 16.4999L19.836 16.2469C20.2602 15.4247 20.5001 14.4915 20.5001 13.5001C20.5001 11.3853 19.4061 9.52617 17.7531 8.45761C17.5747 7.73435 17.3038 7.04756 16.9535 6.41052C19.8908 7.42684 22.0001 10.2171 22.0001 13.5001C22.0001 14.6332 21.7483 15.7094 21.2971 16.6741C21.5501 17.6821 21.8064 18.774 21.9689 19.4773C22.1733 20.3623 21.3939 21.1633 20.5014 20.9768C19.7743 20.8248 18.6318 20.581 17.588 20.3367C16.6455 20.763 15.5996 21.0001 14.5001 21.0001Z"
        fill={color}
      />
    </svg>
  );
};

export default SupportIcon;
