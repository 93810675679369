import { FC, useState } from 'react';
import { Button, message, Space } from 'antd';
import {
  PlusCircleOutlined,
  CheckOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ExportTemplatesButton from 'containers/ExportTemplatesButton';
import { HeaderAction, TableFilters } from 'sections/GenericTablePage/types';
import UniqIdsGenerator from 'lib/uniqIdsGenerator';
import { downloadFile } from 'lib/util';
import client from 'lib/apiClient';
import {
  EXPORT_ALL_ITEMS_ACTION,
  EXPORT_TEMPLATES_ACTION,
} from 'sections/GenericTablePage/actions';

const prefix_id = 'generic-header-actions';

const idGenerator = new UniqIdsGenerator(prefix_id);

type Props = {
  actions: Array<HeaderAction>;
  handleClick: Function;
  isAllowed: boolean;
  total?: number;
  list_name?: string;
  searchField?: string;
  filter?: TableFilters;
};

export const icons = {
  'plus-circle': PlusCircleOutlined,
  'check': CheckOutlined,
  'download': DownloadOutlined,
};

const HeaderActions: FC<Props> = ({
  actions,
  handleClick,
  isAllowed,
  total,
  list_name,
  searchField,
  filter,
}) => {
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const { t } = useTranslation();

  const exportAllItems = async () => {
    const worker = new Worker(
      new URL('./../../export.worker.ts', import.meta.url)
    );

    setIsExporting(true);

    const { data, object_type }: any = await client.getCustomListsAPI({
      list_name,
      query: searchField,
      ...filter,
      lines_per_page: total,
    })();

    const workerMaker = (type, arg) => {
      if (window.Worker) {
        worker.postMessage({ type, arg });
      }
    };

    workerMaker('csvFormat', data);

    worker.onmessage = (e) => {
      const response = e.data;
      const data = response.data;
      const type = response.type;

      switch (type) {
        case 'formatted':
          downloadFile(data, `${object_type || 'export'}.csv`);
          setIsExporting(false);
          message.success(t('messages.fileDownloaded'));
          break;
        case 'error':
          setIsExporting(false);
          message.error(data);
          break;
        default:
          console.log('Unexpected behavior with type:', type);
          setIsExporting(false);
          break;
      }
    };
  };

  return (
    <Space>
      {Array.isArray(actions) &&
        actions.map(
          ({ label, action, icon, display, uploadType }, fieldName) => {
            const IconComponent = icons[icon];
            const isExportItemsBtn = action.includes(EXPORT_ALL_ITEMS_ACTION);
            const isExportTemplatesBtn = action.includes(
              EXPORT_TEMPLATES_ACTION
            );
            const isAddBtn = !isExportItemsBtn && !isExportTemplatesBtn;
            let displayButton;

            switch (true) {
              case isAddBtn:
                displayButton = display !== undefined ? display : true;

                return displayButton ? (
                  <Button
                    key={`${label}${fieldName}`}
                    onClick={() => handleClick(action)}
                    id={idGenerator.getTrimmedString(label)}
                    disabled={!isAllowed}
                    className={'header-add-button'}
                  >
                    {icon && IconComponent && <IconComponent />}
                    {label}
                  </Button>
                ) : null;
              case isExportItemsBtn:
                displayButton = display || false;

                return display ? (
                  <Button
                    key={`${label}${fieldName}`}
                    onClick={() => exportAllItems()}
                    id={idGenerator.getTrimmedString(label)}
                    className={`header-button`}
                    disabled={isExporting}
                  >
                    {isExporting ? (
                      <LoadingOutlined />
                    ) : (
                      <Space size={4}>
                        {icon && IconComponent && <IconComponent />}
                        {label}
                      </Space>
                    )}
                  </Button>
                ) : null;
              case isExportTemplatesBtn:
                return display ? (
                  <ExportTemplatesButton
                    key={`${label}${fieldName}`}
                    label={label}
                    icon={IconComponent}
                    uploadType={uploadType}
                  />
                ) : null;
              default:
                return null;
            }
          }
        )}
    </Space>
  );
};

export default HeaderActions;
