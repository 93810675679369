import { FC, useRef, useState } from 'react';
import { SBadge } from './styled-components';
import {
  CheckOutlined,
  CloseOutlined,
  EllipsisOutlined,
  MonitorOutlined,
  HourglassOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { OnboardingIcon } from 'components/library/Icons';
import { Tooltip } from 'antd';

export type BadgeType =
  | 'success'
  | 'rejected'
  | 'error'
  | 'queued'
  | 'checking'
  | 'pending'
  | 'in_progress'
  | 'interrupted'
  | 'minimal'
  | 'informative'
  | 'important'
  | 'significant'
  | 'critical'
  | 'invited'
  | 'onboarding';

export interface BadgeProps {
  title?: string;
  type?: BadgeType;
  icon?: string;
  customColor?: {
    background: string;
    text: string;
  };
  displayIcon?: boolean;
  displayStar?: boolean;
}

interface CurrentRef {
  scrollWidth: number;
  clientWidth: number;
}

const ICONS = {
  success: CheckOutlined,
  rejected: CloseOutlined,
  error: CloseOutlined,
  queued: EllipsisOutlined,
  checking: MonitorOutlined,
  pending: HourglassOutlined,
  in_progress: LoadingOutlined,
  interrupted: ExclamationCircleOutlined,
  invited: MailOutlined,
  onboarding: OnboardingIcon,
};

const Badge: FC<BadgeProps> = ({
  title,
  type,
  icon,
  customColor,
  displayIcon = true,
  displayStar = false,
}) => {
  const Icon = ICONS[icon] || ICONS[type];
  const contentRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseOver = () => {
    const { scrollWidth, clientWidth } = contentRef.current as CurrentRef;
    if (scrollWidth > clientWidth) setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <SBadge
      type={type}
      className={'badge'}
      $customColor={customColor}
      $star={displayStar}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
        title={title}
        open={showTooltip}
        overlayClassName="custom-tooltip"
        placement="bottom"
      >
        <div className="badge-content" ref={contentRef}>
          {displayIcon && Icon && <Icon className="badge-icon" />}
          {title}
          {displayStar && <span className="badge-star">*</span>}
        </div>
      </Tooltip>
    </SBadge>
  );
};

export default Badge;
