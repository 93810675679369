import {
  COMPANIES_TABLE,
  CUSTOMLIST_TABLE,
  PRODUCTS_TABLE,
  TRACES_TABLE,
  UPLOAD_TABLE,
  USERS_TABLE,
  SMSCOMFIRMATION_TABLE,
  SLUDGEANALYSIS_TABLE,
  COMPANALYSIS_TABLE,
  SOILANALYSIS_TABLE,
  WORKFLOW_TABLE,
} from './constants';

export const SHOW_TRACE_ACTION = 'showTrace';
export const EDIT_TRACE_ACTION = 'editTrace';
export const SHOW_TRACE_WITH_FETCH = 'showTraceWithFetch';
export const EDIT_TRACE_WITH_FETCH = 'editTraceWithFetch';
export const ADD_TRACE_ACTION = 'addTrace';
export const ADD_USER_ACTION = 'addUser';
export const ADD_ORGANIZATION_ACTION = 'addOrganization';
export const EDIT_COMPANY_ACTION = 'editCompany';
export const EDIT_USER_ACTION = 'editUser';
export const ADD_ITEM_ACTION = 'addItem';
export const SHOW_OBJECT_ACTION = 'show';
export const SHOW_ITEM_ACTION = 'showItem';
export const EDIT_OBJECT_ACTION = 'edit';
export const EDIT_ITEM_ACTION = 'editItem';
export const ADD_PRODUCT_ACTION = 'addProduct';
export const EDIT_PRODUCT_ACTION = 'editProduct';
export const QR_CODE_PREVIEW = 'previewQR';
export const CHAIN_GRAPH_ACTION = 'Graph';
export const CHAIN_TABLE_ACTION = 'Table';
export const SHOW_UPLOAD_ACTION = 'showUpload';
export const EDIT_UPLOAD_ACTION = 'editUpload';
export const SHOW_FILE_UPLOAD_ACTION = 'openFile';
export const SHOW_LOG_UPLOAD_ACTION = 'openLog';
export const ADD_UPLOAD_ACTION = 'uploadTrace';
export const SHOW_SMS_ACTION = 'showSms';
export const RESEND_ALL_SMS_CONFIRMATION_ACTION = 'resendAllSmsConfirmation';
export const SHOW_SMS_CONFIRMATION_ACTION= 'showSmsConfirmation';
export const SHOW_USER_ACTION = 'showUser';
export const RESEND_SMS_CONFIRMATION_ACTION = 'resendSmsConfirmation';
export const HISTORY_TRACE_ACTION = 'historyTrace';
export const SHOW_TRACE_VERSION_ACTION = 'showTraceVersion';
export const SHOW_ANALYSIS_ACTION = 'showAnalysisTrace';
export const VALIDATE_DATA_ACTION = 'validate';
export const INVALIDATE_DATA_ACTION = 'invalidate';
export const VALIDATE_ALL_ACTION = 'validAll';
export const SHOW_WORKFLOW_ACTION = 'showWorkflow';
export const EDIT_WORKFLOW_ACTION = 'editWorkflow';
export const ADD_WORKFLOW_ACTION = 'addWorkflow';
export const EXPORT_ALL_ITEMS_ACTION = 'exportItems';
export const EXPORT_TEMPLATES_ACTION = 'exportTemplates';
export const SHOW_PRODUCT_STATUS = 'showProductStatus';
export const EDIT_PRODUCT_STATUS = 'editProductStatus';
export const CONTACT_SUPPLIER = 'contactSupplier';
export const WORKFLOW_OBJECT_HISTORY_ACTION = 'showWorkflowHistory';
export const WORKFLOW_OBJECT_VERSION_ACTION = 'showWorkflowVersion';

export const HEADER_ACTIONS: any = (t) => ({
  [USERS_TABLE]: [
    {
      label: t('form.addItem'),
      action: ADD_USER_ACTION,
      icon: 'plus-circle',
    },
  ],
  [TRACES_TABLE]: [
    {
      label: t('form.addItem'),
      action: ADD_TRACE_ACTION,
      icon: 'plus-circle',
    },
  ],
  [UPLOAD_TABLE]: [
    {
      label: t('common.downloadTemplates'),
      action: EXPORT_TEMPLATES_ACTION,
      icon: 'download',
    },
    {
      label: t('form.addItem'),
      action: 'uploadTrace',
      icon: 'plus-circle',
    },
  ],
  [COMPANIES_TABLE]: [
    {
      label: t('form.addItem'),
      action: ADD_ORGANIZATION_ACTION,
      icon: 'plus-circle',
    },
  ],
  [CUSTOMLIST_TABLE]: [
    {
      label: t('common.exportAll'),
      action: EXPORT_ALL_ITEMS_ACTION,
      icon: 'download',
    },
    {
      label: t('form.addItem'),
      action: ADD_ITEM_ACTION,
      icon: 'plus-circle',
    },
  ],
  [PRODUCTS_TABLE]: [
    {
      label: t('form.addItem'),
      action: ADD_PRODUCT_ACTION,
      icon: 'plus-circle',
    },
  ],
  [SMSCOMFIRMATION_TABLE]: [
    {
      label: t('table.resendAll'),
      action: RESEND_ALL_SMS_CONFIRMATION_ACTION,
      icon: 'message'
    }
  ],
  [SLUDGEANALYSIS_TABLE]: [
    {
      label: t('table.confirmConsistent'),
      action: VALIDATE_ALL_ACTION,
      icon: 'check',
    }
  ],
  [COMPANALYSIS_TABLE]: [
    {
      label: t('table.confirmConsistent'),
      action: VALIDATE_ALL_ACTION,
      icon: 'check',
    }
  ],
  [SOILANALYSIS_TABLE]: [
    {
      label: t('table.confirmConsistent'),
      action: VALIDATE_ALL_ACTION,
      icon: 'check',
    }
  ],
  [WORKFLOW_TABLE]: [
    {
      label: t('form.addItem'),
      action: ADD_WORKFLOW_ACTION,
      icon: 'plus-circle',
    },
  ],
});
