import { FC } from 'react';
import { SAvatar } from './styled-components';

interface AvatarProps {
  src?: string;
  firstName: string;
  lastName: string;
  size?: number;
  context?: 'header' | 'table';
  backgroundColor?: string;
  color?: string;
}

const Avatar: FC<AvatarProps> = ({
  src,
  firstName,
  lastName,
  size = 42,
  context,
  backgroundColor,
  color,
}) => {
  const initials = `${
    firstName ? firstName.substring(0, 1).toUpperCase() : ''
  }${lastName ? lastName?.substring(0, 1).toUpperCase() : ''}`;

  return (
    <SAvatar
      src={src}
      size={size}
      context={context}
      $backgroundColor={backgroundColor}
      $color={color}
    >
      {initials}
    </SAvatar>
  );
};

export default Avatar;
