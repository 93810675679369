const parseName = (name: string) => {
  let firstBracketIndex = name.indexOf('[');
  let secondBracketIndex = name.indexOf(']');

  if (
    firstBracketIndex !== -1 &&
    secondBracketIndex !== -1 &&
    secondBracketIndex > firstBracketIndex
  ) {
    let parentName = name.substring(0, firstBracketIndex);
    let index = name.substring(firstBracketIndex + 1, secondBracketIndex);

    return {
      parentName,
      index,
    };
  } else {
    return null;
  }
};

const checkFieldExistence = (
  obj: any,
  key: string,
  name: Array<string | number>,
  source: { parentName: string; index: string } | null
) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      name.push(prop);

      if (prop === key) {
        if (name.length > 2) {
          if (name[0] === source?.parentName) {
            name[1] = source?.index;
          }
        }

        return true;
      } else if (Array.isArray(obj[prop])) {
        // Composition/Collection fields

        for (let i = 0; i < obj[prop].length; i++) {
          if (typeof obj[prop][i] === 'object') {
            name.push(i);

            if (checkFieldExistence(obj[prop][i], key, name, source)) {
              return true;
            }

            name.pop(); // remove when no found
          }
        }
      }
      name.pop(); // remove when no found
    }
  }
};

export const setAutofillValues = (
  mainObj: object,
  updateObj: object,
  sourceName: string,
  setFieldValue: (name: string, value: string) => void
) => {
  const parsedName = parseName(sourceName);

  Object.keys(updateObj).forEach((key) => {
    const path = [];
    const found = checkFieldExistence(mainObj, key, path, parsedName);
    if (found) {
      let name;
      if (path.length === 1) {
        name = path[0];
      } else {
        name = `${path[0]}[${path[1]}][${path[2]}]`;
      }
      setFieldValue(name, updateObj[key]);
    }
  });
};
