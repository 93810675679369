import AbstractResourceFactory from './abstract-resource-factory';
import client from '../../../../../lib/apiClient';
import { Table } from '../../../types';
import formErrorHandler from '../../formErrorHandler';

class Workflow implements AbstractResourceFactory {
  defaultFilterState = {
    sorting: '%5B%5D',
    lines_per_page: 10,
  };
  toggleArrow = 2560;
  async getAll(
    page = 1, 
    searchField = '', 
    filters: object,
    list_name: string,
    filter_params,
    pageSchema,
  ): Promise<Table> {
    const optionName: string = pageSchema?.options?.name || '';
    
    const { data, table_scheme, numberOfElements } = await client.getWorkflows({
      name: optionName,
      query: searchField,
      ...filters,
      page_number: page,
    });

    return {
      data: data,
      schema: table_scheme,
      total: numberOfElements,
    };
  }

  async add({ data, actions }: any) {
    try {
      await client.postWorkflow(data);
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }

  async edit({ data, actions }: any) {
    try {
      await client.putWorkflow(data);
      actions.setSubmitting(false);
    } catch(e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }
}

export default Workflow;
