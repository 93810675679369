import { FC, useEffect, useState } from 'react';
import { Button, Flex, Form, Spin, Descriptions } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import * as components from './components';
import client from 'lib/apiClient';
import { store } from 'store/nextStore';
import { closeGenericModalAction } from 'containers/GenericModal/reducer';
import { conformityFormModal } from 'sections/GenericTablePage/constants';
import { SFormWrapper } from './styled-components';
import { useTranslation } from 'react-i18next';

const CONFORMITY_STATUS = 'ConformityStatus';
const CONFORMITY_FILES = 'ConformityFiles';
const QUALITY_STATUS = 'QualityStatus';
const QUALITY_FILES = 'QualityFiles';

interface ConformityFormProps {
  userValues: object;
  isEdit: boolean;
  formSchema: object;
  handleSubmit: (fields: object) => void;
  stubbed?: boolean;
}

interface ConformitySectionStatus {
  [CONFORMITY_STATUS]?: string;
  [QUALITY_STATUS]?: string;
}

interface FieldData {
  errors: string[];
  warnings: string[];
  name: NamePath[];
  touched: boolean;
  validating: boolean;
  value: any;
}

const ConformityForm: FC<ConformityFormProps> = ({
  userValues,
  isEdit,
  formSchema,
  handleSubmit,
  stubbed = false,
}) => {
  const [data, setData] = useState<{ [key: string]: any }>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [sectionStatus, setSectionStatus] = useState<ConformitySectionStatus>(
    {}
  );
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    let initialDataValues;

    if (userValues) {
      Object.entries(userValues).forEach(([key, value]) => {
        initialDataValues = {
          ...initialDataValues,
          [key]: value || formSchema[key]?.defaultValue,
        };
      });
    }

    setData(initialDataValues);

    if (stubbed) {
      setSectionStatus({
        [CONFORMITY_STATUS]: formSchema[CONFORMITY_STATUS].statuses?.filter(
          (status) => status.key === userValues[CONFORMITY_STATUS]
        )[0],
        [QUALITY_STATUS]: formSchema[QUALITY_STATUS].statuses?.filter(
          (status) => status.key === userValues[QUALITY_STATUS]
        )[0],
      });
    }
  }, [userValues, formSchema, stubbed]);

  const onFinish = async (_values) => {
    try {
      if (stubbed) {
        setIsSubmitting(true);
        setTimeout(() => {
          store.dispatch(
            closeGenericModalAction({ name: conformityFormModal })
          );
        }, 1500);
        return;
      }

      const allFields = form.getFieldsValue(true);
      const traceId = data.trace_id;

      setIsSubmitting(true);

      const traceData = await client.getTraceById(traceId);

      const parsedData = { ...JSON.parse(traceData.data) };
      const created_at = traceData?.displayDate;

      parsedData['ValidationStatus'] = allFields['ValidationStatus'];
      parsedData['Notes'] = allFields['Comment'];

      handleSubmit({ data: parsedData, traceId, created_at });
    } catch (e) {
      console.log('Error:', e);
    }
  };

  const onFieldsChange = (_changed, all: FieldData[]) => {
    if (stubbed) {
      const allConformityFiles = all.filter((item) =>
        item.name.includes(CONFORMITY_FILES)
      );
      let conformityStatus = getSectionStatus(
        allConformityFiles,
        'value',
        CONFORMITY_STATUS
      );
      form.setFieldValue(CONFORMITY_STATUS, conformityStatus);
      setSectionStatus((prev) => ({
        ...prev,
        [CONFORMITY_STATUS]: formSchema[CONFORMITY_STATUS].statuses?.filter(
          (status) => status.key === conformityStatus
        )[0],
      }));

      const allQualityFiles = all.filter((item) =>
        item.name.includes(QUALITY_FILES)
      );
      let qualityStatus = getSectionStatus(
        allQualityFiles,
        'value',
        QUALITY_STATUS
      );
      form.setFieldValue(QUALITY_STATUS, qualityStatus);
      setSectionStatus((prev) => ({
        ...prev,
        [QUALITY_STATUS]: formSchema[QUALITY_STATUS].statuses?.filter(
          (status) => status.key === qualityStatus
        )[0],
      }));
    }
  };

  const getSectionStatus = (
    files: FieldData[],
    fieldName: string,
    statusType: typeof CONFORMITY_STATUS | typeof QUALITY_STATUS
  ): string => {
    if (!files) return null;

    const allStatuses = files?.map((item) => item[fieldName]);

    if (allStatuses.length === 0) return null;

    if (
      allStatuses?.some((status) => status !== 'Validated') &&
      statusType === CONFORMITY_STATUS
    ) {
      return 'Missing elements';
    }

    if (
      allStatuses?.some((status) => status === 'Refused') &&
      statusType === QUALITY_STATUS
    ) {
      return 'Refused';
    }

    if (
      allStatuses?.some((status) => status === 'Validation pending') &&
      statusType === QUALITY_STATUS
    ) {
      return 'Validation pending';
    }

    return 'Complete & Validated';
  };

  return (
    <SFormWrapper>
      {data ? (
        <Form
          name="edit_statuses"
          initialValues={data}
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
          form={form}
        >
          <Descriptions column={1}>
            {Object.keys(formSchema).map((fieldName, index) => {
              const fieldSchema = formSchema[fieldName];
              const Component = components[fieldSchema?.type];
              const badgeProps = fieldSchema?.badges
                ? fieldSchema?.badges.filter(
                    (badge) => badge.key === data[fieldName]
                  )[0]
                : null;

              const props = {
                value: data[fieldName],
                defaultValue: fieldSchema?.defaultValue,
                src: data[fieldName],
                fieldName,
                formSchema,
                collapseLabel: fieldSchema?.collapseLabel,
                sectionStatus: sectionStatus[fieldName],
                content: fieldSchema?.content,
                isEdit,
                allData: data,
                options: fieldSchema?.options,
                fields: fieldSchema?.fields,
                disabled: fieldSchema?.disabled,
                badgeProps,
                userValues,
                statusOptions:
                  formSchema[fieldSchema?.content]?.fields.status?.options,
              };

              return (
                !fieldSchema.hidden &&
                !fieldSchema.subItem && (
                  <Descriptions.Item
                    key={`${fieldName}-${index}`}
                    label={fieldSchema?.label}
                    className={`${fieldSchema?.type.toLowerCase()}`}
                  >
                    {Component ? <Component {...props} /> : data[fieldName]}
                  </Descriptions.Item>
                )
              );
            })}
          </Descriptions>

          <Form.Item style={{ marginBottom: 0 }}>
            <Flex gap="small" justify="flex-end">
              {isEdit && (
                <Spin size="small" spinning={isSubmitting}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-button"
                  >
                    {t('common.save')}
                  </Button>
                </Spin>
              )}
            </Flex>
          </Form.Item>
        </Form>
      ) : (
        <Spin />
      )}
    </SFormWrapper>
  );
};

export default ConformityForm;
