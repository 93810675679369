import dayjs from 'dayjs';
const dates = { DateHeure: { format: 'DD:MM:YYYY' } };

const prepareForComparacing = (n) =>
  typeof n === 'string' || typeof n === 'number' ? n.toString() : ' ';

const compareItems = (a, b) =>
  a.localeCompare(b, 'en-US-u-kf-upper', {
    ignorePunctuation: true,
    numeric: true,
  });

const sortAs = {
  string: (a, b, data) => {
    if (data === 'company.name') {
      if (a.company.name < b.company.name) return -1;
      if (a.company.name > b.company.name) return 1;
      return 0;
    }
    if (data === 'company.kind') {
      if (a.company.kind < b.company.kind) return -1;
      if (a.company.kind > b.company.kind) return 1;
      return 0;
    }
    if (a[data] || b[data]) {
      return compareItems(
        prepareForComparacing(a[data]),
        prepareForComparacing(b[data])
      );
    }
    return 0;
  },
  date: (a, b, data) => {
    if (data === 'data.date') {
      if (dayjs(a.data.date) < dayjs(b.data.date)) return -1;
      if (dayjs(b.data.date) < dayjs(a.data.date)) return 1;
      return 0;
    }
    if (data === 'last_sign_in_at') {
      const momentA = a[data] ? dayjs(a[data]) : dayjs(0);
      const momentB = b[data] ? dayjs(b[data]) : dayjs(0);
      if (momentA < momentB) return -1;
      if (momentB < momentA) return 1;
      return 0;
    }

    if (dates[data]) {
      const x = +dayjs(a[data], dates[data].format);
      const y = +dayjs(b[data], dates[data].format);

      if (x > y) return -1;
      else if (x < y) return 1;
      else return 0;
    }

    if (dayjs(a[data]) < dayjs(b[data])) return -1;
    if (dayjs(b[data]) < dayjs(a[data])) return 1;
    return 0;
  },
  number: (a, b, data) => a[data] - b[data],
};

export default sortAs;
