import styled from 'styled-components';
import { transition } from 'App/styleVariables';

const SCard = styled.div`
  padding: 35px 61px 35px 49px;
  width: 100%;
  background-color: ${({ theme }) => theme.body} !important;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px !important;
  }

  .title {
    color: ${({ theme }) => theme.cardwrapper.title.color};
    font-size: ${({ theme }) => theme.cardwrapper.title.size};
    font-family: ${({ theme }) => theme.cardwrapper.title.family};
    font-weight: ${({ theme }) => theme.cardwrapper.title.weight};
  }

  .refresh {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.cardwrapper.refreshButton.color};
    font-size: ${({ theme }) => theme.cardwrapper.refreshButton.size};
    font-family: ${({ theme }) => theme.cardwrapper.refreshButton.family};
    font-weight: ${({ theme }) => theme.cardwrapper.refreshButton.weight};
    cursor: pointer;
    min-width: 34px;
    transition: ${transition};

    .refresh-icon {
      color: ${({ theme }) => theme.cardwrapper.refreshButton.color};
      border: none !important;
      box-shadow: rgba(0, 0, 0, 0.15) 0 3px 10px;
      border-radius: 50% !important;
      background-color: ${({ theme }) =>
        theme.cardwrapper.refreshButton.backgroundColorPassive};

      span[role='img'] {
        font-size: 16px;
      }
    }

    &:hover {
      color: ${({ theme }) =>
        theme.cardwrapper.refreshButton.activeColor ||
        theme.buttonToAdd.active};

      .refresh-icon {
        color: ${({ theme }) =>
          theme.cardwrapper.refreshButton.iconColorActive ||
          theme.cardwrapper.refreshButton.activeColor ||
          theme.buttonToAdd.active};
        background-color: ${({ theme }) =>
          theme.cardwrapper.refreshButton.backgroundColorActive};
      }
    }

    .refresh-icon {
      &:hover {
        color: ${({ theme }) =>
          theme.cardwrapper.refreshButton.iconColorActive ||
          theme.cardwrapper.refreshButton.activeColor ||
          theme.buttonToAdd.active};
        background-color: ${({ theme }) =>
          theme.cardwrapper.refreshButton.backgroundColorActive};
      }
    }

    .refresh-text {
      padding-left: 5px;
      line-height: 1;
    }
  }
`;

export { SCard };

export default {};
