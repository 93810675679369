import styled from 'styled-components';
import { transition } from 'App/styleVariables';

export const SSortersWrapper = styled.span`
  display: flex;
  flex-direction: column;

  .sort-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 12px;
      fill: #0000004a;
      transition: ${transition};
    }

    &.active {
      svg {
        fill: ${({ theme }) => theme.table.sorterArrow.activeArrow};
      }
    }
  }
`;
