import { FC } from 'react';
import { QueryObserverResult, useQuery } from 'react-query';
import { Button, message } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import apiClient from 'lib/apiClient';
import { DropdownItemWrapper } from '../../styled-components';

interface DropdownItemProps {
  label: string;
  traceType?: string | undefined;
}

const DropdownItem: FC<DropdownItemProps> = ({ label, traceType }) => {
  const { isFetching, refetch }: QueryObserverResult<Blob, any> = useQuery(
    ['exportTemplates', traceType],
    async ({ queryKey }): Promise<Blob | undefined> => {
      const res = await apiClient.getUploadTemplates({
        type: queryKey[1],
      });

      return res;
    },
    {
      enabled: false, // only run when refetch is called
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (result) => {
        if (result) {
          const url = window.URL.createObjectURL(new Blob([result]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `template_${traceType || 'ALL'}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      },
      onError: (error) => {
        console.log('Error while exporting template: ', error);
        message.error('Something went wrong.');
      },
    }
  );

  return (
    <DropdownItemWrapper size="middle" className="dropdown-item">
      {label}
      <Button onClick={() => refetch()}>
        {isFetching ? <LoadingOutlined /> : <DownloadOutlined />}
      </Button>
    </DropdownItemWrapper>
  );
};

export default DropdownItem;
