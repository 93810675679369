import React, { useEffect, useState } from 'react';
import { STableElement, StyledTooltip, SWarningIcon } from './style';
import { InfoPopover } from 'components/library';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type Props = {
  text: string | boolean;
  warning?: {
    title?: string;
    message: string[];
  };
};

interface CurrentRef {
  scrollWidth: number;
  clientWidth: number;
}

const ColumnTitle = ({ text, warning }: Props) => {
  const [is_tooltip, setTooltip] = useState<boolean>(false);
  const [is_ellipsis, setEllipsis] = useState<boolean>(false);
  const [is_active, setActive] = useState<boolean>(false);

  const ref = React.createRef();

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('keydown', handleKeyDown);

    updateDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDimensions = () => {
    if (!ref || !ref.current) return;

    const { scrollWidth, clientWidth } = ref.current as CurrentRef;

    setEllipsis(scrollWidth > clientWidth);
  };

  const handleKeyDown = (e: any) => {
    const keys = { 123: true };
    if (keys[e.keyCode]) setTimeout(updateDimensions, 400);
  };

  const handleMouseOver = () => {
    const { scrollWidth, clientWidth } = ref.current as CurrentRef;
    setActive(true);

    if (scrollWidth > clientWidth) setTooltip(true);
  };

  const handleMouseLeave = () => {
    setActive(false);
    setTooltip(false);
  };

  let renderText;
  if (typeof text === 'boolean') {
    renderText = text.toString();
  } else {
    renderText = text;
  }

  return (
    <STableElement>
      <div
        style={is_ellipsis ? { textOverflow: 'ellipsis' } : {}}
        ref={ref as React.RefObject<HTMLDivElement>}
        onMouseOver={is_active ? null : handleMouseOver}
        onFocus={is_active ? null : handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {(is_tooltip && is_tooltip && (
          <StyledTooltip
            placement="bottomLeft"
            title={renderText}
            overlayClassName="custom-tooltip"
            mouseEnterDelay={300}
            mouseLeaveDelay={0}
            open={is_tooltip}
          >
            {renderText}
          </StyledTooltip>
        )) ||
          renderText}
      </div>
      {warning && (
        <InfoPopover
          triggerElement={
            <SWarningIcon>
              <ExclamationCircleOutlined />
            </SWarningIcon>
          }
          content={warning.message}
          title={warning.title}
          type={'text_array'}
          closeBtnLabel="OK"
        />
      )}
    </STableElement>
  );
};

export default (props) => <ColumnTitle {...props} />;
