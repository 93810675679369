import { useEffect, useState } from 'react';
import SProgressBar from './styled-components';
import { Tooltip } from 'antd';

interface ProgressBarProps {
  completed: string | number;
  progress?: string | number;
  warning?: string | number;
  balance?: string | number;
  total?: string | number;
  trailColor?: string;
  completedColor?: string;
  progressColor?: string;
  warningColor?: string;
  showInfo?: boolean;
  showBalance?: boolean;
  infoFormat?: (p: string | number) => string;
  strokeHeight?: string;
  labelColor?: string;
  borderRadius?: string;
  showTooltip?: boolean;
  completedTooltip?: string;
  progressTooltip?: string;
  warningTooltip?: string;
  balanceTooltip?: string;
}

const ProgressBar = ({
  completed,
  progress,
  warning,
  balance,
  total = 100,
  trailColor,
  completedColor,
  progressColor,
  warningColor,
  showInfo = false,
  showBalance = false,
  infoFormat = (percent) => `${percent} %`,
  strokeHeight,
  borderRadius,
  labelColor,
  showTooltip = false,
  completedTooltip,
  progressTooltip,
  warningTooltip,
  balanceTooltip,
}: ProgressBarProps) => {
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [warningPercentage, setWarningPercentage] = useState(0);
  const [balancePercentage, setBalancePercentage] = useState(100);

  useEffect(() => {
    const completedPercentage = completed ? (+completed * 100) / +total : 0;
    const progressPercentage = progress ? (+progress * 100) / +total : 0;
    const warningPercentage = warning ? (+warning * 100) / +total : 0;
    const balancePercentage = 100 - +completedPercentage - +progressPercentage - +warningPercentage;

    setCompletedPercentage(completedPercentage);
    setProgressPercentage(progressPercentage);
    setBalancePercentage(balancePercentage);
    setWarningPercentage(warningPercentage)
  }, [total, completed, progress, warning]);

  const renderTooltip = (title, children) => (
    <Tooltip title={title} overlayClassName="white-tooltip">
      {children}
    </Tooltip>
  );

  const completedCategory = (
    <div className={`filler completed ${showTooltip ? 'hoverable' : ''}`}>
      {showInfo && (
        <span className={'label'}>{`${infoFormat(completed)}`}</span>
      )}
    </div>
  );

  const progressCategory = (
    <div className={`filler progress ${showTooltip ? 'hoverable' : ''}`}>
      {showInfo && <span className={'label'}>{`${infoFormat(progress)}`}</span>}
    </div>
  );

  const warningCategory = (
    <div className={`filler warning ${showTooltip ? 'hoverable' : ''}`}>
      {showInfo && <span className={'label'}>{`${infoFormat(warning)}`}</span>}
    </div>
  );

  const balanceCategory = (
    <div className={`filler balance ${showTooltip ? 'hoverable' : ''}`}>
      {showInfo && <span className={'label'}>{`${infoFormat(balance)}`}</span>}
    </div>
  );

  return (
    <SProgressBar
      completed={completedPercentage}
      progress={progressPercentage}
      warning={warningPercentage}
      balance={balancePercentage}
      trailColor={trailColor}
      completedColor={completedColor}
      progressColor={progressColor}
      warningColor={warningColor}
      strokeHeight={strokeHeight}
      labelColor={labelColor}
      borderRadius={borderRadius}
      className="custom-progress-bar"
    >
      {showTooltip
        ? renderTooltip(completedTooltip, completedCategory)
        : completedCategory}
      {!!+progress
        ? showTooltip
          ? renderTooltip(progressTooltip, progressCategory)
          : progressCategory
        : null}
      {!!+warning
        ? showTooltip
          ? renderTooltip(warningTooltip, warningCategory)
          : warningCategory
        : null}
      {showBalance
        ? showTooltip
          ? renderTooltip(balanceTooltip, balanceCategory)
          : balanceCategory
        : null}
    </SProgressBar>
  );
};

export default ProgressBar;
