import styled from 'styled-components';
import { List } from 'antd';
import { borderRadius, colors, font, transition } from 'App/styleVariables';

export const SListItem = styled(List.Item)`
  padding: 10px !important;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  transition: ${transition};

  &:hover {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  }

  &:last-child {
    margin-bottom: 0;
  }

  .ant-list-item-meta {
    align-items: center !important;
  }

  .ant-list-item-meta-avatar {
    margin-right: 10px !important;
    font-size: 16px;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: #eaecf8;
    color: #3643ba !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-item-title {
    margin-bottom: 0;
    margin-right: 20px;
    font-family: ${font};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 270px;
    font-weight: 400;
  }

  .list-view-file {
    font-size: 14px !important;
    font-weight: ${({ theme }) => theme.form.backButton.weight} !important;
    font-family: ${({ theme }) => theme.form.backButton.family} !important;
    color: ${({ theme }) => theme.form.backButton.colorPassive} !important;
    border: ${({ theme }) => theme.form.backButton.borderPassive} !important;

    &:hover {
      color: ${({ theme }) =>
        theme.form.backButton.colorActive || colors.secondary} !important;
      border: ${({ theme }) =>
        theme.form.backButton.borderActive ||
        `1px solid ${colors.secondary}`} !important;
    }
  }

  .ant-list-item-action .ant-form-item {
    margin-bottom: 0;
  }

  .list-select-status {
    font-size: 12px;
    font-family: ${font};
    width: 160px;

    .ant-select-selector {
      border-radius: ${borderRadius};
      color: ${({ theme }) => theme.form.backButton.colorPassive} !important;
      border: ${({ theme }) => theme.form.backButton.borderPassive} !important;
    }

    &.ant-select-focused:not(.ant-select-disabled),
    &:not(.ant-select-disabled):hover,
    &:not(.ant-select-disabled):focus {
      .ant-select-selector {
        color: ${({ theme }) =>
          theme.form.backButton.colorActive || colors.secondary} !important;
        border: ${({ theme }) =>
          theme.form.backButton.borderActive ||
          `1px solid ${colors.secondary}`} !important;
      }
    }
  }
`;
