import styled from 'styled-components';
import { font } from 'App/styleVariables';
import { DotItemType } from './dot-item';
import { getDotItemColor } from './util';

interface ItemStyles {
  color?: string;
  type?: DotItemType;
}

export const SItem = styled.div<ItemStyles>`
  .text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    line-height: 1.2;
    font-family: ${font};
    font-size: 14px;

    &::before {
      content: '';
      display: ${({ color, type }) => (color || type ? 'block' : 'none')};
      width: 16px;
      height: 16px;
      background-color: ${({ color, type }) => color || getDotItemColor(type)};
      margin-right: 8px;
      border-radius: 50%;
    }
  }

  &:last-child {
    .text {
      margin-bottom: 0;
    }
  }
`;
