import type { ColumnsType } from 'antd/es/table';
import { toHHMMSS } from './utils';
import { DeviceData, EventData, PageData } from './type';

const deviceColumns = (t: (string) => string): ColumnsType<DeviceData> => {
  return [
    {
      title: t('common.type'),
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: t('analyticsDashboard.uniqueVisitors'),
      dataIndex: 'nb_visits',
      key: 'nb_visits',
      width: '20%',
    },
  ];
};

const eventColumns = (t: (string) => string): ColumnsType<EventData> => {
  return [
    {
      title: t('analyticsDashboard.action'),
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: t('analyticsDashboard.events'),
      dataIndex: 'nb_events',
      key: 'nb_events',
      width: '12%',
    },
  ];
};

const pageColumns = (t: (string) => string): ColumnsType<PageData> => {
  return [
    {
      title: 'URL',
      dataIndex: 'label',
      key: 'label',
      render: (_, record) => {
        const href = decodeURI(record.segment.substring(9));
        const true_href = decodeURIComponent(href);
        if (record.hasOwnProperty('subtable')) {
          return <span>{record.label} </span>;
        } else {
          return <a href={true_href}> {record.label} </a>;
        }
      },
    },
    {
      title: t('analyticsDashboard.pageViews'),
      dataIndex: 'nb_hits',
      key: 'nb_hits',
      width: '20%',
    },
    {
      title: t('analyticsDashboard.uniquePageViews'),
      dataIndex: 'nb_visits',
      width: '20%',
      key: 'nb_visits',
    },
    {
      title: t('analyticsDashboard.averageTimeOnPage'),
      dataIndex: 'avg_time_on_page',
      width: '20%',
      key: 'avg_time_on_page',
      render: (sec) => toHHMMSS(sec),
    },
  ];
};

export { deviceColumns, eventColumns, pageColumns };
