import AbstractResourceFactory from '../abstract-resource-factory';
import client from '../../../../../../lib/apiClient';
import table_presenter from "../../../../../../lib/presenters/TablePresenter";

import { Table } from '../../../../types';


class GreasyWoolLotResourceFactory implements AbstractResourceFactory {
  async getAll(): Promise<Table> {
    const { data, table_scheme } = await client.getStocks('greasy_wool');
    const presentedData = table_presenter.present_data(data, 'greasy_wool');

    return {
      data: presentedData,
      schema: table_scheme,
      total: presentedData?.length,
    }
  }
}

export default GreasyWoolLotResourceFactory;