import { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Spin, Statistic, Tabs, TabsProps, Typography } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import CommonPlot from '../../../../components/CommonPlot';
import { UnitType, PeriodType, WidgetProps } from '../../utils/type';
import { calculateStubbedData } from '../../utils/calculateStubbedData';
import client from 'lib/apiClient';
import StyledTab from './styled-component';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

function ft_get_dates(date: string | Dayjs, period: PeriodType) {
  const curDate = dayjs(date).startOf(period as PeriodType).format('YYYY-MM-DD');
  let x: [string] = [curDate];

  for (let i = 0; i < 4; i++) {
    x.unshift(dayjs(curDate).subtract(i + 1, period).format('YYYY-MM-DD'));
  }

  return x;
}

const plotStyles = {
  size: {
    column: {
      start: 11,
      end: 1,
    },
    row: {
      start: 1,
      end: 5,
    },
  },
  not_shadow: true,
  minHeight: '505px',
};

const Stat = ({
  title,
  val,
  prevVal,
  color,
}: {
  title: string;
  val: number;
  prevVal: number;
  color: string;
}) => {
  let style;
  let prefix;
  if (prevVal <= val) {
    style = { color: color };
    prefix = <ArrowUpOutlined />;
  }
  return (
    <Statistic title={title} value={val} valueStyle={style} prefix={prefix} />
  );
};

const default_data = (period, color) => {
  const ret = {
    type: 'bar',
    width: 30000000,
    marker: { color: color },
  };

  if (period === 'week') ret['width'] *= 7;
  if (period === 'month') ret['width'] *= 30;
  if (period === 'year') ret['width'] *= 365;
  return ret;
};

const default_layout = (period, x) => {
  const ret = {
    xaxis: {
      type: 'date',
      tickformat: '%d/%m/%Y',
      title: 'Date',
    },
    margin: {
      t: 40,
    },
  };
  if (period === 'day') {
    ret['xaxis']['dtick'] = 86400000;
  }

  if (period === 'week') {
    ret['xaxis']['tickmode'] = 'array';
    ret['xaxis']['tickvals'] = x;
    ret['xaxis']['ticktext'] = x.map((date) => {
      var str = dayjs(date).format('D');
      str += ' - ';
      str += dayjs(date).add(6, 'days').format('D MMM YYYY');
      return str;
    });
  }

  if (period === 'month') {
    ret['xaxis']['tickformat'] = 'array';
    ret['xaxis']['tickvals'] = x;
    ret['xaxis']['ticktext'] = x.map((date) => {
      return dayjs(date).format('MMMM YYYY');
    });
  }

  if (period === 'year') {
    ret['xaxis']['tickformat'] = 'array';
    ret['xaxis']['tickvals'] = x;
    ret['xaxis']['ticktext'] = x.map((date) => {
      return dayjs(date).format('YYYY');
    });
  }
  return ret;
};

export default function SegmentedGraph({
  date,
  period,
  title,
  colors,
  metrics,
  stubbed,
  stubbedData,
  tabPosition = 'top',
}: WidgetProps) {
  const [value, setValue] = useState<string>('nb_visits');
  const [data, setData] = useState({
    nb_visits: [],
    avg_time_spent: [],
    nb_pageviews: [],
    nb_bottom_reached: [],
    penetration_rate: [],
  });
  const [loading, setLoading] = useState(true);
  const [fetch_succes, setFetch_success] = useState(false);
  const { t } = useTranslation();
  const x = ft_get_dates(date, period as PeriodType);

  const items: TabsProps['items'] = metrics?.map((metric) => {
    return {
      key: metric.name,
      label: (
        <Stat
          title={metric.label}
          val={data[metric.name][data[metric.name].length - 1]}
          prevVal={data[metric.name][data[metric.name].length - 2]}
          color={colors.success}
        />
      ),
      children: (
        <CommonPlot
          styles={plotStyles}
          data={{
            data: [
              {
                x: x,
                y: data[metric.name],
                ...default_data(period, colors.info),
              },
            ],
            layout: {
              yaxis: {
                title: metric.yaxis_title,
              },
              ...default_layout(period, x),
            },
            frames: [],
          }}
          responsive={true}
        />
      ),
    };
  });

  async function getData(x: [string]) {
    const y = [];
    const y1 = [];
    const y2 = [];
    const y3 = [];
    const y4 = [];
    const promises = [];
    const promises2 = [];
    const promises3 = [];
    for (let i = 0; i < x.length; i++) {
      promises.push(client.getMatomoData('VisitsSummary.get', x[i], period));
      promises2.push(client.getMatomoData('Actions.get', x[i], period));
      promises3.push(client.getMatomoData('Events.getAction', x[i], period));
    }

    try {
      const res = await Promise.all(promises);
      const res2 = await Promise.all(promises2);
      const res3 = await Promise.all(promises3);

      for (let i = 0; i < x.length; i++) {
        y.push(res[i].nb_visits);
        y1.push(res[i].avg_time_on_site);
        y2.push(res2[i].nb_pageviews);
        y3.push(res3[i].length > 0 ? res3[i][0].nb_visits : 0);
        y4.push((100 - parseInt(res[i].bounce_rate)).toString() + '%');
      }

      setData({
        nb_visits: y,
        avg_time_spent: y1,
        nb_pageviews: y2,
        nb_bottom_reached: y3,
        penetration_rate: y4,
      });
      setFetch_success(true);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (stubbed) {
      setLoading(false);
      setData(calculateStubbedData(stubbedData, x, period as UnitType));
      setFetch_success(true);
    } else {
      getData(x);
    }
    // eslint-disable-next-line
  }, [date, period]);

  return (
    <>
      <Title level={3}>{title?.toUpperCase()}</Title>
      {loading ? (
        <Spin style={{ display: 'block' }} size="large" />
      ) : (
        <>
          {fetch_succes ? (
            <StyledTab>
              <Tabs
                type={'card'}
                activeKey={value}
                items={items}
                onChange={setValue}
                tabPosition={tabPosition}
              />
            </StyledTab>
          ) : (
            <p>
              {t('errors.loadingError')}
            </p>
          )}
        </>
      )}
    </>
  );
}
