import { FC } from 'react';
import { Config } from 'config.js';
import { SIframeWrapper } from './styled-component';
import { grabToken } from 'lib/util';

const { API_URL } = Config;

interface WorkflowBackofficeProps {
  redirect_url?: string;
}

const WorkflowBackoffice: FC<WorkflowBackofficeProps> = ({ redirect_url }) => {
  const redirectUrl = redirect_url ? `&redirect_url=${redirect_url}` : '';
  const iframeSrc = `${API_URL}/api/v2/authenticate-workflow-backoffice?${grabToken()}${redirectUrl}`;

  return (
    <SIframeWrapper>
      <iframe
        title="Workflow Backoffice"
        src={iframeSrc}
        width="100%"
        height="100%"
      />
    </SIframeWrapper>
  );
};

export default WorkflowBackoffice;
