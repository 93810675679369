import { colors } from 'App/styleVariables';
import { DotItemType } from './dot-item';

export const getDotItemColor = (type: DotItemType) => {
  switch (type) {
    case 'success':
      return colors.success;
    case 'in_progress':
      return colors.inProgress;
    case 'interrupted':
      return colors.interrupted;
    case 'critical':
      return colors.critical;
    default:
      break; 
  }
};