import { message } from 'antd';
import { Dispatch } from 'redux';
import client from '../../lib/apiClient';
import { actionGoTo } from '../SideBar/reducer';
import {
  errorAuthAlertManager,
  checkOnBusinessSelection,
} from '../../lib/auth';
import { BUSINESS_PICKER_MODAL } from '../../components/Modal/constants';
import {
  InitialStateInterface,
  SignInValuesInterface,
  VerifyBusinessValuesInterface,
} from './types';

const SIGNIN_REQUEST_SENT = 'AUTH::SIGNIN_REQUEST_SENT';
export const SIGNIN_REQUEST_SUCCEEDDED = 'AUTH::SIGNIN_REQUEST_SUCCEEDDED';
const VERIFY_REQUEST_SENT = 'AUTH::VERIFY_REQUEST_SENT';
const VERIFY_REQUEST_SUCCEEDDED = 'AUTH::VERIFY_REQUEST_SUCCEEDDED';
const SIGNOUT_REQUEST_SENT = 'AUTH::SIGNOUT_REQUEST_SENT';
const SIGNOUT_REQUEST_SUCCEEDDED = 'AUTH::SIGNOUT_REQUEST_SUCCEEDDED';
const INITIAL_STATE = 'AUTH::INITIAL_STATE';
const SWITCH_LANGUAGE_REQUEST_SENT = 'AUTH::SWITCH_LANGUAGE_REQUEST_SENT';
const SWITCH_LANGUAGE_REQUEST_SUCCEEDDED = 'AUTH::SWITCH_LANGUAGE_REQUEST_SUCCEEDDED';

const initial_state: InitialStateInterface = {
  currentUser: {
    isSignedIn: false,
    isLoading: false,
    attributes: {},
  },
};

export const signIn =
  (values: SignInValuesInterface) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: SIGNIN_REQUEST_SENT });

      let response;
      if (!!values.password) {
        response = await client.signInUser(values);
      } else {
        response = await client.getLoginMethod(values);
      }

      switch (true) {
        case response.hasOwnProperty('login_by_sso'):
          return response;
        case response.hasOwnProperty('business_list'):
          sessionStorage.setItem(BUSINESS_PICKER_MODAL, 'open');
          return {
            business_list: response?.business_list,
          };
        case response.data?.force_password_updating:
          return { forceReset: true };
        default:
          return dispatch({
            type: SIGNIN_REQUEST_SUCCEEDDED,
            payload: {
              data: response.data,
            },
          });
      }
    } catch (e) {
      errorAuthAlertManager(e);
      throw e;
    }
  };

export const getBusinessData =
  (isModal: boolean) => async (dispatch: Dispatch) => {
    try {
      const response = await client.getBusinessData();

      if (response.hasOwnProperty('business_list')) {
        !isModal && sessionStorage.setItem(BUSINESS_PICKER_MODAL, 'open');
        return { business_list: response.business_list };
      } else {
        return dispatch({
          type: SIGNIN_REQUEST_SUCCEEDDED,
          payload: {
            data: response,
          },
        });
      }
    } catch (e) {
      console.log('Error: ', e);
      errorAuthAlertManager(e);
      throw e;
    }
  };

export const verifyBusiness =
  (value: VerifyBusinessValuesInterface) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      const { data } = await client.verifyBusiness(value);

      if (data?.force_password_updating) {
        return { forceReset: true };
      } else {
        return dispatch({
          type: SIGNIN_REQUEST_SUCCEEDDED,
          payload: {
            data,
          },
        });
      }
    } catch (e) {
      errorAuthAlertManager(e);
      throw e;
    }
  };

export const verifyTokenAuth =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      checkOnBusinessSelection();

      dispatch({ type: VERIFY_REQUEST_SENT });

      const { data } = await client.verifyToken();

      if (data?.force_password_updating) {
        dispatch(actionGoTo('/reset_password'));
      } else {
        dispatch({
          type: VERIFY_REQUEST_SUCCEEDDED,
          payload: {
            data,
          },
        });
      }
    } catch (e) {
      console.log('err', e);
      if (e?.message) {
        message.error(`${e}`);
      }
      dispatch({ type: INITIAL_STATE });
      sessionStorage.clear();
    }
  };

export const signOut =
  (values) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: SIGNOUT_REQUEST_SENT });

      await client.signOutUser(values);

      dispatch({ type: SIGNOUT_REQUEST_SUCCEEDDED });
    } catch (e) {
      console.log('err', e);
      dispatch({ type: INITIAL_STATE });
      sessionStorage.clear();
    }
  };

export const switchLanguage =
  (lang) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch({ type: SWITCH_LANGUAGE_REQUEST_SENT });

      const response = await client.switchLanguage({ lang });

      dispatch({
        type: SWITCH_LANGUAGE_REQUEST_SUCCEEDDED,
        payload: {
          data: response,
        },
      });
    } catch (e) {
      console.log('err', e);
      if (e?.message) {
        message.error(`${e}`);
      }
    }
  };

const send_signin_request = (state) => ({
  ...state,
  currentUser: {
    isSignedIn: false,
    isLoading: true,
    attributes: {},
  },
});

const send_signout_request = (state) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      isLoading: true,
    },
  };
};

const set_auth = (state, payload) => ({
  ...state,
  currentUser: {
    isSignedIn: true,
    isLoading: false,
    attributes: payload.data,
  },
});

const verify_auth = (state, payload) => ({
  ...state,
  currentUser: {
    isSignedIn: true,
    isLoading: false,
    attributes: payload.data,
  },
});

const set_initial_state = (state) => ({
  ...state,
  currentUser: initial_state.currentUser,
});

const send_switch_language_request = (state) => ({
  ...state,
});

const set_new_language = (state, payload) => ({
  ...state,
  currentUser: {
    isSignedIn: true,
    isLoading: false,
    attributes: payload.data,
  },
});

const handlers = {
  [SIGNIN_REQUEST_SENT]: send_signin_request,
  [VERIFY_REQUEST_SENT]: send_signin_request,
  [SIGNIN_REQUEST_SUCCEEDDED]: set_auth,
  [VERIFY_REQUEST_SUCCEEDDED]: verify_auth,
  [SIGNOUT_REQUEST_SENT]: send_signout_request,
  [SIGNOUT_REQUEST_SUCCEEDDED]: set_initial_state,
  [INITIAL_STATE]: set_initial_state,
  [SWITCH_LANGUAGE_REQUEST_SENT]: send_switch_language_request,
  [SWITCH_LANGUAGE_REQUEST_SUCCEEDDED]: set_new_language,
};

export default (state = initial_state, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action.payload) : state;
};
