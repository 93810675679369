import { message } from 'antd';
import AbstractResourceFactory from './abstract-resource-factory';
import client from '../../../../../lib/apiClient';
import { PageSchema, Table } from '../../../types';
import { getDashboardName } from 'sections/CommonDashboard/util';
import formErrorHandler from '../../formErrorHandler';
import { removeGaps, replaceEmptyStringsWithNullInObject } from 'lib/util';

class DashboardTableResourceFactory implements AbstractResourceFactory {
  defaultFilterState = {
    query: '',
    sorting: '%5B%5D',
    lines_per_page: 10,
    filters: '%7B%7D',
  };
  async getAll(
    page = 1,
    searchField = '',
    filters: object,
    path: string,
    filter_params: any,
    pageSchema: PageSchema,
    traceId,
    business
  ): Promise<Table> {
    const table_name: string = pageSchema?.options?.table_name || '';
    const dashboard_name = getDashboardName(business.name, pageSchema);

    const { data, numberOfElements, table_scheme, form_scheme } =
      await client.getDashboardTableData({
        business: business.name,
        dashboard_name,
        table_name,
        ...filters,
        page_number: page,
        query: searchField,
      });

    return {
      data,
      schema: table_scheme,
      total: numberOfElements,
      formSchema: form_scheme,
    };
  }

  async edit({ data, traceId, created_at, actions, t }: any) {
    data = removeGaps(data);
    data = replaceEmptyStringsWithNullInObject(data);
    const requestData = JSON.stringify(data);

    try {
      await client.putTraces({
        data: requestData,
        traceId,
        created_at,
      });

      actions.setSubmitting(false);

      message.success(t('messages.traceSuccessfullyUpdated', { traceId }));
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }

  async editStatus({ data, traceId, created_at }: any) {
    data = removeGaps(data);
    data = replaceEmptyStringsWithNullInObject(data);
    const requestData = JSON.stringify(data);

    try {
      await client.putTraces({
        data: requestData,
        traceId,
        created_at,
      });
    } catch (e) {
      throw formErrorHandler(e);
    }
  }
}

export default DashboardTableResourceFactory;
