import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  BranchesOutlined,
  CarryOutOutlined,
  CheckOutlined,
  ContainerOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FileOutlined,
  FileSearchOutlined,
  ForkOutlined,
  HddOutlined,
  HomeOutlined,
  MessageOutlined,
  SettingOutlined,
  ShopOutlined,
  SwapOutlined,
  UploadOutlined,
  EyeOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { actionGoTo } from './reducer';
import UniqIdsGenerator from '../../lib/uniqIdsGenerator';
import { StyledMenu, StyledMenuWrapper } from './style';
import { matchPath } from 'react-router-dom';
import withRouter from '../../components/HOC/withRouter';
import DownloadMonitoringReport from '../../components/DownloadMonitoringReport';

const id_generator = new UniqIdsGenerator('sidebar');

const BUTTON_KEY_PART = 'button_';

const ICONS = {
  branches: BranchesOutlined,
  'carry-out': CarryOutOutlined,
  check: CheckOutlined,
  container: ContainerOutlined,
  dashboard: DashboardOutlined,
  Dashboard: DashboardOutlined,
  Dashboards: DashboardOutlined,
  database: DatabaseOutlined,
  Documents: FileOutlined,
  Events: CarryOutOutlined,
  file: FileOutlined,
  fork: ForkOutlined,
  home: HomeOutlined,
  Home: HomeOutlined,
  Librairies: HddOutlined,
  setting: SettingOutlined,
  Settings: SettingOutlined,
  SMS: MessageOutlined,
  Stock: ShopOutlined,
  Storage: HddOutlined,
  swap: SwapOutlined,
  Traces: SwapOutlined,
  Traceability: ForkOutlined,
  Track: FileSearchOutlined,
  upload: UploadOutlined,
  Uploads: UploadOutlined,
  eye: EyeOutlined,
  download: DownloadOutlined,
};

class SideBar extends React.Component {
  state = {
    collapsed: false,
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    // This code is not working
    // window.removeEventListener('resize');
  }

  updateDimensions = () => {
    if (window.innerWidth < 900) {
      this.setState({
        collapsed: true,
      });
    } else {
      this.setState({
        collapsed: false,
      });
    }
  };

  render() {
    const {
      kind,
      isAdmin,
      goTo,
      isSignedIn,
      pagesShema,
      businessName,
      router,
    } = this.props;

    const { collapsed } = this.state;
    const components = { DownloadMonitoringReport };

    const checkForAccess = (pageName) => {
      const {
        accessCompanyKinds,
        excludedCompanyKinds,
        isAdminOnly,
        menuVisibility,
      } = pagesShema[pageName];

      if (!menuVisibility) {
        return false;
      }

      return (
        (accessCompanyKinds.includes(kind) &&
          !excludedCompanyKinds.includes(kind) &&
          !isAdminOnly) ||
        (isAdminOnly && kind !== 'FabricBrand' && isAdmin)
      ); // ugly
    };

    const checkSelectedItem = () => {
      return Object.keys(pagesShema).filter((pageName) => {
        const { path: defaultPath } = pagesShema[pageName];
        const path = defaultPath?.includes('?')
          ? `/${defaultPath.split('/')[1]}`
          : defaultPath;
        const isMatch = path && matchPath(path, router.location.pathname);
        return isMatch?.pathname;
      });
    };

    const getIcons = (iconType) => {
      const IconComponent = ICONS[iconType];

      if (IconComponent) {
        return <IconComponent className="item-icon" />;
      }
      return null;
    };

    const getButton = (name, subMenu = false) => {
      const componentName = pagesShema[name]?.component || name;
      const Component = components[componentName];

      return (
        <Component
          {...pagesShema[name]}
          className={`menu-button ${subMenu ? 'sub-menu-item' : 'menu-item'}`}
        />
      );
    };

    const getButtonKey = (name) => `${BUTTON_KEY_PART}${name}`;

    const menuItems =
      pagesShema &&
      Object.keys(pagesShema)
        .filter(checkForAccess)
        .map((pageName) => {
          const lab = pagesShema[pageName].label
            ? pagesShema[pageName].label.split(' ').slice(0, 1).join('')
            : '';
          const isButton = pagesShema[pageName]?.button;

          return pagesShema[pageName].subItems
            ? {
                label: pagesShema[pageName].label,
                key: pageName,
                icon: getIcons(pagesShema[pageName].icon || lab),
                children: pagesShema[pageName].subItems
                  .filter(checkForAccess)
                  .map((subPageName) => {
                    const isButton = pagesShema[subPageName]?.button;
                    return {
                      label: isButton
                        ? getButton(subPageName, true)
                        : pagesShema[subPageName].label,
                      key: isButton ? getButtonKey(subPageName) : subPageName,
                      id: id_generator.get_id('menuitem', subPageName),
                    };
                  }),
              }
            : !pagesShema[pageName].subMenu &&
                !pagesShema[pageName].hidden && {
                  label: isButton
                    ? getButton(pageName)
                    : pagesShema[pageName].label,
                  key: isButton ? getButtonKey(pageName) : pageName,
                  icon: !isButton && getIcons(pagesShema[pageName].icon || lab),
                  id: id_generator.get_id('menuitem', pageName),
                };
        });

    return (
      isSignedIn && (
        <StyledMenuWrapper
          $inlineCollapsed={collapsed}
          businessname={businessName}
        >
          <StyledMenu
            id={'sidebar-menu'}
            mode="inline"
            onClick={({ key }) =>
              key.includes(BUTTON_KEY_PART)
                ? null
                : goTo(`/${pagesShema[key].path.split('/')[1]}`)
            }
            inlineCollapsed={collapsed}
            selectedKeys={checkSelectedItem()}
            defaultOpenKeys={['sub1']}
            businessname={businessName}
            items={menuItems}
          />
        </StyledMenuWrapper>
      )
    );
  }
}

SideBar.propTypes = {
  kind: PropTypes.string,
  isAdmin: PropTypes.bool,
  goTo: PropTypes.func,
  isSignedIn: PropTypes.bool,
  pagesShema: PropTypes.object,
  businessName: PropTypes.string,
  router: PropTypes.object,
};

const mapStateToProps = ({
  auth: {
    currentUser: {
      attributes: { role, company, pages_scheme, business },
      isSignedIn,
    },
  },
}) => ({
  isAdmin:
    (role === 'Admin' || role === 'SuperAdmin') &&
    company &&
    company.kind === 'Admin',
  kind: company && company.kind,
  isSignedIn,
  pagesShema: pages_scheme,
  businessName: business && business.name,
});

const mapDispatchToProps = {
  goTo: actionGoTo,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBar)
);
