import { FC, SVGProps } from 'react';

interface IconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const IndustryIcon: FC<IconProps> = ({ color = '#000000', ...props }) => {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 24 24"
      id="company-small-24px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="company"
        d="M16,4H2A2.006,2.006,0,0,0,0,6V24H18V6A2.006,2.006,0,0,0,16,4ZM8,22V18h2v4Zm8,0H12V17a.945.945,0,0,0-1-1H7a.945.945,0,0,0-1,1v5H2V6H16ZM8,10H6V8H8Zm4,0H10V8h2ZM8,14H6V12H8Zm4,0H10V12h2Z"
        transform="translate(3 -2)"
        fill={color}
      />
      <rect
        id="rectangle"
        width="24"
        height="24"
        fill="none"
        opacity="0"
      />
    </svg>
  );
};

export default IndustryIcon;
