import AbstractResourceFactory from './abstract-resource-factory';
import client from '../../../../../lib/apiClient';
import { Table } from '../../../types';
import formErrorHandler from '../../formErrorHandler';

class CompanyResourceFactory implements AbstractResourceFactory {
  async getAll(...args: any[]): Promise<Table> {
    const [companies, table_scheme] = await Promise.all([
      client.getCompanies(),
      client.getCompaniesTable(),
    ]);

    return {
      data: companies,
      schema: table_scheme,
      total: companies.length,
    };
  }

  async add({ data, actions }: any) {
    try {
      await client.postCompany(data);
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }

  async edit({ data, actions }: any) {
    try {
      const newData = await client.putCompany(data);
      actions.setSubmitting(false);
      return newData;
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }
}

export default CompanyResourceFactory;
