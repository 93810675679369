import React, {MouseEvent} from 'react';
import { ColumnProps } from 'antd/lib/table';
import { withTranslation } from 'react-i18next';
import GenericTable from './generic-table';
import { TableSchema } from '../types';
import withWindowSize from 'components/HOC/withWindowSize';
import ColumnPropsBuilder from '../helpers/builders/column-props-builder';
import onSearchHelper from '../helpers/onSearch';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

class FrontEndPaginatedTable extends GenericTable {
  constructor(props) {
    super(props);
    
    // Only override handleTableChange if the table is not front-controlled
    if (!this.props.isFrontTableControlled) {
      this.handleTableChange = undefined;
    }
  }

  onSearch = (
    value: string,
    event?:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
      | undefined
  ) => {
    const { dataSource } = this.props;
    this.setState({
      data: onSearchHelper(value, dataSource),
      searchValue: value,
    });
  };

  componentDidUpdate(prevProps: any, prevState: any, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (this.props.dataSource !== prevProps.dataSource) {
      this.setState({
        data: onSearchHelper(this.state.searchValue, this.props.dataSource),
      });
    }
  }

  // This method is called from parent's class method buildColumn
  addFieldsToColumn(
    columnSchema: TableSchema,
    columnProps: ColumnProps<any>
  ): ColumnProps<any> {
    // create new builder with existing column props
    const builder = new ColumnPropsBuilder(columnProps);

    // add for sorting option
    if (columnSchema.sortAs) {
      builder.addSorter(columnSchema.sortAs, columnSchema.fieldName);
    }

    // add fields
    builder.addOnFilter((value, record) => {
      // FRangePicker filter is Range picker used for front-end paginated tables
      if (
        typeof value === 'object' &&
        value.filter === 'FRangePicker' &&
        Array.isArray(value.data)
      ) {
        return (
          dayjs(record[columnSchema.fieldName]).isSameOrAfter(
            value.data[0],
            'day'
          ) &&
          dayjs(record[columnSchema.fieldName]).isSameOrBefore(
            value.data[1],
            'day'
          )
        );
      }

      // default return
      return record[columnSchema.fieldName]?.match(value);
    });

    // build and return column props object
    return builder.build();
  }
}

export default withWindowSize(withTranslation()(FrontEndPaginatedTable));
