import { useMutation } from 'react-query';
import { store } from 'store/nextStore';
import { closeGenericModalAction } from 'containers/GenericModal/reducer';
import { MutationArguments } from '../../types';
import { conformityFormModal } from '../../constants';
import errorHandler from 'lib/errorHandler';

const useEditStatusMutation = ({
  factory,
  client,
  name,
  page,
  searchField,
  filter,
}: MutationArguments) => {
  return useMutation(factory?.editStatus, {
    onSuccess: (_successData) => {
      client.refetchQueries([name, page, searchField, filter]);

      store.dispatch(closeGenericModalAction({ name: conformityFormModal }));
    },
    onError: (error) => {
      if (error) {
        errorHandler(error);
        client.refetchQueries([name, page, searchField, filter]);
        store.dispatch(closeGenericModalAction({ name: conformityFormModal }));
      }
    },
  });
};

export default useEditStatusMutation;
