import styled from 'styled-components';
import { Radio } from 'antd';
import { font, colors, transition } from '../../../../App/styleVariables';

export const SRadioCard = styled(Radio)<any>`
  display: flex;
  align-items: stretch;
  position: relative;
  width: 100%;
  min-height: 85px;
  background: #ffffff;
  border: 2px solid #e2e2e2;
  box-shadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  transition: ${transition};
  font-family: ${font};
  overflow: hidden;
  margin-right: 0;

  &.ant-radio-wrapper-checked,
  &.focused,
  &:focus,
  &:focus-visible {
    border: 2px solid ${colors.secondary};
    box-shadow: 0 0 5px 0.5px rgba(59, 131, 227, 0.35);
  }

  &:not(.ant-radio-wrapper-disabled):hover {
    box-shadow: 0 0 5px 0.5px rgba(59, 131, 227, 0.35);
  }

  .ant-radio {
    position: absolute;
    top: 0;
  }

  p {
    margin: 0;
  }

  & > span:not(.ant-radio) {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 0;
  }

  .business-card-container {
    z-index: 1;
  }

  .business-background {
    height: 140px;
    width: 100%;
    background-image: ${(props: any) => `url(${props.background_image})`};
    background-color: #d9d9d9;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .info-wrapper {
    width: 100%;
    height: calc(100% - 140px);
    min-height: 115px;
    padding: 35px 12px 8px;
    background: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .logo-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -65px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 15px;
    box-sizing: border-box;
    border-radius: 10px;
    background: ${(props: any) => props.logo_bg || '#d9d9d9'};
  }

  .logo {
    width: 100px;
    height: 45px;
    background-image: ${(props: any) => `url(${props.logo})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  .description {
    color: #656565;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
    display: -webkit-box;
    max-width: 335px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .icons-row {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    .anticon {
      padding: 0;
      color: #656565;
    }
  }

  .last-logged-in {
    display: flex;
    align-items: center;

    .anticon {
      padding-right: 4px;
    }

    p {
      color: #717171;
      font-size: 12px;
    }
  }

  &.ant-radio-wrapper-disabled {
    .anticon,
    p {
      color: rgba(0, 0, 0, 0.25);
    }

    .alerts .ant-badge-count {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  .alerts {
    margin-right: 4px;

    .ant-badge-count {
      min-width: 14px;
      width: 14px;
      height: 14px;
      padding: 0;
      font-size: 10px;
      line-height: 14px;
      background-color: ${(props: any) =>
        props.alerts > 0 ? colors.secondary : '#ababab'};
    }
  }
`;
