import AbstractResourceFactory from './abstract-resource-factory';
import client from '../../../../../lib/apiClient';
import table_presenter from '../../../../../lib/presenters/TablePresenter';
import { PageSchema, Table, TableSchema } from '../../../types';

class ChainResourceFactory implements AbstractResourceFactory {
  defaultFilterState = {
    query: '',
    sorting: '%5B%5D',
    lines_per_page: 10,
    filters: '%7B%7D',
  };
  async getAll(
    page = 1,
    searchField = '',
    filters: object,
    path: string,
    filter_params: any,
    pageSchema: PageSchema,
  ): Promise<Table> {
    const api_paginated: boolean = !!pageSchema?.options?.api_pagination;
    const chainTableName: string = pageSchema?.options?.name || '';

    let content: object, numberOfElements: number, table_scheme: TableSchema[];
    if (api_paginated) {
      ({ content, numberOfElements, table_scheme } = await client.getChainsAPI({
        ...filters,
        page_number: page,
        query: searchField,
        name: chainTableName,
      }));
    } else {
      ({ content, numberOfElements, table_scheme } = await client.getChains());
    }

    const data = table_presenter.present_data(content, 'chains');

    return {
      data,
      schema: table_scheme,
      total: numberOfElements,
    };
  }
}

export default ChainResourceFactory;
