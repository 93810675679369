import { FC, useState } from 'react';
import {
  Button,
  Flex,
  Descriptions,
  Divider,
  Form,
  Spin,
  Input,
  Select,
  DatePicker,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { closeGenericModalAction } from 'containers/GenericModal/reducer';
import { SWrapper } from './styled-components';
import { industrialProcessModal } from 'components/Modal/constants';
import { store } from 'store/nextStore';
import LegendItem from 'components/LegendItem';
import dayjs from 'dayjs';

const IndustrialProcess: FC<any> = ({ userValues, isEdit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const {
    Suppliers,
    ProjectedLiveDate,
    ProjectsTrend,
    Comments,
    Rank1Suppliers,
    RankNSuppliers,
  } = userValues;

  const onFinish = (values) => {
    console.log('values', values);
    setIsSubmitting(true);
    setTimeout(() => {
      store.dispatch(closeGenericModalAction({ name: industrialProcessModal }));
    }, 1500);
  };

  const getInitialValues = (values) => {
    return {
      ...values,
      ProjectedLiveDate: {
        label: dayjs(values.ProjectedLiveDate.label, 'DD MMM YYYY'),
      },
    };
  };

  return (
    <SWrapper>
      <Form
        name="industrial_process"
        initialValues={getInitialValues(userValues)}
        onFinish={onFinish}
      >
        {Suppliers?.map((supplier, index) => (
          <>
            {!isEdit ? (
              <Descriptions
                key={`supplier-${index}`}
                column={1}
                title={t('industrialProcessModal.pointOfContact', { supplier: supplier.Supplier })}
                className="description"
              >
                <Descriptions.Item label={t('common.firstName')}>
                  {supplier.first_name}
                </Descriptions.Item>
                <Descriptions.Item label={t('common.lastName')}>
                  {supplier.last_name}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  <a href={`mailto:${supplier.email}}`} className="email-link">
                    {supplier.email}
                  </a>
                </Descriptions.Item>
              </Descriptions>
            ) : (
              <div className="form-section">
                <p className="form-section-header">{t('industrialProcessModal.pointOfContact', { supplier: supplier.Supplier })}</p>
                <Form.Item
                  name={['Suppliers', index, 'first_name']}
                  label={t('common.firstName')}
                  className="form-item-container"
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  name={['Suppliers', index, 'last_name']}
                  label={t('common.lastName')}
                  className="form-item-container"
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item
                  name={['Suppliers', index, 'email']}
                  label="Email"
                  className="form-item-container"
                >
                  <Input allowClear />
                </Form.Item>
              </div>
            )}
          </>
        ))}

        <Divider />

        <Descriptions column={1} className="description">
          <Descriptions.Item label={t('industrialProcessModal.rank1')}>
            {Rank1Suppliers}
          </Descriptions.Item>
          <Descriptions.Item label={t('industrialProcessModal.rankN')}>
            {RankNSuppliers}
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        {!isEdit ? (
          <Descriptions
            column={1}
            title={t('industrialProcessModal.deploymentStatus')}
            className="description"
          >
            <Descriptions.Item label={t('industrialProcessModal.projectedLiveDate')}>
              {ProjectedLiveDate.label}
            </Descriptions.Item>
            <Descriptions.Item label={t('industrialProcessModal.projectTrend')}>
              <LegendItem
                label={ProjectsTrend}
                color={
                  ProjectsTrend === 'On track'
                    ? '#8CFECF'
                    : ProjectsTrend === 'Delay'
                    ? '#FECA8C'
                    : '#FF4D4D'
                }
              />
            </Descriptions.Item>
            {Comments && (
              <Descriptions.Item label={t('industrialProcessModal.comments')}>{Comments}</Descriptions.Item>
            )}
          </Descriptions>
        ) : (
          <div className="form-section">
            <p className="form-section-header">{t('industrialProcessModal.deploymentStatus')}</p>
            <Form.Item
              name={['ProjectedLiveDate', 'label']}
              label={t('industrialProcessModal.projectedLiveDate')}
              className="form-item-container"
            >
              <DatePicker format={'DD MMM YYYY'} />
            </Form.Item>
            <Form.Item
              name="ProjectsTrend"
              label={t('industrialProcessModal.projectTrend')}
              className="form-item-container"
            >
              <Select
                options={[
                  { value: 'On track', label: 'On track' },
                  { value: 'Delay', label: 'Delay' },
                  { value: 'Alert', label: 'Alert' },
                ]}
                optionRender={(option) => (
                  <LegendItem
                    label={option.label}
                    color={
                      option.value === 'On track'
                        ? '#8CFECF'
                        : option.value === 'Delay'
                        ? '#FECA8C'
                        : '#FF4D4D'
                    }
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              name="Comments"
              label={t('industrialProcessModal.comments')}
              className="form-item-container"
            >
              <Input.TextArea allowClear />
            </Form.Item>
          </div>
        )}

        <Form.Item style={{ marginBottom: 0 }}>
          <Flex gap="small" justify="flex-end">
            <Button
              onClick={() =>
                store.dispatch(
                  closeGenericModalAction({ name: industrialProcessModal })
                )
              }
              className="cancel-button"
            >
              {t('common.cancel')}
            </Button>
            {isEdit && (
              <Spin size="small" spinning={isSubmitting}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                >
                  {t('common.save')}
                </Button>
              </Spin>
            )}
          </Flex>
        </Form.Item>
      </Form>
    </SWrapper>
  );
};

export default IndustrialProcess;
