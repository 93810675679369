import { createGlobalStyle } from 'styled-components';
import { borderRadius, colors, font, inputShadow, transition } from './styleVariables';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    box-sizing: border-box !important;
  }

  .App {
    text-align: center;
  }
  
  .App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
  }

  .App-title {
    font-size: 1.5em;
  }

  .App-intro {
    font-size: large;
  }

  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
  }
  
  // SCROLL
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #7a7a7a;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #f9f9f9;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888 !important;
    border-radius: 6px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .custom-tooltip {
    .ant-tooltip-inner {
      font-size: 12px;
      font-weight: 500;
      font-family: ${font};
      color: ${colors.dark};
      background-color: #ffffff;
    }

    .ant-tooltip-arrow {
      &:before,
      &:after {
        background-color: #ffffff;
      }
    }
    .ant-tooltip-placement-bottom .ant-tooltip-arrow,
    .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
    .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
      border-bottom-color: #889297;
    }
  }

  .white-tooltip {
    .ant-tooltip-inner {
      font-family: ${font};
      color: #000000;
      background-color: #ffffff;
    }

    .ant-tooltip-arrow {
      &:before,
      &:after {
        background-color: #ffffff;
      }
    }

    .ant-tooltip-placement-bottom .ant-tooltip-arrow,
    .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
    .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
      border-bottom-color: #889297;
    }
  }

  #div_header_ChainDetailsTable {
    display: none;
  }

  .ant-page-header {
    padding: 15px 0;
    color: #1b1b1b;
    font-family: ${font};
    font-size: 12px;
    line-height: 24px;
  }

  .ant-calendar-range-right input {
    padding-left: 93px !important;
  }

  .ant-calendar-range-middle {
    display: none !important;
    padding: 0 !important;
  }

  .page-wrapper {
    background: rgb(237, 236, 236);
    height: 100%;
  }

  .ant-input,
  .ant-select-dropdown,
  .ant-btn,
  .ant-picker-dropdown,
  .ant-picker,
  .ant-dropdown-menu {
    font-family: ${font};
  }

  .ant-input:not(.ant-input-disabled) {
    &:hover {
      border-color: ${colors.secondary} !important;
    }

    &:focus {
      border-color: ${colors.secondary} !important;
      box-shadow: ${inputShadow} !important;
    }
  }

  .ant-input-affix-wrapper {
    &:not(.ant-input-affix-wrapper-disabled) {
      &:hover,
      &:focus {
        border-color: ${colors.secondary} !important;
      }

      &:focus {
        border-color: ${colors.secondary} !important;
        box-shadow: ${inputShadow} !important;
      }
    }
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: ${inputShadow} !important;
  }

  .ant-select:not(.ant-select-disabled) {
    &:hover {
      .ant-select-selector {
        border-color: ${colors.secondary} !important;
      }
    }
    
    &:focus,
    &.ant-select-focused {
      .ant-select-selector {
        border: 1px solid ${colors.secondary} !important;
        box-shadow: ${inputShadow} !important;
      }
    }
  }

  .ant-radio-wrapper:not(.ant-radio-wrapper-checked) {
    &:hover {
      .ant-radio-inner {
        border-color: ${colors.secondary};
      }
    }
  }

  .ant-checkbox:not(.ant-checkbox-disabled) {
    &:hover{
      .ant-checkbox-inner {
        border-color: ${colors.secondary} !important;
      }
    }
    
    &.ant-checkbox-checked{
      &:hover {
        .ant-checkbox-inner {
          background-color: ${colors.secondary};
        }
      }
    }
  }

  .ant-picker {
    &:hover,
    &:focus {
      border-color: ${colors.secondary} !important;
    }
    
    &.ant-picker-focused {
      border-color: ${colors.secondary} !important;
      box-shadow: ${inputShadow} !important;
    }
  }

  .ant-btn-default:not(.ant-btn-disabled) {
    &:hover:not([disabled]) {
      border-color: ${colors.secondary};
      color: ${colors.secondary};
    }
  }

  .ant-tooltip {
    font-family: ${font};
  }

  .ant-alert {
    font-family: ${font};
  }

  .ant-typography {
    font-family: ${font};
  }

  .ant-select-dropdown-menu-item {
    white-space: normal !important;
    word-break: normal !important;
  }
  
  .ant-form-item-explain-error {
    font-family: ${font};
  }

  .ant-form-item-label {
    font-family: ${font};
  }

  .homepage-wrapper {
    height: 100%;
  }

  .homepage-wrapper html {
    height: 100% !important;
  }

  .main-wrapper {
    min-height: calc(100vh - 83px) !important;
    height: 100%;
  }

  .Side-bar-wrapper {
    display: flex;
    justify-content: space-between;
    min-height: calc(100vh - 83px);
  }

  .Side-bar-panel-wrapper {
    display: flex;
    bottom: 0;
    position: relative;
    left: 0;
  }

  .Side-bar-items-wrapper {
    width: 100%;
    margin-top: 10px;
    margin-right: 10px;
  }

  .ant-upload-list-item-name {
    max-width: 280px;
  }

  .ant-modal {
    height: auto;
  }

  .content-wraper {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .ant-modal-wrap .ant-modal-content {
    border-radius: 6px;
  }

  .ant-modal-content .ant-modal-header {
    padding-left: 0;
    border-radius: 4px 4px 0 0;
  }

  .ant-input-affix-wrapper,
  .ant-input,
  .ant-picker,
  .ant-select .ant-select-selector {
    border-radius: 8px !important;
  }
  .ant-btn:not(.rounded):not(.inside) {
    border-radius: ${borderRadius} !important;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius:  0 !important;
  }

  .ant-btn:not(.ant-btn-icon-only) {
    padding: 0 15px;
  }

  .submenu-collapsed-popup .ant-menu{
    background-color: rgb(237, 233, 228) !important;
  }

  #indicatorTooltip {
    color: #000000;
    padding: 5px 10px;
    text-align: center;
  }

  [class*=" ant-notification"] {
    font-family: ${font};
  }

  .info-badge {
    cursor: pointer;
  }
  
  .platform-button {
    &.primary {
      background-color: ${colors.primary};

      &:hover {
        background-color: ${colors.secondary};
      }
    }
  }

  .ant-popover {
    .ant-popover-inner {
      padding: 0;
      border-radius: ${borderRadius};
    }
    .ant-popover-title {
      margin: 0;
    }
  }

  .ant-spin .ant-spin-dot-item{
    background-color: ${colors.primary};
  }

  .ant-dropdown {
    .ant-dropdown-menu {
      border-radius: ${borderRadius};
    }
  }

  .header-user-dropdown {
    min-width: 160px !important;
    width: auto !important;
  }

  .ant-empty {
    font-family: ${font};
  }
  
  .header-language-selector-popup {
    .ant-select-item {
      font-weight: 600;
      color: ${({ theme }) =>
        theme.header.languageSelector?.options?.passiveColor || colors.gray};
      transition: ${transition};

      &.ant-select-item-option-active {
        background-color: transparent;
      }

      &:hover {
        color: ${({ theme }) =>
          theme.header.languageSelector?.options?.activeColor ||
          colors.primary};
        background-color: transparent;
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: ${({ theme }) =>
        theme.header.languageSelector?.options?.activeColor || colors.primary};
      background-color: ${({ theme }) =>
        theme.header.languageSelector?.options?.activeBackgroundColor ||
        colors.secondary10};
    }
  }
`;

export default GlobalStyle;
