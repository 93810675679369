import React from 'react';
import { connect } from 'react-redux';
import { closeModalAction } from './reducer';

import ChainDetails from 'containers/ChainDetails';
import CustomModal from 'components/Modal';
import { withTranslation } from 'react-i18next';

class TrackProductModal extends React.Component {
  handleOnClose = () => {
    const { closeModal } = this.props;

    closeModal();
  }

  render() {
    const { isModalOpen, traceId, t } = this.props;

    return (
      <CustomModal
        width={window.innerWidth > 1300 ? 1250 : '90%'}
        title={`${t('chains.chainDetails')}: ${traceId}`}
        closeModal={this.handleOnClose}
        modalVisible={isModalOpen}
  
      >
        {isModalOpen ?
          <ChainDetails {...this.props} />
          : null}
      </CustomModal>
    );
  }
}

const ConnectedModal = connect(
  ({ trackProductModal }) => ({
    isModalOpen: trackProductModal.isModalOpen,
    mode: trackProductModal.mode,
    traceId: trackProductModal.traceId,
    traceType: trackProductModal.traceType
  }),
  {
    closeModal: closeModalAction,
  },
)(TrackProductModal);

export default withTranslation()(ConnectedModal);
