import { FC } from 'react';
import { Select as ASelect, Form } from 'antd';
import Badge from 'components/Badge';

interface SelectProps {
  fieldName: string;
  value: string | null;
  isEdit: boolean;
  options: {
    value: string;
    text: string;
  }[];
  disabled?: boolean;
}

const Select: FC<SelectProps> = ({ fieldName, value, isEdit, options, disabled }) => {
  const getStatusProps = (status) =>
    options?.filter((option) => option.value === status)[0];
  const statusProps = getStatusProps(value);

  if (isEdit && !disabled) {
    return (
      <Form.Item name={fieldName} className="conformity-form-item">
        <ASelect
          options={options?.map((option) => ({
            label: option.text,
            value: option.value,
          }))}
          className="conformity-form-select"
        />
      </Form.Item>
    );
  }

  return value ? <Badge title={value} {...statusProps} /> : null;
};

export default Select;
