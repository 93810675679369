import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import TraceList from './components/TraceList';
import client from 'lib/apiClient';
import trace_presenter from 'lib/presenters/TracePresenter';
import errorHandler from 'lib/errorHandler';
import { sortObjectByOrder } from 'lib/util';

const orderFields = (data, scheme) => {
  const dataObject = _.cloneDeep(data);
  const sortedScheme = sortObjectByOrder(scheme);
  const order = Object.keys(sortedScheme);

  const reorderedObj = {};
  order.forEach(key => {
    if (dataObject.hasOwnProperty(key)) {
      if (dataObject[key] !== null && Array.isArray(dataObject[key])) {
        reorderedObj[key] = dataObject[key].map((obj) => {
          const orderedObj = {};
          for (const field in sortedScheme[key].fields) {
            if (obj.hasOwnProperty(field)) {
              orderedObj[field] = obj[field];
            }
          }
          return orderedObj;
        });
      } else {
        reorderedObj[key] = dataObject[key];
      }
    }
  });
  return reorderedObj;
};

class ShowTraceList extends React.PureComponent {
  state = { is_all: false, no_empty: true };

  async componentDidMount() {
    const { data, fetchTrace, traceId, scheme, ordered } = this.props;
    let dynamicTraceData;

    if (fetchTrace) {
      try {
        const r = await client.getTraceById(traceId);
        dynamicTraceData = {
          ...JSON.parse(r.data),
          traceId: r.traceId,
          updated_at: r.displayDate,
        };
      } catch (e) {
        console.log(e);
        errorHandler(e);
      }
    }
    
    const orderedData = ordered && orderFields(data, scheme)

    this.setState(
      trace_presenter.present_data(ordered ? orderedData : data || dynamicTraceData, 'show_list')
    );
  }

  handleToggle = () => this.setState({ is_all: !this.state.is_all });

  render() {
    const { modalWidth, business } = this.props;
    const { all, filtered, no_empty, is_all } = this.state;

    return filtered || all ? (
      <TraceList
        data={(is_all && all) || filtered}
        modalWidth={modalWidth}
        no_empty={no_empty}
        is_all={is_all}
        handleToggle={this.handleToggle}
        business={business}
      />
    ) : (
      <Spin size="large" style={{ display: 'block' }} />
    );
  }
}

ShowTraceList.propTypes = {
  /**
   * Trace data : all trace information that you want to see on the screen
   */
  data: PropTypes.object,
  /**
   * Property that sets the dimensions for the label field and the value field in the row
   */
  modalWidth: PropTypes.any,
  /**
   * Property that specifies whether to fetch trace data when the component is mounted
   */
  fetchTrace: PropTypes.bool,
  /**
   * Trace id
   */
  traceId: PropTypes.string,
};

const mapStateToProps = ({ auth }) => ({
  business: auth.currentUser.attributes.business,
});

const ConnectedShowTraceList = connect(mapStateToProps)(ShowTraceList);

export default ConnectedShowTraceList;
