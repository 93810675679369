import styled from 'styled-components';
import { colors, transition } from 'App/styleVariables';

interface ProgressBarStyles {
  completed: string | number;
  progress?: string | number;
  warning?: string | number;
  balance?: number;
  trailColor?: string;
  completedColor?: string;
  progressColor?: string;
  warningColor?: string;
  strokeHeight?: string;
  labelColor?: string;
  borderRadius?: string;
}

const SProgressBar = styled.div<ProgressBarStyles>`
  height: ${({ strokeHeight }) => strokeHeight || '15px'};
  width: 100%;
  background-color: ${({ trailColor }) => trailColor || colors.lightGray};
  border-radius: ${({ borderRadius }) => borderRadius || '50px'};
  display: flex;

  .filler {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ${transition};

    &.hoverable:hover {
      box-shadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.25) !important;
      z-index: 2;
    }

    &.completed {
      width: ${({ completed }) => `${completed}%`};
      background-color: ${({ completedColor }) => completedColor || colors.primary};
      border-top-left-radius: ${({ borderRadius }) => borderRadius || '50px'};
      border-bottom-left-radius: ${({ borderRadius }) => borderRadius || '50px'};
      border-top-right-radius: ${({ completed, borderRadius }) =>
        completed === 100 ? borderRadius || '50px' : '0px'};
      border-bottom-right-radius: ${({ completed, borderRadius }) =>
        completed === 100 ? borderRadius || '50px' : '0px'};
    }

    &.progress {
      width: ${({ progress }) => `${progress}%`};
      background-color: ${({ progressColor }) =>
        progressColor || colors.secondary};
      border-top-left-radius: ${({ borderRadius, completed }) =>
        +completed > 0 ? '0px' : borderRadius || '50px'};
      border-bottom-left-radius: ${({ borderRadius, completed }) =>
        +completed > 0 ? '0px' : borderRadius || '50px'};
      border-top-right-radius: ${({ borderRadius, balance, warning }) =>
        +warning > 0 || +balance > 0 ? '0px' : borderRadius || '50px'};
      border-bottom-right-radius: ${({ borderRadius, balance, warning }) =>
        +warning > 0 || +balance > 0 ? '0px' : borderRadius || '50px'};
    }

    &.warning {
      width: ${({ warning }) => `${warning}%`};
      background-color: ${({ warningColor }) =>
        warningColor || colors.interrupted};
      border-top-left-radius: ${({ borderRadius, completed, progress }) =>
        +completed > 0 || +progress > 0 ? '0px' : borderRadius || '50px'};
      border-bottom-left-radius: ${({ borderRadius, completed, progress }) =>
        +completed > 0 || +progress > 0 ? '0px' : borderRadius || '50px'};
      border-top-right-radius: ${({ borderRadius, balance }) =>
        +balance > 0 ? '0px' : borderRadius || '50px'};
      border-bottom-right-radius: ${({ borderRadius, balance }) =>
        +balance > 0 ? '0px' : borderRadius || '50px'};
    }

    &.balance {
      width: ${({ balance }) => `${balance}%`};
      background-color: ${({ trailColor }) => trailColor || colors.lightGray};
      border-top-right-radius: ${({ borderRadius }) => borderRadius || '50px'};
      border-bottom-right-radius: ${({ borderRadius }) => borderRadius || '50px'};
      border-top-left-radius: ${({ balance, borderRadius }) =>
        balance === 100 ? borderRadius || '50px' : '0px'};
      border-bottom-left-radius: ${({ balance, borderRadius }) =>
        balance === 100 ? borderRadius || '50px' : '0px'};
    }
  }

  .label {
    color: ${(props) => props.labelColor || 'white'};
    font-size: 10px;
    line-height: 1;
    white-space: nowrap;
    font-weight: 500;
  }
`;

export default SProgressBar;
