import React from 'react';
import PropTypes from 'prop-types';
import { Button, Select } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import fileExtension from 'file-extension';
import { withTranslation } from 'react-i18next';
import withRouter from 'components/HOC/withRouter';
import { DocumentsItemForm } from 'sections/Documents/containers/DocumentListForm/documents-item-form';
import { get_documents } from 'sections/Documents/containers/DocumentListTable/reducer';
import SearchData from 'containers/SearchData';
import { onSearchAction } from 'containers/SearchData/reducer';
import Views from '../../components';
import withGenericModal from 'containers/GenericModal/component';
import {
  closeGenericModalAction,
  openGenericModalAction,
} from 'containers/GenericModal/reducer';
import { handleSubmitAction } from 'sections/Documents/containers/DocumentListForm/reducer';
import { HeaderActions } from 'components/DataTable';
import { addFileGalleryModal } from 'sections/GenericTablePage/constants';
import { GridSize, Miniatures, SidebarWrapper, FilesViewWrapper } from './styles';
import { SearchWrapper } from 'containers/SearchData/style';
import { Header } from 'components/DataTable/style';
import './styles.css';

import paper from 'containers/Gallery/resource/paper.png';

const { Option } = Select;

const MODAL_WINDOW_GALLERY = 'GALLERY';
export const ADD_FILE_MODAL_WINDOW = 'ADD_FILE_MODAL_WINDOW';

const extensionFiltersValues = {
  images: ['png', 'jpg', 'jpeg'],
  docs: ['pdf', 'doc', 'csv'],
};

class Gallery extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedItem: null,
      size: 'medium',
      extensionFilter: [
        ...extensionFiltersValues['images'],
        ...extensionFiltersValues['docs'],
      ],
      companyFilter: [],
    };
  }

  componentDidMount() {
    const { get_items, data } = this.props;
    get_items();
    this.setState({
      data,
      companiesList: [...new Set(data?.map((item) => item.company))],
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedItem, extensionFilter, companyFilter } = this.state;
    const { item, data } = this.props;

    if (
      !selectedItem &&
      Array.isArray(data) &&
      data.length !== 0 &&
      data !== prevProps.data &&
      item
    ) {
      this.setState({
        selectedItem: data.find((i) => item === i.file_name),
      });
    }

    if (
      extensionFilter !== prevState.extensionFilter ||
      companyFilter !== prevState.companyFilter ||
      data !== prevProps.data
    ) {
      let filteredData = data;

      if (Array.isArray(extensionFilter)) {
        filteredData = this.filterByType(filteredData);
      }

      if (Array.isArray(companyFilter) && companyFilter.length !== 0) {
        filteredData = this.filterByCompany(filteredData);
      }

      this.setState({
        data: filteredData,
      });
    }

    if (data !== prevProps.data) {
      this.setState({
        companiesList: [...new Set(data?.map((item) => item.company))],
      });
      if (selectedItem && Array.isArray(data)) {
        this.setState({
          selectedItem: data.find(
            (i) => selectedItem.file_name === i.file_name
          ),
        });
      }
    }
  }

  componentWillUnmount() {
    const { onSearch } = this.props;
    onSearch('');
  }

  filterByType = (filteringData) => {
    const filteredData = filteringData?.filter((file) => {
      const extension = fileExtension(file.file_name);
      return !!this.state.extensionFilter.includes(extension);
    });

    return filteredData;
  };

  filterByCompany = (filteringData) => {
    const filteredData = filteringData?.filter((file) =>
      this.state.companyFilter.includes(file.company)
    );
    return filteredData;
  };

  render() {
    const {
      onSelect,
      onSearch,
      canEditFile,
      openGenericModal,
      closeGenericModal,
      handleSubmitFile,
      get_items,
      additionalMenu,
      modalName,
      headerActionsSchema,
      t,
    } = this.props;

    const { selectedItem, size, data, companiesList } = this.state;

    const GalleryModalWindow = withGenericModal(
      DocumentsItemForm,
      MODAL_WINDOW_GALLERY,
      {
        handleSubmit: (values) => {
          handleSubmitFile({
            ...values,
            onSuccess: () => {
              get_items();
              closeGenericModal({ name: MODAL_WINDOW_GALLERY });
            },
          });
        },
      }
    );

    const view = (
      <div className={`gallery-files-wrapper`}>
        <FilesViewWrapper>
          <Miniatures>
            {Array.isArray(data) &&
              data.map((item) => (
                <Views.FileViewMiniature
                  key={item?.id || item?.file_name}
                  item={item}
                  onClick={() => this.setState({ selectedItem: item })}
                  isSelected={
                    selectedItem
                      ? item.file_name === selectedItem.file_name
                      : false
                  }
                  ondblclick={
                    onSelect
                      ? () => onSelect(item)
                      : () => window.open(item.file_url_href, '_blank')
                  }
                  size={size}
                />
              ))}
          </Miniatures>
        </FilesViewWrapper>
        <div className={'gallery-sidebar'} style={{ width: '30%' }}>
          {selectedItem && (
            <SidebarWrapper>
              <Views.FileTechnicalInfo item={selectedItem} />
              <div className={`gallery-sidebar-button`}>
                {onSelect && (
                  <div className={`gallery-sidebar-buttons`}>
                    <Button
                      type="primary"
                      onClick={() => {
                        closeGenericModal({ name: modalName });
                      }}
                    >
                      {t('common.cancel')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => onSelect(selectedItem)}
                    >
                      {t('common.select')}
                    </Button>
                  </div>
                )}
                {canEditFile && (
                  <Button
                    type="primary"
                    onClick={() => {
                      openGenericModal({
                        name: MODAL_WINDOW_GALLERY,
                        configuration: { title: t('form.editInfo'), width: '800px' },
                        data: {
                          userValues: data.find(
                            (item) => selectedItem.file_name === item.file_name
                          ),
                          type: 'document',
                          isEdit: true,
                          formStep: 'formFilling',
                          formType: '',
                          styles: {},
                          isLoading: false,
                          title: selectedItem.file_name,
                        },
                      });
                    }}
                  >
                    {t('form.editInfo')}
                  </Button>
                )}
              </div>
            </SidebarWrapper>
          )}
          {!selectedItem && (
            <div className={`gallery-sidebar-preview`}>
              <img
                src={paper}
                alt="File preview"
                className={`gallery-sidebar-preview-img`}
              />
            </div>
          )}
        </div>
      </div>
    );

    return (
      <div>
        <Header>
          <SearchWrapper className={`header-media`}>
            <SearchData
              // id={id_generator.get_id(prefix_id, 'search-input')}
              placeholder={t('common.search')}
              onSearch={(e) => {
                this.setState({ selectedItem: null });
                onSearch(e);
              }}
            />
            <GridSize>
              <span className={'filter-label'}>{t('common.type')} :</span>
              <Select
                mode="multiple"
                style={{ width: '175px' }}
                defaultValue={['images', 'docs']}
                onChange={(filters) => {
                  this.setState({
                    extensionFilter: filters
                      .map((f) => extensionFiltersValues[f])
                      .flat(),
                  });
                }}
                maxTagCount={2}
              >
                <Option value={'images'}>{t('media.images')}</Option>
                <Option value={'docs'}>{t('media.docs')}</Option>
              </Select>
            </GridSize>
            <GridSize>
              <span className={'filter-label'}>{t('common.company')} :</span>
              <Select
                mode="multiple"
                maxTagCount={2}
                onChange={(filters) => {
                  this.setState({
                    companyFilter: filters,
                  });
                }}
                maxTagTextLength={7}
                className={`company-filter`}
              >
                {companiesList?.map((company) => {
                  return (
                    <Option key={company} value={company}>
                      {company || t('common.noName')}
                    </Option>
                  );
                })}
              </Select>
            </GridSize>
            <GridSize>
              <span className={'filter-label'}>{t('media.size')} :</span>
              <Select
                defaultValue={size}
                style={{ width: '85px' }}
                onChange={(size) => this.setState({ size })}
                className={`size-filter`}
              >
                <Option value="small">{t('media.small')}</Option>
                <Option value="medium">{t('media.medium')}</Option>
                <Option value="large">{t('media.large')}</Option>
              </Select>
            </GridSize>
            {additionalMenu}
          </SearchWrapper>
          <HeaderActions
            actions={headerActionsSchema || []}
            prefix_id={'prefix'}
            handleClick={() =>
              openGenericModal({
                name: addFileGalleryModal,
                configuration: { title: t('form.addFile'), width: '800px' },
                data: {
                  type: 'document',
                  isEdit: false,
                  formStep: 'formFilling',
                  formType: '',
                  styles: {},
                  isLoading: false,
                  handleSubmit: (values) => {
                    handleSubmitFile({
                      ...values,
                      onSuccess: () => {
                        get_items();
                        closeGenericModal({
                          name: addFileGalleryModal,
                        });
                      },
                    });
                  },
                },
              })
            }
            isAllowed
          />
        </Header>
        {view}
        <GalleryModalWindow />
      </div>
    );
  }
}

Gallery.propTypes = {
  get_items: PropTypes.func,
  data: PropTypes.array,
  item: PropTypes.string,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  canEditFile: PropTypes.bool,
  openGenericModal: PropTypes.func,
  closeGenericModal: PropTypes.func,
  handleSubmitFile: PropTypes.func,
  additionalMenu: PropTypes.element,
  parent: PropTypes.string,
  modalName: PropTypes.string,
  headerActionsSchema: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

const mapStateToProps = ({ documentsTable, documentsModal, searchData }) => ({
  data:
    searchData.isSearchActive && searchData.table === 'DocumentsTable'
      ? searchData.searchResult
      : documentsTable.data,
  loading: documentsTable.loading,
  isModalOpen: documentsModal.isModalOpen,
  isEdit: documentsModal.isEdit,
});

const mapDispatchToProps = {
  get_items: get_documents,
  openGenericModal: openGenericModalAction,
  closeGenericModal: closeGenericModalAction,
  handleSubmitFile: handleSubmitAction,
  onSearch: (q) =>
    onSearchAction(
      q,
      (globalState) => globalState.documentsTable.data,
      'DocumentsTable'
    ),
};

const composeResult = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(withTranslation()(Gallery));

export default composeResult;
