import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { CardStyles } from '../../types';
import { font } from '../../../../App/styleVariables';

interface TableStyles extends CardStyles {
  displayTitle?: boolean;
}

export const SIndicatorContainer = styled(SIndicator)<TableStyles>`
  .ant-card-body {
    position: relative;

    .indicator-title {
      position: absolute;
    }
  }

  .table-header {
    box-shadow: none;
    margin-bottom: ${(props) => (props?.displayTitle ? '20px' : '0px')};
    height: auto;

    & > div {
      background: transparent;
      padding: 0 !important;
      height: auto !important;
      display: flex;
      justify-content: flex-end;

      .ant-input-search {
        width: 30% !important;
        min-width: 150px;

        .ant-input-wrapper {
          background: #f5f5f5 !important;
          padding-right: 0 !important;
          display: flex;
          border-radius: 49px;

          .ant-input {
            border: 0;
            max-width: unset !important;
            width: calc(100% - 32px);
            background: transparent;

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              font-weight: 400 !important;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              font-weight: 400 !important;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              font-weight: 400 !important;
            }

            &:focus {
              box-shadow: none;
            }
          }

          .ant-input-search-button {
            width: 32px !important;
            background: transparent !important;
            border: 0;
            color: #808080 !important;
            border-radius: 49px !important;

            & > span {
              transform: scale(1);

              .ant-btn:not(:disabled):focus-visible {
              }
            }
          }
        }
      }
    }
  }

  .table-wrapper {
    box-shadow: none;
  }

  .table-container {
    margin-top: ${(props) => props?.styles?.table?.marginTop};
    min-height: ${(props) => props?.styles?.minHeight || '270px'};

    .ant-table-content {
      box-shadow: none;
      padding: 0 !important;
      border-radius: 0 !important;

      table {
        border-radius: 0 !important;
      }
    }

    .ant-table-thead > tr > th {
      padding: 8px !important;
      font-size: ${(props) =>
        props?.styles?.table?.header?.fontSize || '14px'} !important;
      font-weight: 500 !important;
      font-family: ${({ theme }) =>
        `${theme?.dashboard?.fontFamily}, sans-serif` || font} !important;
      color: #2b3674 !important;
      border-bottom: 1px solid #eaecf0 !important;
      background-color: #e8f4ff !important;

      .anticon-filter {
        margin-top: 2px;
      }

      .ant-table-column-sorter {
        width: 17px !important;
        right: ${(props) =>
          props?.styles?.table?.header?.sorterRight} !important;
      }

      .ant-table-column-sorters {
        padding-right: ${(props) => props?.styles?.table?.header?.paddingRight};
      }
    }

    .ant-table-tbody > tr > td {
      padding: 5px 8px !important;
      font-size: ${(props) =>
        props?.styles?.table?.content?.fontSize || '13px'} !important;
      font-weight: 400 !important;
      font-family: ${({ theme }) =>
        `${theme?.dashboard?.fontFamily}, sans-serif` || font} !important;
      color: #808080 !important;
      text-align: ${(props) =>
        props?.styles?.table?.content?.textAlign} !important;

      &:first-child {
        text-align: ${(props) =>
          props?.styles?.table?.firstRow?.textAlign} !important;
      }
    }

    .ant-table-tbody > tr.table-last-row > td {
      font-size: ${(props) =>
        props?.styles?.table?.lastRow?.fontSize} !important;
      font-weight: ${(props) =>
        props?.styles?.table?.lastRow?.fontWeight} !important;
      color: ${(props) => props?.styles?.table?.lastRow?.color} !important;
      border-top: ${(props) => props?.styles?.table?.lastRow?.borderTop};
    }

    .ant-pagination {
      margin-top: 15px !important;

      .ant-pagination-item {
        a {
          color: #667085 !important;
          font-weight: 400 !important;
          font-family: ${({ theme }) =>
            `${theme?.dashboard?.fontFamily}, sans-serif` || font} !important;
        }

        &.ant-pagination-item-active {
          background: #e8f4ff !important;

          a {
            color: #2b3674 !important;
          }
        }
      }

      .ant-pagination-item-link {
        font-size: 16px !important;
        color: #2b3674 !important;
      }
    }
  }
`;
