import styled from 'styled-components';

interface SArrowSVGStyles {
  type?: 'down' | 'up' | 'left' | 'right';
}

export const SArrowSVG = styled.svg<SArrowSVGStyles>`
  transform: ${({ type }) => {
    switch (type) {
      case 'up':
        return 'rotate(180deg)';
      case 'left':
        return 'rotate(90deg)';
      case 'right':
        return 'rotate(270deg)';
      default:
        return 'initial';
    }
  }};
`;
