import styled from 'styled-components';

export const STopBar = styled.div`
  background: ${({ theme }) => theme.header.background};
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;

  .topbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    height: 83px;
    position: relative;
  }

  .crystalcheck {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .crystalcheck-icon {
    width: 100%;
    max-height: 63px;
  }

  .logo-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo-icon {
    max-height: 75px;
  }

  .company-name {
    margin: auto;
    color: ${({ theme }) => theme.header.business.color};
    font-size: ${({ theme }) => theme.header.business.size};
    font-family: ${({ theme }) => theme.header.business.family};
    font-weight: ${({ theme }) => theme.header.business.weight};
  }

  .right-box-wrapper {
    display: flex;
    flex-direction: column;
  }
`;
