import React from 'react';
import PropTypes from 'prop-types';
import Hotkeys from 'react-hot-keys';
import { json2csv } from 'json-2-csv';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import { downloadFile, prepareTraceForCsv } from 'lib/util';

class DownloadTraceHotkey extends React.Component {
  onKeyDown(keyName) {
    const { data, t } = this.props;

    if (keyName === 'shift+s') {
      const filename = data?.traceId
        ? `${data.traceId}.csv`
        : data?.trace_id
        ? `${data.trace_id}.csv`
        : 'export.csv';

      json2csv(prepareTraceForCsv(data), {
        delimiter: { field: ';' },
        emptyFieldValue: '',
        expandArrayObjects: true,
        unwindArrays: true,
        excludeKeys: [
          'traceId',
          'blockNumber',
          'txHash',
          'trace_id',
          'id',
          'updated_at',
          'created_at',
          'createdAt',
          'editedAt',
          'editedLocation',
          'editedAuthor',
          'date',
          'location',
          'author',
        ],
      })
        .then((csv) => {
          downloadFile(csv, filename);
          message.success(t('messages.fileDownloaded'));
        })
        .catch((err) => message.error(`${err.name}: ${err.message}`));
    }
  }

  render() {
    const { children: Component } = this.props;
    return Component ? (
      <Hotkeys keyName="shift+s" onKeyDown={this.onKeyDown.bind(this)}>
        <Component {...this.props} />
      </Hotkeys>
    ) : null;
  }
}

DownloadTraceHotkey.propTypes = {
  /**
   * The component on which the hotkey should be triggered. This Child component is wrapped with *DownloadTraceHotkey* component.
   */
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  /**
   * Data to be parsed into csv.
   */
  data: PropTypes.object.isRequired,
};

export default withTranslation()(DownloadTraceHotkey);
