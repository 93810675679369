import { FC, useState } from 'react';
import { Button, Flex, Form, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { closeGenericModalAction } from 'containers/GenericModal/reducer';
import { SFormWrapper } from './styled-components';
import { sendEmailFormModal } from 'components/Modal/constants';
import { store } from 'store/nextStore';

const onboardInitials = (business, t) => {
  const businessName = (business === 'decathlon' || business === 'dkt') ? 'Decathlon' : 'Anna Rose';

  return {
    subject: t('sendEmailForm.onBoardEmailSubject', { businessName }),
    message: t('sendEmailForm.onBoardEmailMessage', { businessName }),
  }
};

const onboardingInitials = (business, t) => {
  const businessName = (business === 'decathlon' || business === 'dkt') ? 'Decathlon' : 'Anna Rose';

  return {
    subject: t('sendEmailForm.onBoardingEmailSubject'),
    message: t('sendEmailForm.onBoardingEmailMessage', { businessName }),
  }
};

const SendEmailForm: FC = ({ data, businessName }: any) => {
  const { email, Status } = data;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const onFinish = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      store.dispatch(closeGenericModalAction({ name: sendEmailFormModal }));
    }, 1500);
  };

  return (
    <SFormWrapper>
      <p className="email"> {t('sendEmailForm.emailTo')}: {email}</p>
      <Form
        name="send_email"
        initialValues={
          Status === 'TO ONBOARD'
            ? onboardInitials(businessName, t)
            : Status === 'ONBOARDING'
            ? onboardingInitials(businessName, t)
            : {}
        }
        onFinish={onFinish}
      >
        <Form.Item label={t('sendEmailForm.cc')} name="cc">
          <Input allowClear />
        </Form.Item>

        <Form.Item
          label={t('sendEmailForm.subject')}
          name="subject"
          rules={[{ required: true, message: t('sendEmailForm.inputSubject') }]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item
          name="message"
          rules={[{ required: true, message: t('sendEmailForm.inputMessage') }]}
        >
          <Input.TextArea allowClear />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Flex gap="small" justify="flex-end">
            <Button
              onClick={() =>
                store.dispatch(
                  closeGenericModalAction({ name: sendEmailFormModal })
                )
              }
              className="cancel-button"
            >
              {t('common.cancel')}
            </Button>
            <Spin size="small" spinning={isSubmitting}>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
              >
                {t('sendEmailForm.submit')}
              </Button>
            </Spin>
          </Flex>
        </Form.Item>
      </Form>
    </SFormWrapper>
  );
};

export default SendEmailForm;