import { FC } from 'react';
import { SArrowSVG } from './styled-components';

interface ArrowProps {
  size?: number;
  type?: 'down' | 'up' | 'left' | 'right';
}

const Arrow: FC<ArrowProps> = ({ size = 11, type = 'down' }) => {
  return (
    <SArrowSVG
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="inherit"
      type={type}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99747 7.53363C5.13613 7.53363 5.26447 7.50709 5.38247 7.45396C5.49755 7.40088 5.61263 7.3153 5.72767 7.1973L8.91851 3.94008C9.08372 3.77781 9.16634 3.57719 9.16634 3.33821C9.16634 3.18184 9.12651 3.03727 9.04684 2.9045C8.96717 2.77173 8.86247 2.66552 8.73263 2.58586C8.6028 2.5062 8.45826 2.46637 8.29892 2.46637C8.05997 2.46637 7.84605 2.56226 7.65722 2.75403L4.99305 5.50671L2.3377 2.75403C2.15477 2.56226 1.94087 2.46637 1.69599 2.46637C1.53962 2.46637 1.39653 2.5062 1.26671 2.58586C1.13395 2.66552 1.02921 2.77173 0.9525 2.9045C0.872837 3.03727 0.833008 3.18184 0.833008 3.33821C0.833008 3.57719 0.914141 3.77781 1.07641 3.94008L4.26726 7.1973C4.3823 7.31238 4.49884 7.39646 4.61688 7.44954C4.73192 7.50267 4.8588 7.53067 4.99747 7.53363Z"
        fill="inherit"
      />
    </SArrowSVG>
  );
};

export default Arrow;

