import { FC, ReactNode, useState } from 'react';
import { Popover } from 'antd';
import PopoverContent from './components/PopoverContent';
import PopoverTitle from './components/PopoverTitle';

interface InfoPopoverProps {
  title?: string;
  triggerElement: ReactNode;
  triggerAction?: 'click' | 'hover' | 'focus';
  content: any;
  type?: 'dot_legend' | 'text_array';
  closeBtnLabel?: string;
}

const InfoPopover: FC<InfoPopoverProps> = ({
  title,
  triggerElement,
  triggerAction = 'click',
  content,
  type,
  closeBtnLabel,
}) => {
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={
        <PopoverContent
          content={content}
          type={type}
          closeFn={close}
          displayClose={triggerAction !== 'hover'}
          closeBtnLabel={closeBtnLabel}
        />
      }
      title={<PopoverTitle title={title} />}
      trigger={triggerAction}
      open={open}
      onOpenChange={handleOpenChange}
    >
      {triggerElement}
    </Popover>
  );
};

export default InfoPopover;
