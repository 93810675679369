import React, { ReactNode } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SortOrder, ColumnFilterItem } from 'antd/lib/table/interface';
import sortAs from '../sortAs';
import * as filters from '../filters';
import TableElement from '../../components/TableElement';
import SortArrows from 'sections/GenericTablePage/components/SortArrows';
import { TableSchema } from '../../types';

/**
 * This class is a builder for building column object for columns field of ant design table component
 */
class ColumnPropsBuilder {
  column: ColumnProps<any>;

  constructor(column: ColumnProps<any> = {}) {
    this.column = column;
  }

  addTitle(title: string, helpType?: 'dot_legend', helpContent?: any) {
    this.column.title = (
      <TableElement
        text={title}
        helpType={helpType}
        helpContent={helpContent}
      />
    );
    return this;
  }

  addKey(key: string) {
    this.column.key = key;
    return this;
  }

  addWidth(column: TableSchema) {
    // @ts-ignore
    this.column.width = column.isWidthFixed && column.width;
    return this;
  }

  addDataIndex(dataIndex: string) {
    this.column.dataIndex = dataIndex;
    return this;
  }

  addSorter(sortType: string, fieldName: string) {
    this.column.sorter = (a, b) => sortAs[sortType](a, b, fieldName);
    return this;
  }

  addBooleanSorter(sortAs: any) {
    this.column.sorter = !!sortAs;
    return this;
  }

  addSortDirections() {
    this.column.sortDirections = ['ascend', 'descend', 'ascend'];
    return this;
  }

  addDefaultSortDirections(defaultOrder: SortOrder) {
    this.column.defaultSortOrder = defaultOrder;
    return this;
  }

  addSortOrder(sortedInfo: any) {
    if (
      typeof sortedInfo === 'object' &&
      sortedInfo !== null &&
      !Array.isArray(sortedInfo) &&
      Object.keys(sortedInfo).length !== 0
    ) {
      this.column.sortOrder =
        sortedInfo.columnKey === this.column.dataIndex
          ? sortedInfo.order
          : null;
    }

    return this;
  }

  addFilterDropdown(customFilter: string, name: string) {
    this.column.filterDropdown =
      !!customFilter &&
      function (callbacks) {
        return filters[customFilter]({
          ...callbacks,
          name,
        });
      };
    return this;
  }

  addOnFilter(onFilter: (value: any, record: any) => boolean) {
    this.column.onFilter = onFilter;
    return this;
  }

  addFilters(filters: ColumnFilterItem[]) {
    this.column.filters = filters;
    return this;
  }

  addFilteredValue(filteredInfo: object) {
    if (!!filteredInfo) {
      this.column.filteredValue = filteredInfo[this.column.dataIndex as string];
    }

    return this;
  }

  addEllipsis() {
    this.column.ellipsis = true;
    return this;
  }

  addCustomSorterArrows() {
    this.column.sortIcon = ({ sortOrder }) => <SortArrows order={sortOrder} />;
    return this;
  }

  addRender(
    render: (
      text: string,
      record: any,
      index: number
    ) => { props: { className: string }; children: ReactNode }
  ) {
    this.column.render = render;
    return this;
  }

  addChild(children: [], fn) {
    // @ts-ignore
    this.column.children =
      children?.length > 0 && children.map((item) => fn(item));
    return this;
  }

  /**
   * returns a copy of built object
   */
  build(): ColumnProps<any> {
    return { ...this.column };
  }
}

export default ColumnPropsBuilder;
