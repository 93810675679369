import client from 'lib/apiClient';
import { getDashboardName } from './util';
import { PageSchema } from 'sections/GenericTablePage/types';

// Stubbed responses for the DEMO
import SUPPLIERS_COCKPIT_RESPONSE from './response/suppliers_cockpit_response.json';
import SUPPLIERS_COCKPIT_DKT_RESPONSE from './response/suppliers_cockpit_dkt_response.json';
import CONFORMITY_RESPONSE from './response/conformity_response.json';
import CONFORMITY_DKT_RESPONSE from './response/conformity_dkt_response.json';
import CORETEAM_RESPONSE from './response/coreteam_response.json';
import CORETEAM_DKT_RESPONSE from './response/coreteam_dkt_response.json';

interface Props {
  businessName: string;
  params: object;
  filters: any;
  pageSchema: PageSchema,
  id?: string;
  language?: string;
}

export const fetchData = async ({
  businessName,
  params,
  filters,
  pageSchema,
  id,
  language
}: Props) => {
  const isDecathlonDemo = businessName === 'decathlon';
  const isAnnaRoseDemo = businessName === 'annarose';

  if (
    pageSchema?.path?.split('/')[1] === 'supplier-status' &&
    (isDecathlonDemo || isAnnaRoseDemo)
  ) {
    return isDecathlonDemo
      ? SUPPLIERS_COCKPIT_DKT_RESPONSE
      : SUPPLIERS_COCKPIT_RESPONSE;
  }
  if (
    pageSchema?.path?.split('/')[1] === 'conformity-dashboard' &&
    (isDecathlonDemo || isAnnaRoseDemo)
  ) {
    return isDecathlonDemo ? CONFORMITY_DKT_RESPONSE : CONFORMITY_RESPONSE;
  }
  if (
    pageSchema?.path?.split('/')[1] === 'core-team-cockpit' &&
    (isDecathlonDemo || isAnnaRoseDemo)
  ) {
    return isDecathlonDemo ? CORETEAM_DKT_RESPONSE : CORETEAM_RESPONSE;
  }

  return await client.getDashboardData({
    business: businessName,
    dashboard_name: getDashboardName(businessName, pageSchema),
    params: {
      ...params,
      ...filters,
      id,
      ...(language && { lang: language }),
    },
  });
};
