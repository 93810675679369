import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { PageHeader } from '@ant-design/pro-layout';

class ScreenManager extends React.Component {
  constructor(props) {
    super(props);
    const { mainScreen } = this.props;
    this.state = {
      previousScreen: '',
      currentScreen: mainScreen,
      mainScreen,
    };
  }

  handleBackToPrevScreen = () => {
    const { previousScreen, currentScreen, mainScreen } = this.state;

    this.setState({
      previousScreen: currentScreen,
      currentScreen: previousScreen || mainScreen,
    });
  };

  isMainScreen = () => {
    const { currentScreen, mainScreen } = this.state;

    return currentScreen === mainScreen;
  };

  handleScreenChange = ({ screenName }) => {
    const { currentScreen } = this.state;

    this.setState({
      previousScreen: currentScreen,
      currentScreen: screenName,
    });
  };

  render() {
    const { screens, t } = this.props;
    const { currentScreen, previousScreen, mainScreen } = this.state;
    const isMainScrean = this.isMainScreen();

    return (
      <div>
        <PageHeader
          onBack={!isMainScrean && this.handleBackToPrevScreen}
          subTitle={
            !isMainScrean &&
            `${t('common.backTo')} ${previousScreen || mainScreen}`
          }
          style={{
            display: isMainScrean ? 'none' : 'block',
          }}
        />
        {screens[currentScreen]({
          ...this.props,
          onScreenChange: this.handleScreenChange,
        })}
      </div>
    );
  }
}

ScreenManager.propTypes = {
  mainScreen: PropTypes.string,
  screens: PropTypes.object,
};

export default withTranslation()(ScreenManager);
