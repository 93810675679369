import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'lib/apiClient';
import { Dayjs } from 'dayjs';

export interface Message {
  message: {
    message: String;
  };
  id: number | string;
  read: Boolean;
  created_at: string | number | Date | Dayjs;
  expired: Boolean;
  name: string;
}

export interface NotificationState {
  notifications: Object;
}

const initialState: NotificationState = {
  notifications: {},
};

export const getNotifications = createAsyncThunk(
  'notificationsSlice/getNotifications',
  async () => await client.getNotifications()
);

export const markNotificationAsRead = createAsyncThunk(
  'notificationsSlice/markNotificationAsRead',
  async (id) => {
    await client.markNotificationAsRead(id)({ read: true });
    return id;
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        if (Array.isArray(payload)) {
          const updatedNotifications = {};

          payload.forEach((message) => {
            updatedNotifications[message.id] = {
              id: message.id,
              message: message?.message?.message || '',
              read: message.read || false,
              created_at: message.created_at,
              expired: message.expired || false,
            };
          });

          state.notifications = updatedNotifications;
        }
      })
      .addCase(markNotificationAsRead.fulfilled, (state, { payload }) => {
        // @ts-ignore
        delete state.notifications[payload];
      });
  },
});

// export const { markAsRead } = notificationsSlice.actions;

export default notificationsSlice.reducer;
