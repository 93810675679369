import styled from 'styled-components';
import { Space } from 'antd';
import { borderRadius, colors, font } from 'App/styleVariables';

export const DropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: ${borderRadius};
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 12px 14px;
  border: 1px solid #eaecf0;
  font-family: ${font};

  .ant-divider-horizontal {
    margin: 6px 0;
  }
`;

export const DropdownItemWrapper = styled(Space)`
  width: 100%;
  justify-content: space-between;

  .dropdown-item-btn {
    border: none;
    padding: 0 !important;
    height: auto;
    font-size: 16px;
    color: inherit;
    box-shadow: none !important;

    &:hover {
      background-color: transparent !important;
      color: ${colors.secondary} !important;
    }
  }
`;
