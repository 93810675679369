import AbstractResourceFactory from './abstract-resource-factory';
import client from 'lib/apiClient';
import { Table } from '../../../types';

class WorkflowObjectHistory implements AbstractResourceFactory {
  defaultFilterState = {
    sorting: '%5B%5D',
    lines_per_page: 10,
  };
  toggleArrow = 2560;
  isSearchEnabled = false;
  async getAll(
    page = 1, 
    searchField = '', 
    filters: object,
    list_name: string,
    filter_params,
    pageSchema,
    traceId,
  ): Promise<Table> {
    
    const { data, scheme, numberOfElements } = await client.getWorkflowObjectHistory({
      id: traceId,
      query: searchField,
      ...filters,
      page,
    });

    return {
      data,
      schema: scheme,
      total: numberOfElements,
    };
  }
}

export default WorkflowObjectHistory;
