import React from 'react';
import { Spin } from 'antd';
import { CertificatesInterface } from '../../types';
import { SIndicatorContainer } from './styled-components';

const FrontEndTableComponent = React.lazy(
  () => import('../../../GenericTablePage/table/front-end-paginated-table')
);

const CertificateManager = ({
  styles,
  bordered,
  data,
  table_scheme,
  pagination = true,
  isSearchEnabled = true,
  title,
  displayTitle = true,
}: CertificatesInterface) => {
  return (
    <SIndicatorContainer styles={styles} bordered={bordered} displayTitle={displayTitle}>
      {displayTitle && (
        <p className={'indicator-title'}>{title || 'Certificat par acteur'}</p>
      )}
      <React.Suspense
        fallback={<Spin style={{ display: 'block' }} size="large" />}
      >
        <FrontEndTableComponent
          dataSource={data}
          columns={table_scheme}
          getRowKey={(record: any) => record.ProjectId || record.id}
          isSearchEnabled={isSearchEnabled}
          searchButtonText={false}
          searchPlaceholder={'Recherche'}
          pagination={
            pagination && { showSizeChanger: false, defaultPageSize: 6 }
          }
        />
      </React.Suspense>
    </SIndicatorContainer>
  );
};

export default CertificateManager;
