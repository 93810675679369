import { store } from '../../../store/nextStore';
import { changeModalTitleAction } from '../../../containers/GenericModal/reducer';
import {
  COMPANIES_ADD_TITLE,
  formWorkerModal,
  TRACES_ADD_TITLE,
  UPLOADS_ADD_TITLE,
  USERS_ADD_TITLE,
} from '../constants';
import {
  ADD_ORGANIZATION_ACTION,
  ADD_TRACE_ACTION,
  ADD_UPLOAD_ACTION,
  ADD_USER_ACTION,
  ADD_WORKFLOW_ACTION,
  EDIT_WORKFLOW_ACTION,
} from '../actions';

const handleSubTitle = (value: any, action: any, t: (string) => string) => {
  let title;
  switch (action) {
    case ADD_USER_ACTION:
      title = `${USERS_ADD_TITLE(t)} ${value}`;
      break;
    case ADD_ORGANIZATION_ACTION:
      title = `${COMPANIES_ADD_TITLE(t)} ${value}`;
      break;
    case ADD_UPLOAD_ACTION:
      title = `${UPLOADS_ADD_TITLE(t)} ${value}`;
      break;
    case ADD_TRACE_ACTION:
      title = `${TRACES_ADD_TITLE(t)} ${value}`;
      break;
    case ADD_WORKFLOW_ACTION:
    case EDIT_WORKFLOW_ACTION:
      title = `${value}`;
      break;
    default:
      title = '';
      break;
  }

  store.dispatch(changeModalTitleAction({ name: formWorkerModal, title }));
};

export default handleSubTitle;
