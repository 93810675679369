import styled from 'styled-components';
import { font } from 'App/styleVariables';

export const SWrapper = styled.div`
  .ant-descriptions,
  .supplier-form-select,
  .ant-empty-description {
    font-family: ${font};
  }

  .ant-descriptions-item-label {
    font-weight: 500;
    color: #101010;
  }

  .supplier-form-select-wrapper {
    width: 100%;
    margin-bottom: 0;
  }

  .supplier-form-select:not(.ant-select-disabled) {
    &:hover,
    &:focus {
      .ant-select-selector {
        border: ${({ theme }) =>
          theme.form.item?.input?.borderActive} !important;
      }
    }
  }

  .supplier-form-select:not(.ant-select-disabled).ant-select-focused {
    .ant-select-selector {
      border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
    }
  }

  .submit-button {
    font-size: ${({ theme }) => theme.form.submitButton?.size} !important;
    font-weight: ${({ theme }) => theme.form.submitButton?.weight} !important;
    font-family: ${({ theme }) => theme.form.submitButton?.family} !important;
    color: ${({ theme }) => theme.form.submitButton?.color} !important;
    border: none !important;
    background: ${({ theme }) => theme.form.submitButton?.passive} !important;

    &:hover {
      color: ${({ theme }) => theme.form.submitButton?.color} !important;
      background: ${({ theme }) => theme.form.submitButton?.active} !important;
    }
  }
`;
