import styled from 'styled-components';
import { SIndicator } from '../../styled-components';
import { font } from 'App/styleVariables';

interface LegendItemStyles {
  color: string;
  labelColor?: string;
}

export const SIndicatorContainer = styled(SIndicator)`
  .ant-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .chart-wrapper {
    width: 34%;
    text-align: center;
  }
  #gaugeChart {
    width: 100%;
    height: 140px;
  }
  .traces-wrapper {
    width: 65%;
    color: #101828;
  }
  .traces-title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 1.2;
    margin-bottom: 6px;

    span {
      margin-right: 10px;
      font-weight: 600;
      font-size: 36px;

      @media (max-width: 1600px) {
        font-size: 28px;
      }
      @media (max-width: 1440px) {
        font-size: 24px;
      }

      &.tooltip {
        cursor: pointer;
      }
    }
  }
  .complete-progress {
    margin-bottom: 15px;
  }
  .monitoring-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @media (max-width: 1500px) {
      flex-flow: row wrap;
    }
  }
  .monitoring-item {
    width: 32.5%;

    @media (max-width: 1500px) {
      width: 48.5%;
      align-self: flex-end;

      &:first-child {
        width: 100%;
      }
    }
  }

  .custom-progress-bar {
    margin: 8px 0 15px;
  }
`;

export const SLegendItem = styled.div<LegendItemStyles>`
  display: flex;
  margin-bottom: 10px;
  font-family: ${({ theme }) =>
    theme?.dashboard?.fontFamily
      ? `${theme?.dashboard?.fontFamily}, sans-serif`
      : font};

  &:before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 5px;
  }

  span {
    line-height: 1;
  }
`;
