import { FC } from 'react';
import { ConfigProvider } from 'antd';
import { connect } from 'react-redux';
import enUS from 'antd/es/locale/en_US';
import frFR from 'antd/es/locale/fr_FR';
import zhCN from 'antd/locale/zh_CN';

const ConfigProviderWrapper: FC<any> = ({ currentLanguage, children }) => {
  const getAntLocale = () => {
    switch (currentLanguage) {
      case 'en':
        return enUS;
      case 'fr':
        return frFR;
      case 'cn':
        return zhCN;
      default:
        return enUS;
    }
  };

  return <ConfigProvider locale={getAntLocale()}>{children}</ConfigProvider>;
};

const mapStateToProps = ({ auth }) => ({
  currentLanguage: auth.currentUser.attributes.lang,
});

export default connect(mapStateToProps)(ConfigProviderWrapper);
