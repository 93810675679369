import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from 'App/styleVariables';

export const SNotificationWrapper = styled(Button)`
  padding: 0 !important;
  border-radius: 50% !important;
  width: 42px;
  height: 42px;
  background-color: ${({ theme }) =>
    theme.header.notification?.passiveBgColor || colors.secondary};
  color: ${({ theme }) => theme.header.notification?.passiveColor || '#ffffff'};
  border: none;

  &:hover {
      background-color: ${({ theme }) =>
        theme.header.notification?.activeBgColor ||
        colors.secondary20} !important;
      color: ${({ theme }) =>
        theme.header.notification?.activeColor || colors.primary} !important;
    }

  .notification-bell {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;    
  }

  .notification-badge-container {
    height: 100%;
    width: 100%;
    color: inherit;
  }
`;
