import client from 'lib/apiClient';
import errorHandler from "../../lib/errorHandler";

const getKindsList = async ({ type, filter_params }) => {
  try {
    if (filter_params && filter_params.upload_type) {
      return await client.getUploadKinds({
        upload_type: filter_params.upload_type,
      });
    }

    if (type === 'workflow') {
      return await client.getWorkflowKinds();
    } else {
     return await client.getKinds({ type });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Err on getKindsList', err);
    errorHandler(err);
  }
};

const getFormScheme = async ({ type, kind, listName, defaultScheme }) => {
  try {
    if (type === 'suppliers_dashboard') {
      return {
        "Supplier": {
          "label": "Supplier",
          "disabled": true,
          "rules": {
            "required": false
          },
          "type": "DefaultInput",
          "defaultValue": "",
          "isEditingDisabled": null,
        },
        "Industry": {
          "label": "Industrial process",
          "disabled": true,
          "rules": {
            "required": false
          },
          "type": "DefaultInput",
          "defaultValue": "",
          "isEditingDisabled": null,
        },
        "Rank": {
          "label": "Supplier’s rank",
          "disabled": true,
          "rules": {
            "required": false
          },
          "type": "DefaultInput",
          "defaultValue": "",
          "isEditingDisabled": null,
        },
        "DeclaredBy": {
          "label": "Declared by",
          "disabled": true,
          "rules": {
            "required": false
          },
          "type": "DefaultInput",
          "defaultValue": "",
          "isEditingDisabled": null,
        },
        "DeclaredProduct": {
          "label": "Declared product",
          "disabled": true,
          "rules": {
            "required": false
          },
          "type": "DefaultInput",
          "defaultValue": "",
          "isEditingDisabled": null,
        },
        "Status": {
          "label": "Status",
          "disabled": false,
          "rules": {
            "required": false
          },
          "type": "Select",
          "defaultValue": null,
          "isEditingDisabled": null,
          "options": [
            { "value": "DECLARED", "text": "DECLARED" },
            { "value": "ON HOLD", "text": "ON HOLD" },
            { "value": "TO ONBOARD", "text": "TO ONBOARD" },
          ]
        },
        "email": {
          "label": "Email",
          "disabled": true,
          "rules": {
            "required": false
          },
          "type": "DefaultInput",
          "defaultValue": "",
          "isEditingDisabled": null,
        },
        "Country": {
          "label": "Country",
          "disabled": true,
          "rules": {
            "required": false
          },
          "type": "DefaultInput",
          "defaultValue": "",
          "isEditingDisabled": null,
        },
      }
    }

    if (type === 'workflow') {
      const res = !defaultScheme
        ? await client.getWorkflowScheme(kind)
        : defaultScheme;

      return {
        ...res,
        scheme: res?.fields
      };
    }
    
    if (type === 'custom_list') {
      return {
        scheme: await client.getCustomListForm({ type, kind, listName }),
      };
    }

    return {
      scheme: await client.getForm({ type, kind })
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Err on getFormScheme', err);
    errorHandler(err);
  }
};

export { getKindsList, getFormScheme };