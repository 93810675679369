const onSearch = (query, records) => {
  if ((!query || query.length === 0) && records) {
    return records;
  }

  const stringRegex = query.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  const searchResult = records
    .map((record) => {
      const reg = new RegExp(stringRegex, 'gi');

      const results = Object.keys(record).filter(
        (k) =>
          k !== 'data' && record[k] && record[k].match && record[k].match(reg)
      );
      if (record.company !== undefined) {
        const inner = record.company;
        const companyResults = Object.keys(inner).filter(
          (k) =>
            k !== 'data' && inner[k] && inner[k].match && inner[k].match(reg)
        );
        if (companyResults.length) {
          return record;
        }
      }
      return results.length ? record : null;
    })
    .filter((r) => r);
  return searchResult;
};

export default onSearch;
