import { useState } from 'react';
import { DatePicker, Input, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Style from './styled-component';
import { AnalyticsInterface } from './utils/type';
import { deviceColumns, eventColumns, pageColumns } from './utils/columns';
import SegmentedGraph from './components/SegmentedGraph';
import TableView from './components/TableView';
import { colors } from '../../App/styleVariables';

const { Title } = Typography;
const { StyledTableWrapper } = Style;

const initializeProps = (t: any):AnalyticsInterface => ({
  date_input: {
    title: t('analyticsDashboard.dateAndPeriod'),
    date_format: 'MMM D YYYY',
    period_labels: [t('common.day'), t('common.week'), t('common.month'), t('common.year')],
  },
  widgets: [
    {
      name: 'Dashboard',
      title: t('analyticsDashboard.dashboard'),
      colors: {
        success: '#3f8600',
        info: colors.secondary,
      },
      metrics: [
        {
          name: 'nb_visits',
          label: t('analyticsDashboard.numberVisitors'),
          yaxis_title: t('analyticsDashboard.numberVisitors'),
        },
        {
          name: 'avg_time_spent',
          label: t('analyticsDashboard.averageVisitDurationSec'),
          yaxis_title: t('analyticsDashboard.timeSec'),
        },
        {
          name: 'nb_pageviews',
          label: t('analyticsDashboard.pageViews'),
          yaxis_title: t('analyticsDashboard.numberPages'),
        },
        {
          name: 'nb_bottom_reached',
          label: t('analyticsDashboard.bottomOfPageReached'),
          yaxis_title: t('analyticsDashboard.numberEvents'),
        },
        {
          name: 'penetration_rate',
          label: t('analyticsDashboard.penetrationRate'),
          yaxis_title: t('analyticsDashboard.rate'),
        },
      ],
    },
    {
      name: 'Pageviews',
      title: t('analyticsDashboard.detailByPage'),
    },
    {
      name: 'Eventviews',
      title: t('analyticsDashboard.detailByAction'),
    },
    {
      name: 'Deviceviews',
      title: t('analyticsDashboard.detailByDevice'),
    },
  ],
  styles: {
    size: {
      column: {
        start: 1,
        end: 11,
      },
    },
  },
});

const Analytics = () => {
  const [period, setPeriod] = useState('day');
  const [date, setDate] = useState(
    dayjs().subtract(1, 'day').format('YYYY-MM-DD')
  );
  const { t } = useTranslation();

  const { date_input, widgets, styles } = initializeProps(t);
  const { date_format } = date_input;
  const handlePeriodChange = (value: string) => {
    setPeriod(value);
  };

  const handleDateChange = (useless: any, dateString: any) => {
    if (dateString === '') {
      setDate(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
    } else {
      setDate(dayjs(dateString, date_format).format('YYYY-MM-DD'));
    }
  };

  const widget_components = widgets.map((widget) => {
    const props = {
      date: date,
      period: period,
      ...widget,
    };
    switch (widget.name) {
      case 'Dashboard':
        return <SegmentedGraph {...props} />;
      case 'Pageviews':
        return (
          <TableView
            {...props}
            columns={pageColumns(t)}
            method={'Actions.getPageUrls'}
          />
        );
      case 'Eventviews':
        return (
          <TableView
            {...props}
            columns={eventColumns(t)}
            method={'Events.getAction'}
          />
        );
      case 'Deviceviews':
        return (
          <TableView
            {...props}
            columns={deviceColumns(t)}
            method={'DevicesDetection.getType'}
            filter={(record: any) => record.nb_visits > 0}
          />
        );
      default:
        return <></>;
    }
  });

  return (
    <StyledTableWrapper styles={styles}>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <Input.Group size="large">
          <Title level={3}>{date_input.title?.toUpperCase()}</Title>
          <Space>
            <DatePicker
              size={'large'}
              format={date_format}
              value={dayjs(date, 'YYYY-MM-DD')}
              onChange={handleDateChange}
            />
            <Select
              size={'large'}
              dropdownMatchSelectWidth={false}
              value={period}
              onChange={handlePeriodChange}
              options={[
                { value: 'day', label: date_input.period_labels[0] },
                { value: 'week', label: date_input.period_labels[1] },
                { value: 'month', label: date_input.period_labels[2] },
                { value: 'year', label: date_input.period_labels[3] },
              ]}
            />
          </Space>
        </Input.Group>

        {widget_components}
      </Space>
    </StyledTableWrapper>
  );
};

export default Analytics;
