import React from 'react';
import GenericTablePage from '../../sections/GenericTablePage';
import { WORKFLOW_OBJECT_HISTORY_TABLE } from 'sections/GenericTablePage/constants';

type Props = {
    id: string | number;
};

const WorkflowObjectHistory: React.FC<Props> = ({ id }: Props) => {
  return (
    <GenericTablePage
      pageName={WORKFLOW_OBJECT_HISTORY_TABLE}
      traceId={id}
      withWrapper={false}
    />
  );
};

export default WorkflowObjectHistory;
