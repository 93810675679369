import { Config } from 'config.js';
import query_presenter from '../presenters/QueryPresenter';

const { API_URL } = Config;
const loginUrl = `${API_URL}/auth/sign_in`;
const signupUrl = `${API_URL}/auth/`;

const apiUrl = `${API_URL}/api/v2`;
const table = 'table_scheme';

const tracesUrl = `${API_URL}/api/v2/traces`;
const duplicatesUrl = `${API_URL}/api/v2/duplicates/duplicates`;
// const suppliersUrl = `${API_URL}/api/v2/suppliers`;
const usersUrl = `${API_URL}/api/v2/users`;
const productsUrl = `${API_URL}/api/v2/products`;
const smsUrl = `${API_URL}/api/v2/sms_notifications`;
const traceUploadUrl = `${API_URL}/api/v2/uploads`;
const companiesUrl = `${API_URL}/api/v2/companies`;
const chainsUrl = `${API_URL}/api/v2/chains`;
const documentsUrl = `${API_URL}/api/v2/files`;
const documentsSchemeUrl = `${documentsUrl}/table_scheme`;
const customListsUrl = ({ list_name }) =>
  `${API_URL}/api/v2/lists/${list_name}/items`;
const smsConfirmationUrl = `${API_URL}/api/v2/sms_confirmations`;
const traceHistoryUrl = `${API_URL}/api/v2/trace_history`;

const stocksUrl = `${API_URL}/api/v2/stocks`;

const formsUrl = `${API_URL}/api/v2/forms`;
const listSchemeUrl = `${API_URL}/api/v2/forms/list_scheme`;

const workflowUrl = `${apiUrl}/workflow_objects`;
const workflowSchemesUrl = `${apiUrl}/workflow_schemes`;

const validUrl = 'validations';
const sludgeUrl = `${apiUrl}/${validUrl}/analyses_boues`;
const analysesCompUrl = `${apiUrl}/${validUrl}/analyses_comp`;
const mudUrl = `${apiUrl}/${validUrl}/analyses_boues_bact`;
const soilUrl = `${apiUrl}/${validUrl}/analyses_sols`;
const stockingUrl = `${apiUrl}/${validUrl}/destockages`;
const stockingDetailsUrl = `${apiUrl}/${validUrl}/destockages/options`;
const bk_url = `${apiUrl}/trace_up`;

const pagesURL = `${apiUrl}/pages/`;
const signInUrl = `${API_URL}/auth/sign_in`;
const signOutUrl = `${API_URL}/auth/sign_out`;
const verifyTokenUrl = `${API_URL}/auth/validate_token`;
const resetPasswordUrl = `${apiUrl}/users/password_update`;
const verifyBusinessUrl = `${API_URL}/auth/validate_business`;
const resetForgotPasswordUrl = `${API_URL}/auth/password`;
const confirmEmailURL = `${API_URL}/auth/confirmation`;
const getLoginMethod = `${apiUrl}/users/login_method`;
const businessDataUrl = `${apiUrl}/users/business_list`;

const notificationsURL = `${apiUrl}/notifications`;

const dashboardAuth = `${apiUrl}/dashboard/authentication`;
const commonDashboardUrl = `${apiUrl}/commondashboard`;
const matomoUrl = `${apiUrl}/analytics`;
const monitoringDownloadUrl = `${apiUrl}/monitoring/download`;
const ocrRecognizeUrl = `${apiUrl}/ocr/recognize`;

const serverRequest = (config) => async (data) => {
  const authHeaders = {
    'access-token': sessionStorage.getItem('access-token'),
    client: sessionStorage.client,
    expiry: sessionStorage.expiry,
    'token-type': sessionStorage['token-type'],
    uid: sessionStorage.uid,
    Authorization: 'Bearer AX20000X',
    Service: 'UI2',
  };

  const headersMain = (customHeaders) =>
    new Headers({ 'Content-Type': 'application/json', ...customHeaders });
  const headers = headersMain(config.headers === 'auth' ? authHeaders : null);

  const r = await fetch(config.url, {
    ...config,
    headers,
    body: data && config.method !== 'GET' ? JSON.stringify(data) : undefined,
  });

  if (!r.ok) {
    const response = await r.json();

    if (!response) {
      throw new Error();
    }

    const excludedErrorKeys = ['success'];

    throw Object.keys(response)
      .filter((key) => !excludedErrorKeys.includes(key))
      .map((key) => {
        const e = new Error();
        [e.name] = key !== 'errors' ? [key] : '';
        [e.message] = key === 'errors' ? response[key] : [response[key]];
        return e;
      });
  }

  // update sessionStorage if get new authHeader
  if (r.headers.forEach) {
    r.headers.forEach((value, key) => {
      if (Object.keys(authHeaders).some((item) => item === key)) {
        if (headers.get(key) !== value) {
          sessionStorage[key] = value;
        }
      }
    });
  }

  // throw new Error('Something went wrong');

  const content_type = r.headers.get('Content-Type');

  if (
    content_type === 'application/pdf' ||
    content_type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  )
    return r.blob();

  if (r.status === 204) return null;

  return r.json();
};

const auth = {
  signInUser: ({ email, password }) =>
    serverRequest({
      method: 'POST',
      url: `${signInUrl}`,
      headers: 'auth',
    })({ email, password }),
  signOutUser: (params) =>
    serverRequest({
      method: 'DELETE',
      url: `${signOutUrl}`,
      headers: 'auth',
    })(params),
  verifyToken: (params) =>
    serverRequest({
      method: 'GET',
      url: `${verifyTokenUrl}`,
      headers: 'auth',
    })(params),
  resetPassword: (params) =>
    serverRequest({
      method: 'POST',
      url: `${resetPasswordUrl}`,
      headers: 'auth',
    })(params),
  verifyBusiness: ({ businessId }) =>
    serverRequest({
      method: 'POST',
      url: `${verifyBusinessUrl}`,
      headers: 'auth',
    })({ businessId }),
  sendForgotPasswordEmail: ({ email, redirect_url }) =>
    serverRequest({
      method: 'POST',
      url: `${resetForgotPasswordUrl}`,
      headers: 'auth',
    })({ email, redirect_url }),
  resetForgotPassword: (params) =>
    serverRequest({
      method: 'PATCH',
      url: `${resetForgotPasswordUrl}`,
      headers: 'auth',
    })(params),
  confirmEmail: ({ token }) =>
    serverRequest({
      method: 'GET',
      url: `${confirmEmailURL}?confirmation_token=${token}`,
    })(),
  getLoginMethod: ({ email }) =>
    serverRequest({
      method: 'GET',
      url: `${getLoginMethod}?email=${email}`,
      headers: 'auth',
    })(),
  getBusinessData: () =>
    serverRequest({
      method: 'GET',
      url: `${businessDataUrl}`,
      headers: 'auth',
    })(),
};

const formWorker = {
  getKinds: ({ type }) =>
    serverRequest({
      method: 'GET',
      url: `${formsUrl}/${type}_kinds`,
      headers: 'auth',
    })({ type }),
  getUploadKinds: ({ upload_type }) =>
    serverRequest({
      method: 'GET',
      url: `${formsUrl}/upload_types?upload_type=${upload_type}`,
      headers: 'auth',
    })({ upload_type }),
  getForm: ({ type, kind }) =>
    serverRequest({
      method: 'GET',
      url: `${formsUrl}/${type}_scheme/${kind}`,
      headers: 'auth',
    })({ type, kind }),
  getCustomListForm: ({ listName }) =>
    serverRequest({
      method: 'GET',
      url: `${listSchemeUrl}/${listName}`,
      headers: 'auth',
    })({ listName }),
  getOptions: (value, endpoint, limit, next = '') =>
    serverRequest({
      method: 'GET',
      url: `${apiUrl}/${endpoint}?term=${value}&limit=${limit}&cursor=${next}`,
      headers: 'auth',
    })(value, endpoint, limit, next),
  getWorkflowKinds: serverRequest({
    method: 'GET',
    url: `${workflowSchemesUrl}`,
    headers: 'auth',
  }),
  getWorkflowScheme: (workflowId) =>
    serverRequest({
      method: 'GET',
      url: `${workflowSchemesUrl}/${workflowId}`,
      headers: 'auth',
    })(),
};

const commonIframe = {
  doDashboardAuth: (url) =>
    serverRequest({
      method: 'GET',
      url: `${dashboardAuth}?dashboardurl=${url}`,
      headers: 'auth',
    })(url),
};

const commonDashboard = {
  getDashboardData: ({ business, dashboard_name, params }) =>
    serverRequest({
      method: 'POST',
      url: `${commonDashboardUrl}/${business}/${dashboard_name}`,
      headers: 'auth',
    })(params),
  getDashboardTableData: ({ business, dashboard_name, table_name, ...rest }) =>
    serverRequest({
      method: business === 'agec' ? 'GET' : 'POST',
      url: `${commonDashboardUrl}/${business}/${
        business === 'agec'
          ? `${dashboard_name}/${table_name}`
          : `${dashboard_name}${table_name}`
      }${query_presenter.to_query(rest)}`,
      headers: 'auth',
    })(rest),
};

const matomo = {
  getMatomoData: (method, date, period) =>
    serverRequest({
      method: 'GET',
      url: `${matomoUrl}?method=${method}&date=${date}&period=${period}`,
      headers: 'auth',
    })(),
};

const chains = {
  getChains: (data) =>
    serverRequest({
      method: 'GET',
      url: `${chainsUrl}`,
      headers: 'auth',
    })(data),
  getChainsAPI: (params) =>
    serverRequest({
      method: 'GET',
      url: `${chainsUrl}${query_presenter.to_query(params)}`,
      headers: 'auth',
    })(params),
  getChainsByCode: ({ code }) =>
    serverRequest({
      method: 'GET',
      url: `${chainsUrl}/?traceability_id=${code}`,
      headers: 'auth',
    })({ code }),
  getChainDetails: (data) =>
    serverRequest({
      method: 'GET',
      url: `${chainsUrl}/${data.id}/?traceType=${data.traceType}&name=${data.name}`,
      headers: 'auth',
    })(data),
};

const stocks = {
  getStocks: (data) =>
    serverRequest({
      method: 'GET',
      url: `${stocksUrl}/${data}`,
      headers: 'auth',
    })(data),
};

const users = {
  getUsersTable: serverRequest({
    method: 'GET',
    url: `${usersUrl}/${table}`,
    headers: 'auth',
  }),
  getUsers: serverRequest({
    method: 'GET',
    url: usersUrl,
    headers: 'auth',
  }),
  postUser: serverRequest({
    method: 'POST',
    url: usersUrl,
    headers: 'auth',
  }),
  putUser: (data) =>
    serverRequest({
      method: 'PUT',
      url: `${usersUrl}/${data.id}`,
      headers: 'auth',
    })(data),
  deleteUser: serverRequest({
    method: 'DELETE',
    url: usersUrl,
    headers: 'auth',
  }),
  switchLanguage: (data) =>
    serverRequest({
      method: 'POST',
      url: `${usersUrl}/switch_lang`,
      headers: 'auth',
    })(data),
};

const documents = {
  getDocuments: serverRequest({
    method: 'GET',
    url: `${documentsUrl}`,
    headers: 'auth',
  }),
  getDocumentsScheme: serverRequest({
    method: 'GET',
    url: `${documentsSchemeUrl}`,
    headers: 'auth',
  }),
  postDocuments: serverRequest({
    method: 'POST',
    url: `${documentsUrl}`,
    headers: 'auth',
  }),
  putDocuments: ({ id }) =>
    serverRequest({
      method: 'PUT',
      url: `${documentsUrl}/${id}`,
      headers: 'auth',
    }),
  openExtLink: (link) =>
    serverRequest({
      method: 'GET',
      url: `${link}`,
      headers: 'auth',
    })(link),
  openProtectedLink: ({ fileName, businessId, dashboard = false }) =>
    serverRequest({
      method: 'GET',
      url: `${documentsUrl}/expiring_url?business_id=${businessId}&file_name=${fileName}&dashboard=${dashboard}`,
      headers: 'auth',
    })(),
  getRecognizeDocument: ({ fileUrl, data, name, traceType }) =>
    serverRequest({
      method: 'POST',
      url: `${ocrRecognizeUrl}`,
      headers: 'auth',
    })({ file_url: fileUrl, data, file_field_name: name, traceType }),
};

const products = {
  getProductsTable: serverRequest({
    method: 'GET',
    url: `${productsUrl}/${table}`,
    headers: 'auth',
  }),
  getProducts: serverRequest({
    method: 'GET',
    url: productsUrl,
    headers: 'auth',
  }),
  postProduct: serverRequest({
    method: 'POST',
    url: productsUrl,
    headers: 'auth',
  }),
  putProduct: (data) =>
    serverRequest({
      method: 'PUT',
      url: `${productsUrl}/${data.id}`,
      headers: 'auth',
    })(data),
  deleteProduct: serverRequest({
    method: 'DELETE',
    url: productsUrl,
    headers: 'auth',
  }),
};

const customLists = {
  getCustomLists: ({ list_name }) =>
    serverRequest({
      method: 'GET',
      url: `${customListsUrl({ list_name })}`,
      headers: 'auth',
    }),
  getCustomListsAPI: ({ list_name, ...rest }) =>
    serverRequest({
      method: 'GET',
      url: `${customListsUrl({ list_name })}${query_presenter.to_query(rest)}`,
      headers: 'auth',
    }),
  postCustomListItem: ({ list_name }) =>
    serverRequest({
      method: 'POST',
      url: `${customListsUrl({ list_name })}`,
      headers: 'auth',
    }),
  putCustomListItem: ({ list_name, id }) =>
    serverRequest({
      method: 'PUT',
      url: `${customListsUrl({ list_name })}/${id}`,
      headers: 'auth',
    }),
  getCustomListByTraceId: ({ traceId, listName }) =>
    serverRequest({
      method: 'GET',
      url: `${customListsUrl({ list_name: listName })}/${traceId}`,
      headers: 'auth',
    })(),
};

const sms = {
  getSmsTable: serverRequest({
    method: 'GET',
    url: `${smsUrl}/${table}`,
    headers: 'auth',
  }),
  getSms: serverRequest({
    method: 'GET',
    url: smsUrl,
    headers: 'auth',
  }),
};

const smsConfirmation = {
  getSmsConfirmations: (params) =>
    serverRequest({
      method: 'GET',
      url: `${smsConfirmationUrl}${query_presenter.to_query(params)}`,
      headers: 'auth',
    })(params),
  getSmsConfirmationsTable: serverRequest({
    method: 'GET',
    url: `${smsConfirmationUrl}/${table}`,
    headers: 'auth',
  }),
  resendOneSms: (traceId) =>
    serverRequest({
      method: 'GET',
      url: `${smsConfirmationUrl}/resend?trace_id=${traceId}`,
      headers: 'auth',
    })(traceId),
  resendAllSms: (params) =>
    serverRequest({
      method: 'GET',
      url: `${smsConfirmationUrl}/resend${query_presenter.to_query(params)}`,
      headers: 'auth',
    })(params),
};

const traceUpload = {
  getTraceUploadTable: serverRequest({
    method: 'GET',
    url: `${traceUploadUrl}/${table}`,
    headers: 'auth',
  }),
  getTraceUploadTableWithFilter: (params) =>
    serverRequest({
      method: 'GET',
      url: `${traceUploadUrl}/${table}?upload_type=${params.upload_type}`,
      headers: 'auth',
    })(params),
  getTraceUploadAPI: (params) =>
    serverRequest({
      method: 'GET',
      url: `${traceUploadUrl}${query_presenter.to_query(params)}`,
      headers: 'auth',
    })(params),
  getTraceUpload: serverRequest({
    method: 'GET',
    url: traceUploadUrl,
    headers: 'auth',
  }),
  getUploadFile: (data) =>
    serverRequest({
      method: 'GET',
      url: `${traceUploadUrl}/${data.id}?type=${data.type}`,
      headers: 'auth',
    })(data),
  postTraceUpload: serverRequest({
    method: 'POST',
    url: traceUploadUrl,
    headers: 'auth',
  }),
  putTraceUpload: (data) =>
    serverRequest({
      method: 'PUT',
      url: `${traceUploadUrl}/${data.id}`,
      headers: 'auth',
    })(data),
  getUploadTemplates: ({ type }) =>
    serverRequest({
      method: 'GET',
      url: `${traceUploadUrl}/template${type ? `?trace_types=${type}` : ''}`,
      headers: 'auth',
    })(type),
};

const companies = {
  getCompaniesTable: serverRequest({
    method: 'GET',
    url: `${companiesUrl}/${table}`,
    headers: 'auth',
  }),
  getCompanies: serverRequest({
    method: 'GET',
    url: companiesUrl,
    headers: 'auth',
  }),
  postCompany: serverRequest({
    method: 'POST',
    url: companiesUrl,
    headers: 'auth',
  }),
  putCompany: (data) =>
    serverRequest({
      method: 'PUT',
      url: `${companiesUrl}/${data.id}`,
      headers: 'auth',
    })(data),
};

const traces = {
  getTracesTable: serverRequest({
    method: 'GET',
    url: `${tracesUrl}/${table}`,
    headers: 'auth',
  }),
  getTraces: ({
    page = 1,
    sortingField = '',
    sortDirection = '',
    query = '',
    filters = '',
  }) =>
    serverRequest({
      method: 'GET',
      url: `${tracesUrl}?page=${page}&sortingField=${sortingField}&sortDirection=${sortDirection}&query=${query}&filters=${filters}`,
      headers: 'auth',
    })({ page, sortingField, sortDirection }),
  getTraceById: (traceId) =>
    serverRequest({
      method: 'GET',
      url: `${tracesUrl}/${traceId}`,
      headers: 'auth',
    })(traceId),
  postTraces: serverRequest({
    method: 'POST',
    url: tracesUrl,
    headers: 'auth',
  }),
  putTraces: (data) =>
    serverRequest({
      method: 'PUT',
      url: `${tracesUrl}/${data.traceId}`,
      headers: 'auth',
    })(data),
  getTracesHistory: (traceId) =>
    serverRequest({
      method: 'GET',
      url: `${traceHistoryUrl}/${traceId}`,
      headers: 'auth',
    })(traceId),
  postDuplicates: (params) =>
    serverRequest({
      method: 'POST',
      url: duplicatesUrl,
      headers: 'auth',
    })(params),
};

const workflows = {
  getWorkflows: (data) =>
    serverRequest({
      method: 'GET',
      url: `${workflowUrl}${query_presenter.to_query(data)}`,
      headers: 'auth',
    })(),
  getWorkflowById: (id) =>
    serverRequest({
      method: 'GET',
      url: `${workflowUrl}/${id}`,
      headers: 'auth',
    })(id),
  putWorkflow: (data) =>
    serverRequest({
      method: 'PUT',
      url: `${workflowUrl}/${data.id}`,
      headers: 'auth',
    })(data),
  postWorkflow: (data) =>
    serverRequest({
      method: 'POST',
      url: `${workflowUrl}`,
      headers: 'auth',
    })(data),
  getWorkflowObjectHistory: ({ id, ...rest }) =>
    serverRequest({
      method: 'GET',
      url: `${workflowUrl}/${id}/history${query_presenter.to_query(rest)}`,
      headers: 'auth',
    })(),
  getWorkflowObjectVersion: ({ historyObjectId, id }) =>
    serverRequest({
      method: 'GET',
      url: `${workflowUrl}/${historyObjectId}/history/${id}`,
      headers: 'auth',
    })(),
};

const validations = {
  getSludge: serverRequest({
    method: 'GET',
    url: `${sludgeUrl}`,
    headers: 'auth',
  }),
  getAnalysesComp: serverRequest({
    method: 'GET',
    url: `${analysesCompUrl}`,
    headers: 'auth',
  }),
  getMud: serverRequest({
    method: 'GET',
    url: `${mudUrl}`,
    headers: 'auth',
  }),
  getSoil: serverRequest({
    method: 'GET',
    url: `${soilUrl}`,
    headers: 'auth',
  }),
  getStocking: serverRequest({
    method: 'GET',
    url: `${stockingUrl}`,
    headers: 'auth',
  }),
  getStockingDetails: (data) =>
    serverRequest({
      method: 'GET',
      url: `${stockingDetailsUrl}/${data.traceId}`,
      headers: 'auth',
    })(data),
};

const bk_boards = {
  get_traceup: ({ numerolot, type }) =>
    serverRequest({
      method: 'GET',
      url: `${bk_url}/${numerolot}.${type}`,
      headers: 'auth',
    })({ numerolot, type }),
};

const pages = {
  getPage: (name) =>
    serverRequest({
      method: 'GET',
      url: `${pagesURL}${name}`,
      headers: 'auth',
    }),
};

const notifications = {
  getNotifications: serverRequest({
    method: 'GET',
    url: `${notificationsURL}`,
    headers: 'auth',
  }),
  markNotificationAsRead: (id) =>
    serverRequest({
      method: 'POST',
      url: `${notificationsURL}/${id}/mark_as_read`,
      headers: 'auth',
    }),
};

const monitoring = {
  downloadReport: (pattern) =>
    serverRequest({
      method: 'GET',
      url: `${monitoringDownloadUrl}${pattern ? `?pattern=${pattern}` : ''}`,
      headers: 'auth',
    })(),
};

export default {
  login: serverRequest({
    method: 'POST',
    url: loginUrl,
  }),
  signup: serverRequest({
    method: 'POST',
    url: signupUrl,
  }),
  ...auth,
  ...commonIframe,
  ...traces,
  ...users,
  ...products,
  ...companies,
  ...sms,
  ...traceUpload,
  ...chains,
  ...validations,
  ...formWorker,
  ...stocks,
  ...bk_boards,
  ...customLists,
  ...documents,
  ...pages,
  ...notifications,
  ...smsConfirmation,
  ...commonDashboard,
  ...matomo,
  ...monitoring,
  ...workflows,
};

export { serverRequest };
