import { FC, useEffect, useState } from 'react';
import { Button, Descriptions, Flex, Form, message, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Badge from 'components/Badge';
import client from 'lib/apiClient';
import { store } from 'store/nextStore';
import { closeGenericModalAction } from 'containers/GenericModal/reducer';
import { supplierStatusFormModal } from 'sections/GenericTablePage/constants';
import { SWrapper } from './styled-components';

interface SupplierStatusFormProps {
  userValues: any;
  traceId: string;
  listName: string;
  isEdit: boolean;
  formSchema: { [key: string]: any };
  refreshData: () => void;
}

const SupplierStatusForm: FC<SupplierStatusFormProps> = ({
  userValues,
  traceId,
  listName,
  isEdit,
  formSchema,
  refreshData,
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const { t } = useTranslation();

  const fetchTraceData = async () =>
    await client.getCustomListByTraceId({
      traceId,
      listName,
    });

  useEffect(() => {
    fetchTraceData().then((result) => {
      setData(result);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async () => {
    const allFields = form.getFieldsValue(true);
    setIsSubmitting(true);

    try {
      await client.putCustomListItem({
        list_name: listName,
        id: data.id,
      })(allFields);

      message.success(t('messages.saved'));
      store.dispatch(closeGenericModalAction({ name: supplierStatusFormModal }));
      refreshData();
    } catch (e) {
      console.log('Error', e);
      message.error(`${t('errors.somethingWentWrong')} ${t('errors.tryAgainLater')}`);
      setIsSubmitting(false);
    }
  };

  return (
    <SWrapper>
      {data?.data ? (
        <Form
          name="edit_statuses"
          initialValues={data.data}
          onFinish={onFinish}
          form={form}
        >
          <Descriptions column={1}>
            {Object.keys(formSchema).map((fieldName) => {
              const fieldSchema = formSchema[fieldName];

              const statusProps = fieldSchema?.statuses?.filter(
                (status) => status.key === data.data[fieldName]
              )[0];

              return (
                !fieldSchema.hidden && (
                  <Descriptions.Item
                    key={fieldSchema?.type}
                    label={fieldSchema?.label}
                    className={`${fieldSchema?.type.toLowerCase()}`}
                  >
                    {fieldSchema?.type === 'Select' && isEdit ? (
                      <Form.Item
                        name={fieldName}
                        className="supplier-form-select-wrapper"
                      >
                        <Select
                          options={fieldSchema?.options}
                          className="supplier-form-select"
                        />
                      </Form.Item>
                    ) : fieldSchema?.type === 'Select' && !isEdit ? (
                      <Badge title={data.data[fieldName]} {...statusProps} />
                    ) : (
                      data.data[fieldName] || userValues[fieldName]
                    )}
                  </Descriptions.Item>
                )
              );
            })}
          </Descriptions>

          <Form.Item style={{ marginBottom: 0 }}>
            <Flex gap="small" justify="flex-end">
              {isEdit && (
                <Spin size="small" spinning={isSubmitting}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-button"
                  >
                    {t('common.save')}
                  </Button>
                </Spin>
              )}
            </Flex>
          </Form.Item>
        </Form>
      ) : (
        <Spin size="large" />
      )}
    </SWrapper>
  );
};

export default SupplierStatusForm;
