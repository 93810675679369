import { FC } from 'react';
import Badge from 'components/Badge';
import { BadgeProps } from 'components/Badge/badge';

interface FormBadgeProps {
  badgeProps: BadgeProps;
}

const FormBadge: FC<FormBadgeProps> = ({ badgeProps }) => {
  if (!badgeProps || Object.keys(badgeProps).length === 0) return null;

  return <Badge {...badgeProps} />;
};

export default FormBadge;
