import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IndustryIcon } from 'components/library/Icons';
import { store } from 'store/nextStore';
import { openGenericModalAction } from 'containers/GenericModal/reducer';
import { businessPickerModal } from 'components/Modal/constants';
import { SButton } from '../UserDropdown/styled-components';

interface ChangeBusinessBtnProps {
  id: string;
}

const ChangeBusinessBtn: FC<ChangeBusinessBtnProps> = ({ id }) => {
  const { t } = useTranslation();

  const onClick = () => {
    store.dispatch(
      openGenericModalAction({
        name: businessPickerModal,
        configuration: {
          title: '',
          width: '700px',
          prefixId: 'business_picker',
        },
        data: {
          data: null,
          isModal: true,
        },
      })
    );
  };

  return (
    <SButton type="text" id={id} onClick={onClick} className="inside">
      <IndustryIcon color={''} className="button-industry-icon" />
      {t('header.changeIndustry')}
    </SButton>
  );
};

export default ChangeBusinessBtn;
