import { FC } from 'react';
import { Descriptions, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { SWrapper } from './styled-components';

interface ContactSupplierProps {
  data: {
    contacts: {
      first_name: string;
      last_name: string;
      email: string;
      phone: string;
    }[];
  };
}

const ContactSupplier: FC<ContactSupplierProps> = ({ data }) => {
  const { t } = useTranslation();
  const { contacts } = data;

  return (
    <SWrapper>
      {contacts?.map((contact, index) => (
        <>
          <Descriptions
            key={`contact-${index}`}
            column={1}
            title={`Contact ${index + 1}`}
            className="contact-description"
          >
            <Descriptions.Item label={t('common.firstName')}>
              {contact.first_name}
            </Descriptions.Item>
            <Descriptions.Item label={t('common.lastName')}>
              {contact.last_name}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              <a href={`mailto:${contact.email}}`} className="email-link">
                {contact.email}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label={t('common.phoneNumber')}>
              {contact.phone}
            </Descriptions.Item>
          </Descriptions>
          {index + 1 !== contacts.length && <Divider />}
        </>
      ))}
    </SWrapper>
  );
};

export default ContactSupplier;