import styled from 'styled-components';
import { Button } from 'antd';

export const SReaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: 650px;

  @media (max-width: 1440px) {
    min-height: 550px;
  }

  .document-view-container {
    width: 48%;
  }

  .document-content-form-wrapper {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .document-content-form {
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .ant-form-item {
    &:last-child {
      margin-bottom: 0px;
    }

    .ant-form-item-label {
      padding: 0 0 6px;

      & > label {
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
    .ant-picker.ant-picker-focused,
    .ant-select:not(.ant-select-disabled).ant-select-focused
      .ant-select-selector {
      border: ${({ theme }) => theme.form.item?.input?.borderActive} !important;
      box-shadow: ${({ theme }) =>
        `0 0 0 2px ${theme.form.item?.input?.boxShadowActiveColor}`} !important;
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
      &:hover,
      &:focus {
        border: ${({ theme }) =>
          theme.form.item?.input?.borderActive} !important;
      }
    }
  }

  .document-fields {
    .ant-form-item {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
`;

export const SButton = styled(Button)`
  font-size: ${({ theme }) => theme.form.submitButton?.size} !important;
  font-weight: ${({ theme }) => theme.form.submitButton?.weight} !important;
  font-family: ${({ theme }) => theme.form.submitButton?.family} !important;
  color: ${({ theme }) => theme.form.submitButton?.color} !important;
  border: none !important;
  background: ${({ theme }) => theme.form.submitButton?.passive} !important;

  &:hover {
    color: ${({ theme }) => theme.form.submitButton?.color} !important;
    background: ${({ theme }) => theme.form.submitButton?.active} !important;
  }
`;
