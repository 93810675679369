import ColumnTitle from '../components/ColumnTitle';
import RowActions from './RowActions';
import Badge from 'components/Badge';
import LegendItem from 'components/LegendItem';

const addChildren = (column, record, onRowAction, historyObjectId) => {
  if (column.render) {
    return column.render(record);
  }

  if (column.actions) {
    return (
      <RowActions
        actions={column.actions}
        onRowAction={onRowAction}
        record={record}
        historyObjectId={historyObjectId}
      />
    );
  }

  if (column.badges) {
    const badgeKey = record[column.fieldName];
    const badgeData = column.badges.filter(
      (badge) => badge.key === badgeKey
    )[0];

    return badgeData ? (
      <Badge
        title={badgeData?.title}
        type={badgeData?.type}
        icon={badgeData?.icon}
        customColor={badgeData?.customColor}
        displayIcon={badgeData?.displayIcon}
        displayStar={badgeData?.displayStar}
      />
    ) : (
      ColumnTitle({ text: record[column.fieldName] })
    );
  }

  if (column.legend) {
    const legendItem = record[column.fieldName];
    const legendColor = column.legend[legendItem.name];

    return <LegendItem label={legendItem.label} color={legendColor} />;
  }

  if (column.haveWarning) {
    return ColumnTitle({
      text: record[column.fieldName]?.value,
      warning: record[column.fieldName]?.warning,
    });
  }

  return ColumnTitle({ text: record[column.fieldName] });
};

export default addChildren;
