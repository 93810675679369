import { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import { connect } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { Form, Field, Formik, FormikProps } from 'formik';
import BusinessCard from './components';
import {
  BUSINESS_PICKER_MODAL,
  businessPickerModal,
} from 'components/Modal/constants';
import { getBusinessData, verifyBusiness } from 'sections/Login/reducer';
import { resetAllDataAction, store } from 'store/nextStore';
import UniqIdsGenerator from 'lib/uniqIdsGenerator';
import { BusinessItemInterface } from 'sections/Login/types';
import { FormContent, PickerSpin } from './styled-components';
import { closeGenericModalAction } from 'containers/GenericModal/reducer';

const id_generator = new UniqIdsGenerator('business-picker');
const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

interface Props {
  data: any;
  currentBusiness?: any;
  verifyBusiness: Function;
  resetAllData: Function;
  getBusinessData: Function;
  isModal?: boolean;
}
const BusinessPicker = ({
  data,
  currentBusiness,
  verifyBusiness,
  resetAllData,
  getBusinessData,
  isModal = false,
}: Props) => {
  const [businessesData, setBusinessesData] = useState(data);
  const [focusedValue, setFocusedValue] = useState(null);
  const navigate: NavigateFunction = useNavigate();
  const { state }: any = useLocation();

  useEffect(() => {
    if (!businessesData) {
      getBusinessData(isModal)
        .then((res) => {
          setBusinessesData(res?.business_list);
        })
        .catch((err) => {
          console.log('Error: ', err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessesData]);

  const isDisabledBusiness = (business) => {
    return currentBusiness?.id === business?.id;
  };

  return (
    <Formik
      initialValues={{ businessId: null }}
      onSubmit={(values, actions) => {
        verifyBusiness(values)
          .then(({ forceReset = false }) => {
            sessionStorage.removeItem(BUSINESS_PICKER_MODAL);
            navigate(forceReset ? '/reset_password' : state?.path || '/');
            isModal &&
              store.dispatch(
                closeGenericModalAction({ name: businessPickerModal })
              );
          })
          .catch(() => {
            actions.setSubmitting(false);
            resetAllData();
            navigate('/');
          });
      }}
    >
      {({ isSubmitting, setFieldValue }: FormikProps<any>) => (
        <Form id={id_generator.get_id('formik')}>
          <Field name={'businessId'}>
            {({
              field, // { name, value, onChange, onBlur }
              form: { submitForm },
            }) => (
              <PickerSpin
                spinning={isSubmitting}
                wrapperClassName="business-picker-spin"
              >
                <FormContent
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (
                      e.nativeEvent.type === 'click' &&
                      e.nativeEvent.clientX !== 0 &&
                      e.nativeEvent.clientY !== 0
                    ) {
                      submitForm();
                    }
                  }}
                >
                  <Row gutter={[16, 16]}>
                    {!businessesData && <Spin />}
                    {businessesData?.map(
                      (item: BusinessItemInterface, index: number) => {
                        return (
                          <Col md={12} sm={24} key={index} className={'item'}>
                            <BusinessCard
                              {...item}
                              focusedValue={focusedValue}
                              disabled={isDisabledBusiness(item)}
                              onKeyDown={(e) => {
                                if (
                                  e.keyCode === ENTER_KEY_CODE ||
                                  e.keyCode === SPACE_KEY_CODE
                                ) {
                                  e.preventDefault();
                                  setFieldValue('businessId', +e.target.value);
                                  submitForm();
                                }
                              }}
                              onFocus={(e) => setFocusedValue(+e.target.value)}
                              onBlur={() => setFocusedValue(null)}
                            />
                          </Col>
                        );
                      }
                    )}
                  </Row>
                </FormContent>
              </PickerSpin>
            )}
          </Field>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ auth }) => ({
  currentBusiness: auth.currentUser?.attributes?.business,
});

const mapDispatchToProps = {
  verifyBusiness: verifyBusiness,
  resetAllData: resetAllDataAction,
  getBusinessData: getBusinessData,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPicker);
