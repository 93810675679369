import {
  USERS_MODAL,
  COMPANIES_MODAL,
  TRACES_MODAL,
  CHAINS_MODAL,
  SMS_MODAL,
  GALLERY_MODAL,
  DOCUMENTS_MODAL,
  HISTORY_MODAL,
  HISTORY_VERSION_MODAL,
  BUSINESS_PICKER_MODAL,
  SUPPORT_MODAL,
  WORKFLOW_MODAL,
} from './constants';

const styles = {
  [DOCUMENTS_MODAL]: `
  width: 800px !important;
  height: auto !important;
  .uploads {
    display: grid;
    a {
      margin-bottom: 7px;
    }
  }
  form {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
  .content-wrapper {
    width: 100%;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    display: flex;
    .ant-input {
      width: 100%;
    }
  }
  .flex-form {
    width: 400px;
    height: 730px;
  }
  .ant-radio-wrapper {
    width: 125px;
  }
  .range {
    display: -webkit-inline-box;
    p {
      line-height: 28px;
    }
    .ant-calendar-picker .ant-input {
      width: 265px !important;
      margin-left: 15px;
    }
  }
`,
  [TRACES_MODAL]: `
  width: 1100px !important;
  height: auto !important;
  .uploads {
    display: grid;
    a {
      margin-bottom: 7px;
    }
  }
  form {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
  .content-wrapper {
    width: 100%;
  }
  .ant-modal-content {
    width: 1100px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    display: flex;
    .ant-input {
      width: 100%;
    }
  }
  .flex-form {
    width: 400px;
    height: 730px;
  }
  .ant-radio-wrapper {
    width: 125px;
  }
  .range {
    display: -webkit-inline-box;
    p {
      line-height: 28px;
    }
    .ant-calendar-picker .ant-input {
      width: 265px !important;
      margin-left: 15px;
    }
  }
`,
  [USERS_MODAL]: `
  width: 820px !important;
  height: auto !important;
  .uploads {
    display: grid;
    a {
      margin-bottom: 7px;
    }
  }
  form {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
  .content-wrapper {
    width: 100%;
  }
  .ant-modal-content {
    width: 820px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    display: flex;
    .ant-input {
      width: 100%;
    }
  }
  .flex-form {
    width: 400px;
    height: 730px;
  }
  .ant-radio-wrapper {
    width: 125px;
  }
  .range {
    display: -webkit-inline-box;
    p {
      line-height: 28px;
    }
    .ant-calendar-picker .ant-input {
      width: 265px !important;
      margin-left: 15px;
    }
  }
  `,
  [COMPANIES_MODAL]: `
    width: 800px !important;
    height: auto !important;
    .uploads {
      display: grid;
      a {
        margin-bottom: 7px;
      }
    }
    form {
      justify-content: space-between;
      width: 100%;
      display: flex;
    }
    .content-wrapper {
      width: 100%;
    }
    .ant-modal-content {
      width: 800px;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      display: flex;
      .ant-input {
        width: 100%;
      }
    }
    .flex-form {
      width: 400px;
      height: 730px;
    }
    .ant-radio-wrapper {
      width: 125px;
    }
    .range {
      display: -webkit-inline-box;
      p {
        line-height: 28px;
      }
      .ant-calendar-picker .ant-input {
        width: 265px !important;
        margin-left: 15px;
      }
    }
  `,
  [SMS_MODAL]: `
    width: 650px !important;
    min-height: 500px;
    .content-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .ant-modal-content {
      width: 650px;
      min-height: 500px;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      min-height: 444px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  `,
  [CHAINS_MODAL]: `
    width: 90% !important;
    max-width: 1250px;
    height: auto;
    max-height: 900px;
    padding: 20px 0;
    top: 5vh;
    
    #chain-graph {
       max-height: 750px;
       max-width: 1200px;
    }

    .ant-page-header {
      padding-left: 0px;
    }
    .uploads {
      display: grid;
      a {
        margin-bottom: 7px;
      }
    }
    form {
      justify-content: space-between;
      width: 100%;
      display: flex;
    }
    .content-wrapper {
      width: 100%;
    }
    .ant-modal-content {
      width: 100%;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      display: flex;
      .ant-input {
        width: 100%;
      }
    }
    .flex-form {
      width: 950px;
      height: 700px;
    }
    .ant-radio-wrapper {
      width: 125px;
    }
    .range {
      display: -webkit-inline-box;
      p {
        line-height: 28px;
      }
      .ant-calendar-picker .ant-input {
        width: 265px !important;
        margin-left: 15px;
      }
    }
  `,
  [GALLERY_MODAL]: `
    height: 900px;
    
    .ant-modal-content {
      height: 100%;
    }
    .ant-modal-content, 
    .ant-modal-title {
      background-color: #f9f9f9;
    }
  `,
  [HISTORY_MODAL]: `
    width: 90% !important;
    max-width: 1250px;
    height: auto;
    
    .ant-table-wrapper .ant-pagination {
      background: inherit !important; 
    }
  `,
  [HISTORY_VERSION_MODAL]: `
    width: 1100px !important;
    height: auto !important;
  `,
  [BUSINESS_PICKER_MODAL]: `
    .ant-modal-content {
      border-radius: 6px;
    }
    .ant-modal-header {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding-bottom: 24px;
      margin-bottom: 0;
    }
    .ant-modal-body {
      padding-top: 24px;
    }
    .ant-modal-title {
      font-weight: 600;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .content-wrapper {
      padding: 0 !important;
      width: 100%;
    }
    .content-wrapper .business-picker-spin {
      width: 100%;
    }
    .content-wrapper form {
      width: 100%;
    }
  `,
  [SUPPORT_MODAL]: `
    .content-wrapper {
      padding: 20px 0;
    }
  `,
  [WORKFLOW_MODAL]: `
    width: 800px !important;

    form {
      justify-content: space-between;
      width: 100%;
      display: flex;
    }
    .content-wrapper {
      width: 100%;
    }
  `,
};

export default styles;
