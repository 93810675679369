import React, { useEffect, useState } from 'react';
import { StyledTooltip, STitleWrapper } from './style';
import { InfoBadge, InfoPopover } from 'components/library';

type Props = {
  text: string;
  helpType?: 'dot_legend';
  helpContent?: any;
};

interface CurrentRef {
  scrollWidth: number;
  clientWidth: number;
}

// TODO rename this component to ColumnTitle
const TableElement = ({ text, helpType, helpContent }: Props) => {
  const [is_tooltip, setTooltip] = useState<boolean>(false);
  const [is_active, setActive] = useState<boolean>(false);

  const ref = React.createRef();

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('keydown', handleKeyDown);

    updateDimensions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDimensions = () => {
    if (!ref || !ref.current) {
      return;
    }
  };

  const handleKeyDown = (e: any) => {
    const keys = { 123: true };
    if (keys[e.keyCode]) setTimeout(updateDimensions, 400);
  };

  const handleMouseOver = () => {
    const { scrollWidth, clientWidth } = ref.current as CurrentRef;
    setActive(true);

    if (scrollWidth > clientWidth) setTooltip(true);
  };

  const handleMouseLeave = () => {
    setActive(false);
    setTooltip(false);
  };

  const titleText = (
    <div
      className="column-title-text-wrapper"
      ref={ref as React.RefObject<HTMLDivElement>}
      onMouseOver={is_active ? null : handleMouseOver}
      onFocus={is_active ? null : handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {text}
    </div>
  );

  return (
    <STitleWrapper>
      {(is_tooltip && is_tooltip && (
        <StyledTooltip
          placement="bottomLeft"
          title={text}
          overlayClassName="custom-tooltip"
          mouseEnterDelay={300}
          mouseLeaveDelay={0}
          open={is_tooltip && is_tooltip}
        >
          {titleText}
        </StyledTooltip>
      )) ||
        titleText}
      {helpContent && (
        <InfoPopover
          triggerElement={
            <span style={{ marginLeft: '2px' }}>
              <InfoBadge color={'rgba(0, 0, 0, 0.65)'} />
            </span>
          }
          content={helpContent}
          type={helpType}
          triggerAction="hover"
        />
      )}
    </STitleWrapper>
  );
};

export default TableElement;
