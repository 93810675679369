import { CustomListItemForm } from 'sections/CustomList/containers/CustomListItemForm/custom-list-item-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from '../../../../components/HOC/withRouter';
import { handleSubmitAction } from './reducer';
import { get_documents } from '../DocumentListTable/reducer';

export class DocumentsItemForm extends CustomListItemForm {
  constructor(props) {
    super(props);
    this.state.type = 'file';
  }
}

const mapStateToProps = (state) => ({
  userValues: state.documentsModal.itemData,
});

const mapDispatchToProps = {
  handleSubmit: handleSubmitAction,
  get_custom_list_items: get_documents,
};

const composeResult = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DocumentsItemForm);

export default composeResult;
