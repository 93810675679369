import { FC } from 'react';
import { Button, Flex, Form, List, Select } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Badge from 'components/Badge';
import { SListItem } from './styled-components';
import DotItem from 'components/library/DotItem';

interface FilesListProps {
  fieldName: string;
  list: object[];
  isEdit: boolean;
  fileStatusEditable?: boolean;
  fields: any;
  statusOptions: {
    value: string;
    label: string;
    type?: string;
    displayIcon?: boolean;
    customColor?: {
      background: string;
      text: string;
    };
  }[];
  statusType?: 'badge' | 'dot' | false;
}

const FilesList: FC<FilesListProps> = ({
  list,
  fieldName,
  isEdit,
  statusOptions,
  fileStatusEditable = false,
  statusType = 'badge',
  fields,
}) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();

  const viewFile = (url) => {
    window.open(url, '_blank');
  };

  const nameField = fields.name.field;
  const statusField = fields.status.field;
  const urlField = fields.url.field;

  const getStatusElementInfo = (status) =>
    statusOptions?.filter((item) => item.value === status)[0];

  return (
    <List
      className="files-list"
      itemLayout="horizontal"
      dataSource={list}
      split={false}
      renderItem={(item: any, index: number) => {
        const statusName = form.getFieldValue(fieldName)[index][statusField];
        const statusProps = statusName ? getStatusElementInfo(statusName) : {};

        const defaultActions = [
          statusName !== 'Missing' && !!item[urlField] && (
            <Button
              key="list-view-file"
              onClick={() => viewFile(item[urlField])}
              className="list-view-file"
            >
              {t('common.view')}
            </Button>
          ),
        ];

        const actions =
          isEdit && fileStatusEditable
            ? [
                ...defaultActions,
                <Form.Item name={[fieldName, index, 'status']}>
                  <Select
                    key="list-select-status"
                    options={statusOptions}
                    className="list-select-status"
                  />
                </Form.Item>,
              ]
            : defaultActions;

        let statusElement;
        switch (statusType) {
          case false:
            statusElement = null;
            break;
          case 'dot':
            statusElement = <DotItem {...statusProps} />;
            break;
          default:
            statusElement = statusName && (
              <Badge title={statusName} {...statusProps} />
            );
            break;
        }

        return (
          <SListItem actions={actions}>
            <List.Item.Meta
              avatar={<FileOutlined />}
              title={
                <Flex align="center">
                  <p className="list-item-title">{item[nameField]}</p>
                  {statusElement}
                </Flex>
              }
            />
          </SListItem>
        );
      }}
    />
  );
};

export default FilesList;
