import styled from 'styled-components';
import { Select } from 'antd';
import { font } from 'App/styleVariables';

export const SSelect = styled(Select)`
  position: absolute;
  right: 0;
  font-family: ${font};
  width: 120px;
`;
