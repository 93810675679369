import { FC } from 'react';
import { Select, Space } from 'antd';
import ReactCountryFlag from 'react-country-flag';
import { SSelect } from './styled-components';

const { Option } = Select;

const LanguageSelector: FC<any> = ({
  language,
  languageList,
  selectorVariant = 'borderless',
  handleLanguageChange,
}) => {
  const handleChange = (value: string) => {
    handleLanguageChange(value);
  };

  return (
    <SSelect
      defaultValue={language}
      variant={selectorVariant}
      onChange={handleChange}
      popupClassName="header-language-selector-popup"
    >
      {languageList?.map((lang) => (
        <Option key={lang.value} value={lang.value}>
          <Space>
            <ReactCountryFlag
              countryCode={lang.countryCode}
              svg
              style={{
                width: '18px',
                height: '18px',
              }}
            />
            <span>{lang.label}</span>
          </Space>
        </Option>
      ))}
    </SSelect>
  );
};

export default LanguageSelector;
