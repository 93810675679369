import AbstractResourceFactory from './abstract-resource-factory';
import client from '../../../../../lib/apiClient';
import { Table } from '../../../types';
import { removeGaps, replaceEmptyStringsWithNullInObject } from 'lib/util';
import formErrorHandler from '../../formErrorHandler';

class TraceResourceFactory implements AbstractResourceFactory {
  defaultFilterState = {
    sortDirection: 'descend',
    sortingField: 'displayDate',
    filters: '%7B%7D',
  };
  async getAll(page = 1, searchField = '', filters: object): Promise<Table> {
    const traces = await client.getTraces({
      page,
      query: searchField,
      ...filters,
    });

    // Stubbed patch for the table badges
    const table_schema = await client.getTracesTable();

    const status_column_index = table_schema.findIndex(
      (item) => item.fieldName === 'displayBlockchainStatus'
    );

    if (status_column_index !== -1) {
      table_schema[status_column_index] = {
        ...table_schema[status_column_index],
        badges: [
          {
            key: 'SUCCESS',
            title: 'SUCCESS',
            type: 'success',
          },
          {
            key: 'REJECTED',
            title: 'REJECTED',
            type: 'rejected',
          },
          {
            key: 'ERROR',
            title: 'ERROR',
            type: 'error',
          },
          {
            key: 'QUEUED',
            title: 'QUEUED',
            type: 'queued',
          },
          {
            key: 'CHECKING',
            title: 'CHECKING',
            type: 'checking',
          },
          {
            key: 'PENDING',
            title: 'PENDING',
            type: 'pending',
          },
          {
            key: 'IN PROGRESS',
            title: 'IN PROGRESS',
            type: 'in_progress',
          },
          {
            key: 'INTERRUPTED',
            title: 'INTERRUPTED',
            type: 'interrupted',
          },
        ],
      };
    }

    return {
      data: traces?.content?.map((t) => ({ ...t, data: JSON.parse(t.data) })),
      schema: table_schema,
      total: traces.totalElements,
    };
  }

  async add({ data, actions }: any) {
    data = removeGaps(data);
    data = replaceEmptyStringsWithNullInObject(data);
    try {
      await client.postTraces({ data: JSON.stringify(data) });
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }

  async edit({ data, traceId, created_at, actions }: any) {
    data = removeGaps(data);
    data = replaceEmptyStringsWithNullInObject(data);
    const requestData = JSON.stringify(data);
    try {
      await client.putTraces({
        data: requestData,
        traceId,
        created_at,
      });
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      const error = formErrorHandler(e);
      throw typeof error === 'string' ? error : actions.setStatus(error);
    }
  }
}

export default TraceResourceFactory;
