import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledErrorPage } from './styled-components';

type Error = {
  message: string | null;
}

const ErrorPage = ({ message }: Error) => {
  const { t } = useTranslation();

  return (
    <StyledErrorPage>
      {message || `${t('errors.somethingWentWrong')} ${t('errors.tryAgainLater')}`}
    </StyledErrorPage>
  )
}

export default ErrorPage;