import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { openLink } from '../../../../lib/util';
import { ButtonLink } from './styled-components';

interface DownloadLinkProps {
  file: {
    url?: string;
    name?: string;
  };
}

const DownloadLink: React.FC<DownloadLinkProps> = ({ file }) => {
  const { t } = useTranslation();

  return (
    <ButtonLink onClick={() => openLink(file.url)} download>
      <DownloadOutlined />
      {t('common.downloadFile')}
    </ButtonLink>
  );
};

export default DownloadLink;