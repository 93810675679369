import React, { useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Wrapper, Confirm, Reset } from './style';

const { Option } = Select;

type Props = {
  setSelectedKeys: Function;
  clearFilters: Function;
  confirm: Function;
  filters: Array<any>;
};

const MultipleSelection = ({
  setSelectedKeys,
  clearFilters,
  confirm,
  filters,
}: Props) => {
  const [selectedValues, setSelectedValues] = useState<Array<string | undefined>>([]);
  const { t } = useTranslation();

  const handleChange = (value) => {
    setSelectedKeys(value);
  };

  const handleReset = () => {
    setSelectedValues([]);
    clearFilters();
    confirm();
  };

  const onSelect = (value) => {
    setSelectedValues([...selectedValues, value]);
  };

  const onDeselect = (value) => {
    setSelectedValues(selectedValues.filter((item) => item !== value));
  };

  return (
    <Wrapper>
      <Confirm onClick={() => confirm()}>{t('common.ok')}</Confirm>
      <Reset onClick={handleReset}>{t('common.reset')}</Reset>
      <div>
        <Select
          mode="multiple"
          style={{ width: '400px' }}
          placeholder="Please select"
          defaultValue={[]}
          onChange={handleChange}
          value={Array.from(selectedValues)}
          onSelect={onSelect}
          onDeselect={onDeselect}
        >
          {Array.isArray(filters) &&
            filters.map((filter) => (
              <Option key={filter.value}>{filter.text}</Option>
            ))}
        </Select>
      </div>
    </Wrapper>
  );
};

export default (props: Props) => <MultipleSelection {...props} />;
