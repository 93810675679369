import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import client from '../../lib/apiClient';
import { StyledButton } from './styled-components';

const ICONS = {
  download: DownloadOutlined,
};

interface Props {
  label: string;
  icon: string;
  options?: {
    pattern?: string;
  };
  className?: string;
}

const DownloadMonitoringReport = ({ label, icon, options, className }: Props) => {
  const { t } = useTranslation();
  const Icon = ICONS[icon];

  const handleClick = async () => {
    try {
      const res = await client.downloadReport(options?.pattern);

      if (res?.download_url) {
        window.open(res?.download_url, '_blank');
      } else {
        throw new Error(res?.error);
      }
    } catch (e) {
      console.log('Error: ', e);
      message.error(`${e.message || t('errors.unknown')}`);
    }
  };

  return (
    <StyledButton className={className} type="text" onClick={handleClick}>
      {Icon && <Icon />}
      <span className={'button-label'}>{label}</span>
    </StyledButton>
  );
};

export default DownloadMonitoringReport;
