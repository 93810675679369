import React, { Component } from 'react';
import { Button, Popover } from 'antd';
import { withTranslation } from 'react-i18next';
import UniqIdsGenerator from 'lib/uniqIdsGenerator';
import SearchData from 'containers/SearchData';
import withWindowSize from 'components/HOC/withWindowSize';
import { StyledTable, LikeLink, Header } from './style';
import { SearchWrapper } from '../../containers/SearchData/style';
import TableElement from './components/TableElement';
import Spinner from './components/Spinner';
import * as filters from '../../sections/GenericTablePage/helpers/filters'
import { getPreview } from '../../containers/Gallery/components/FileViewMiniature/get-file-preview';
import ColumnTitle from '../../sections/GenericTablePage/components/ColumnTitle';
import SortArrows from 'sections/GenericTablePage/components/SortArrows';
import { icons } from '../../sections/GenericTablePage/components/HeaderActions/header-actions';
import { openProtectedLink } from '../../lib/util';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const id_generator = new UniqIdsGenerator();

const statuses = {
  SUCCESS: true,
  REJECTED: true,
  INTERRUPTED: true,
  ERROR: true,
};

const excluded = {
  edit: { PB: true },
  show: { Kizéo: true },
};

const dates = { DateHeure: { format: 'DD:MM:YYYY' } };

// If it's stupid but it works, it isn't stupid.
const prepareForComparacing = (n) =>
  typeof n === 'string' || typeof n === 'number' ? n.toString() : ' ';
const compareItems = (a, b) =>
  a.localeCompare(b, 'en-US-u-kf-upper', {
    ignorePunctuation: true,
    numeric: true,
  });

export const sortAs = {
  string: (a, b, data) => {
    if (data === 'company.name') {
      if (a.company.name < b.company.name) return -1;
      if (a.company.name > b.company.name) return 1;
      return 0;
    }
    if (data === 'company.kind') {
      if (a.company.kind < b.company.kind) return -1;
      if (a.company.kind > b.company.kind) return 1;
      return 0;
    }
    if (a[data] || b[data]) {
      return compareItems(
        prepareForComparacing(a[data]),
        prepareForComparacing(b[data])
      );
    }
    return 0;
  },
  date: (a, b, data) => {
    if (data === 'data.date') {
      if (dayjs(a.data.date) < dayjs(b.data.date)) return -1;
      if (dayjs(b.data.date) < dayjs(a.data.date)) return 1;
      return 0;
    }
    if (data === 'last_sign_in_at') {
      const momentA = a[data] ? dayjs(a[data]) : dayjs(0);
      const momentB = b[data] ? dayjs(b[data]) : dayjs(0);
      if (momentA < momentB) return -1;
      if (momentB < momentA) return 1;
      return 0;
    }

    if (dates[data]) {
      const x = +dayjs(a[data], dates[data].format);
      const y = +dayjs(b[data], dates[data].format);

      if (x > y) return -1;
      else if (x < y) return 1;
      else return 0;
    }

    if (dayjs(a[data]) < dayjs(b[data])) return -1;
    if (dayjs(b[data]) < dayjs(a[data])) return 1;
    return 0;
  },
  number: (a, b, data) => a[data] - b[data],
};

const RowActions = ({
  actions,
  prefix_id,
  onRowAction,
  record,
  user,
  index = -1,
  business
}) =>
  actions.map(({ label, action }, fieldName) => {
    const { status, DisplayTraceTtype } = record;
    if (label === 'log' && !statuses[status]) return null;

    if (
      DisplayTraceTtype &&
      excluded[action] &&
      excluded[action][DisplayTraceTtype]
    )
      return null; // ugly solution

    if (action === 'openDocument') {
      return (
        <Popover
          content={
            <a
              // target={'_blank'}
              // rel="noreferrer"
              href={record?.file_url}
              onClick={(e) => {
                e.preventDefault();
                openProtectedLink(record?.file_url, business?.id);
              }}
            >
              {getPreview(record.file_url_href, 'table')}
            </a>
          }
          placement="left"
          key={fieldName}
        >
          <LikeLink key={fieldName}>
            <a
              // target={'_blank'}
              // rel="noreferrer"
              href={record.file_url}
              onClick={(e) => {
                e.preventDefault();
                openProtectedLink(record?.file_url, business?.id);
              }}
            >
              {label}
            </a>
          </LikeLink>
        </Popover>
      );
    }

    return (
      <LikeLink
        id={id_generator.get_id(prefix_id, 'btn', label, index)}
        key={fieldName}
        onClick={(e) => {
          e.preventDefault();
          onRowAction({ record, action });
        }}
      >
        {label}
      </LikeLink>
    );
  });

export const HeaderActions = ({ actions, handleClick, prefix_id, isAllowed }) =>
  actions?.map(({ label, action, icon }, fieldName) => {
    let button;
    const IconComponent = icons[icon];
    label === 'Add Upload' && !isAllowed
      ? (button = (
          <Button
            id={id_generator.get_id(prefix_id, 'disabled-btn')}
            key={fieldName}
            onClick={() => handleClick(action)}
            disabled
          >
            {icon && IconComponent && <IconComponent />}
            {label}
          </Button>
        ))
      : (button = (
          <Button
            id={id_generator.get_id(prefix_id, 'add-btn')}
            key={fieldName}
            onClick={() => handleClick(action)}
          >
            {icon && IconComponent && <IconComponent />}
            {label}
          </Button>
        ));

    return button;
  });

/* eslint react/prop-types: 0 */
class DataTable extends Component {
  prefix_id = this.props.prefixId;

  getColumns = ({ onRowAction }) => {
    const { columnsSchema, user, business } = this.props;
    let index = -1;

    const checkForWidth = (column) => column.isWidthFixed && column.width;

    const prepareToTable = (column, fieldName, data) => {
      // const dynamic_filters =
      //   (column.dynamic_filters || column.customFilter) &&
      //   generate_filters({
      //     data,
      //     field: column.fieldName,
      //   });

      return {
        title: <TableElement text={column.label} />,
        dataIndex: column.fieldName,
        orgIndex: (text, record) => column.fieldName,
        key: column.key ? column.key : column.fieldName,
        width: checkForWidth(column),
        defaultSortOrder: column.defaultSortOrder,
        sorter: column.sortAs
          ? (a, b) => sortAs[column.sortAs](a, b, column.fieldName)
          : '',
        // filters: dynamic_filters || (column.filters && column.filters),
        filters: column.filters,
        filterDropdown:
          !!column.customFilter &&
          function (callbacks) {
            return filters[column.customFilter]({
              ...callbacks,
              name: fieldName,
            });
          },
        ellipsis: true,
        sortIcon: ({ sortOrder }) => <SortArrows order={sortOrder} />,
        onFilter: (value, record) => {
          // FRangePicker filter is Range picker used for front-end paginated tables
          if (
            typeof value === 'object' &&
            value.filter === 'FRangePicker' &&
            Array.isArray(value.data)
          ) {
            return (
              dayjs(record[column.fieldName]).isSameOrAfter(
                value.data[0],
                'day'
              ) &&
              dayjs(record[column.fieldName]).isSameOrBefore(
                value.data[1],
                'day'
              )
            );
          }

          // default return
          return record[column?.fieldName]?.match(value);
        },
        // TODO test next row
        // onHeaderCell: () => ({ id: uniqId.getTableTheadTh(fieldName) }),
        render: (text, record) => {
          return {
            props: {
              className: 'classNameOfCell',
            },
            children: addChildren(column, record),
          };
        },
        sortDirections: ['ascend', 'descend', 'ascend'],
      };
    };

    const addChildren = (column, record) => {
      if (column.render) {
        return column.render(record);
      }
      if (column.actions) {
        index++;
        return (
          <RowActions
            {...column}
            user={user}
            prefix_id={this.prefix_id}
            onRowAction={onRowAction}
            record={record}
            index={index}
            business={business}
          />
        );
      }

      return <ColumnTitle text={record[column.fieldName]} />;
    };

    // columnsSchema && columnsSchema.forEach(getTotalColumnWidth);
    return (
      columnsSchema &&
      columnsSchema.map((column, index) =>
        prepareToTable(column, index, this.props.data)
      )
    );
  };

  render() {
    const {
      data,
      columnsSchema,
      staticData,
      getRowKey,
      onSearch,
      loading,
      handleClickOnTableRowAction,
      handleClickOnHeaderButton,
      headerActionsSchema,
      handleTableChange,
      isSearchDisabled,
      isAllowed,
      isBrokenTitle,
      additionalMenu,
      t,
    } = this.props;
    const { prefix_id } = this;

    return (
      <div>
        {columnsSchema && !!columnsSchema.length ? (
          <React.Fragment>
            <Header>
              {!isSearchDisabled && (
                <SearchWrapper>
                  <SearchData
                    id={id_generator.get_id(prefix_id, 'search-input')}
                    placeholder={t('common.search')}
                    onSearch={(e) => onSearch(e, staticData)}
                  />
                  {additionalMenu}
                </SearchWrapper>
              )}
              <HeaderActions
                actions={headerActionsSchema || []}
                prefix_id={prefix_id}
                handleClick={handleClickOnHeaderButton}
                isAllowed={isAllowed}
              />
            </Header>
            <StyledTable
              isBrokenTitle={isBrokenTitle}
              loading={loading}
              dataSource={data}
              rowKey={getRowKey}
              size="middle"
              onChange={handleTableChange}
              pagination={{ pageSize: 10, showSizeChanger: false }}
              onRow={(row, fieldName) => ({
                id: id_generator.get_id(prefix_id, 'row', fieldName),
              })}
              onHeaderRow={(row, fieldName) => ({
                id: id_generator.get_id(prefix_id, 'header'),
              })}
              columns={this.getColumns({
                onRowAction: (data) => handleClickOnTableRowAction(data),
              })}
              id={id_generator.getTableRoot('table_root')}
              showSorterTooltip={false}
            />
          </React.Fragment>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

export default withWindowSize(withTranslation()(DataTable));
