import styled from 'styled-components';
import { Pagination, Table } from 'antd';
import { blockShadow, borderRadius, colors } from 'App/styleVariables';

interface StyledTableProps {
  readonly theme: {
    [key: string]: string | object | null;
  };
  readonly isFrontTableControlled: boolean;
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  height: 40px;

  &.hidden {
    display: none;
  }

  .header-add-button {
    height: 40px;
    width: auto;
    max-width: 300px;
    font-size: ${({ theme }) => theme.buttonToAdd.size};
    font-weight: ${({ theme }) => theme.buttonToAdd.weight};
    font-family: ${({ theme }) => theme.buttonToAdd.family};
    color: ${({ theme }) => theme.buttonToAdd.passive};
    background: ${({ theme }) =>
      theme.buttonToAdd.backgroundPassive || colors.primary};
    outline: none !important;
    border: none !important;
    border-radius: ${({ theme }) =>
      theme.buttonToAdd.borderRadius || borderRadius} !important;
    box-shadow: ${({ theme }) =>
      theme.buttonToAdd.boxShadow || 'rgba(0, 0, 0, 0.25) 0 4px 4px 0'};

    &:focus {
      outline: none !important;
      border: none !important;
    }

    &:hover {
      color: ${({ theme }) => theme.buttonToAdd.active} !important;
      background: ${({ theme }) =>
        theme.buttonToAdd.backgroundActive || colors.secondary};
      outline: none !important;
      border: none !important;
      margin-top: ${({ theme }) => theme.buttonToAdd.marginTopActive};
    }
  }

  .header-button {
    height: 40px;
    width: auto;
    max-width: 200px;
    min-width: 135px;
    font-size: ${({ theme }) => theme.button.default.size || '15px'};
    font-weight: ${({ theme }) => theme.button.default.weight || 500};
    font-family: ${({ theme }) => theme.button.default.family};
    color: ${({ theme }) =>
      theme.button.default.passiveColor || colors.primary};
    border: ${({ theme }) =>
      theme.button.default.passiveBorder || `1px solid ${colors.primary}`};
    outline: none !important;
    border-radius: ${borderRadius} !important;

    &:focus {
      outline: none !important;
    }

    &:hover:not([disabled]) {
      color: ${({ theme }) =>
        theme.button.default.activeColor || colors.secondary} !important;
      border: ${({ theme }) =>
        theme.button.default.activeBorder ||
        `1px solid ${colors.secondary}`} !important;
      outline: none !important;
    }

    &:disabled {
      background-color: #ffffff;
    }
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40px !important;
  display: flex;
  align-items: center;

  .ant-input:not(.ant-input-disabled) {
    padding: 0 0 0 18px;
    height: 36px;
    max-width: 239px !important;
    color: ${({ theme }) => theme.searchInput.color} !important;
    font-size: ${({ theme }) => theme.searchInput.size} !important;
    font-weight: ${({ theme }) => theme.searchInput.weight} !important;
    font-family: ${({ theme }) => theme.searchInput.family} !important;
    border-radius: ${({ theme }) =>
      theme.searchInput?.borderRadius || borderRadius} !important;

    &:hover,
    &:focus {
      border-color: ${({ theme }) =>
        theme.searchInput?.borderColorActive || colors.secondary} !important;
    }

    &:focus {
      box-shadow: ${({ theme }) =>
        theme.searchInput?.boxShadowFocus} !important;
    }
  }

  input::placeholder {
    padding-left: 0 !important;
    color: ${({ theme }) => theme.searchInput.color} !important;
    font-size: ${({ theme }) => theme.searchInput.size} !important;
    font-weight: ${({ theme }) => theme.searchInput.weight} !important;
    font-family: ${({ theme }) => theme.searchInput.family} !important;
  }

  .ant-input-search-button {
    min-width: auto;
    height: 36px !important;
    font-size: ${({ theme }) => theme.searchButton?.size} !important;
    font-weight: ${({ theme }) => theme.searchButton?.weight} !important;
    font-family: ${({ theme }) => theme.searchButton?.family} !important;
    color: ${({ theme }) => theme.searchButton?.color} !important;
    background: ${({ theme }) => theme.searchButton?.passive} !important;
    margin-left: 10px;

    &:hover {
      color: ${({ theme }) =>
        theme.searchButton?.activeColor || 'white'} !important;
      background: ${({ theme }) => theme.searchButton?.active} !important;
    }
  }
  .ant-input-wrapper {
    padding-right: 10px !important;
    width: auto !important;
  }
`;

const SWrapperTable = styled.div<any>`
  background: ${({ theme }) => theme.table.background} !important;
  border-radius: ${borderRadius};
  box-shadow: ${blockShadow};
`;

const StyledTable = styled(Table)<StyledTableProps>`
  .ant-table {
    table {
      table-layout: fixed !important;
    }
  }

  tr {
    white-space: nowrap;

    div:not(.ant-table-filter-column) {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.ant-table-column-sort {
      background-color: transparent;
    }
  }

  th {
    word-break: normal;
    font-size: ${({ theme }) => theme.table.th.size} !important;
    font-weight: ${({ theme }) => theme.table.th.weight} !important;
    font-family: ${({ theme }) => theme.table.th.family} !important;
    color: ${({ theme }) => theme.table.th.color} !important;
    border-bottom: ${({ theme }) =>
      theme.table.th.border || '1px solid #EAECF0'} !important;
    background-color: ${({ theme }) =>
      theme.table.th.backgroundColor || '#F9FAFB'} !important;

    &:hover .custom-table-sorters {
      .sort-arrow:not(.active) {
        svg {
          fill: ${({ theme }) => `${theme.table.sorterArrow.active} !important`};
        }
      }
    }
  }

  .ant-table.ant-table-middle th {
    padding: 10px 10px !important;
  }

  .ant-table-thead > tr > th {
    &::before {
      display: none !important;
    }

    .ant-table-filter-column {
      display: flex;
      justify-content: flex-start;

      .ant-table-column-sorters {
        padding-right: 0;
      }
    }

    .ant-table-column-sorters {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .ant-table-filter-trigger {
      display: flex;
      align-items: center;
      margin-inline: 0;
      margin-left: 2px;

      &:hover {
        background: transparent;
      }

      &.active {
        color: ${({ theme }) => theme.table.sorterArrow.activeArrow || colors.primary}
      }
    }

    .ant-table-column-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      flex: initial !important;
    }
    
    .custom-table-sorters {
      margin-left: 6px;
    }
  }

  tbody tr:not(.ant-table-measure-row) td {
    font-size: ${({ theme }) => theme.table.tableElement.size} !important;
    font-weight: ${({ theme }) => theme.table.tableElement.weight} !important;
    font-family: ${({ theme }) => theme.table.tableElement.family} !important;
    color: ${({ theme }) => theme.table.tableElement.color} !important;

    &.ant-table-cell:first-child {
      font-weight: 600 !important;
    }
  }

  .ant-table.ant-table-middle tbody tr:not(.ant-table-measure-row) td {
    padding: 14px 10px !important;
  }

  .ant-pagination {
    margin: 20px 0 10px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    float: none !important;
    background: transparent !important;

    li {
      margin: 0 5px !important;
    }
  }

  .ant-pagination-item {
    margin: 0px 4px !important;
    height: 32px !important;
    min-width: 32px !important;
    border-radius: ${({ theme }) =>
      theme.table.pagination.passiveItem.borderRadius || borderRadius};
    background: ${({ theme }) =>
      theme.table.pagination.passiveItem.background} !important;

    a {
      font-size: ${({ theme }) =>
        theme.table.pagination.passiveItem.size} !important;
      font-weight: ${({ theme }) =>
        theme.table.pagination.passiveItem.weight} !important;
      font-family: ${({ theme }) =>
        theme.table.pagination.passiveItem.family} !important;
      color: ${({ theme }) =>
        theme.table.pagination.passiveItem.color} !important;
      line-height: 32px !important;
    }
  }

  .ant-pagination-item-active {
    margin: 0px 4px !important;
    height: 32px !important;
    min-width: 32px !important;
    border: none !important;
    border-radius: ${({ theme }) =>
      theme.table.pagination.passiveItem.borderRadius || borderRadius};
    background: ${({ theme }) =>
      theme.table.pagination.activeItem.background} !important;

    a {
      font-size: ${({ theme }) =>
        theme.table.pagination.activeItem.size} !important;
      font-weight: ${({ theme }) =>
        theme.table.pagination.activeItem.weight} !important;
      font-family: ${({ theme }) =>
        theme.table.pagination.activeItem.family} !important;
      color: ${({ theme }) =>
        theme.table.pagination.activeItem.color} !important;
      line-height: 32px !important;
    }
  }

  .ant-pagination-item-link {
    font-size: ${({ theme }) => theme.table.pagination.arrow.size} !important;
    font-weight: ${({ theme }) =>
      theme.table.pagination.arrow.weight} !important;
    font-family: ${({ theme }) =>
      theme.table.pagination.arrow.family} !important;
    color: ${({ theme }) => theme.table.pagination.arrow.passive} !important;

    &:hover {
      color: ${({ theme }) => theme.table.pagination.arrow.active} !important;
    }
  }
`;

const LikeLink = styled.span`
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin: 0 8px 0 0;
  color: ${({ theme }) => theme.table.tableLink.passive} !important;
  font-size: ${({ theme }) => theme.table.tableLink.size} !important;
  font-weight: ${({ theme }) => theme.table.tableLink.weight} !important;
  font-family: ${({ theme }) => theme.table.tableLink.family} !important;

  &:hover {
    color: ${({ theme }) => theme.table.tableLink.active} !important;
  }
`;

const StyledPagination = styled(Pagination)`
  padding: 20px 0 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  float: none !important;
  background: transparent !important;

  li {
    margin: 0 5px !important;
  }

  .ant-pagination-item {
    margin: 0px 4px !important;
    height: 32px !important;
    min-width: 32px !important;
    border-radius: ${({ theme }) =>
      theme.table.pagination.activeItem.borderRadius || borderRadius};
    background: ${({ theme }) =>
      theme.table.pagination.passiveItem.background} !important;

    a {
      font-size: ${({ theme }) =>
        theme.table.pagination.passiveItem.size} !important;
      font-weight: ${({ theme }) =>
        theme.table.pagination.passiveItem.weight} !important;
      font-family: ${({ theme }) =>
        theme.table.pagination.passiveItem.family} !important;
      color: ${({ theme }) =>
        theme.table.pagination.passiveItem.color} !important;
      line-height: 32px !important;
    }
  }

  .ant-pagination-item-active {
    margin: 0px 4px !important;
    height: 32px !important;
    min-width: 32px !important;
    border: none !important;
    border-radius: ${({ theme }) =>
      theme.table.pagination.activeItem.borderRadius || borderRadius};
    background: ${({ theme }) =>
      theme.table.pagination.activeItem.background} !important;

    a {
      font-size: ${({ theme }) =>
        theme.table.pagination.activeItem.size} !important;
      font-weight: ${({ theme }) =>
        theme.table.pagination.activeItem.weight} !important;
      font-family: ${({ theme }) =>
        theme.table.pagination.activeItem.family} !important;
      color: ${({ theme }) =>
        theme.table.pagination.activeItem.color} !important;
      line-height: 32px !important;
    }
  }
  .ant-pagination-item-link {
    font-size: ${(props) => props.theme.table.pagination.arrow.size} !important;
    font-weight: ${(props) =>
      props.theme.table.pagination.arrow.weight} !important;
    font-family: ${(props) =>
      props.theme.table.pagination.arrow.family} !important;
    color: ${(props) => props.theme.table.pagination.arrow.passive} !important;
    &:hover {
      color: ${(props) => props.theme.table.pagination.arrow.active} !important;
    }
  }
`;

export {
  Header,
  SearchWrapper,
  SWrapperTable,
  StyledTable,
  LikeLink,
  StyledPagination,
};
