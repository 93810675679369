import React, { useState } from 'react';
import { Button, Spin, Tooltip } from 'antd';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DownloadMonitoringReport from 'components/DownloadMonitoringReport';
import tableActionHandler from './helpers/tableActionHandler';
import { deactivatePerfectDuplicates } from './helpers/deactivatePerfectDuplicates';
import { downloadCsv } from 'sections/CommonDashboard/util';
import StyledTableWrapper from './styled-component';

const FrontEndTableComponent = React.lazy(
  () => import('sections/GenericTablePage/table/front-end-paginated-table')
);

interface TableProps {
  data: any;
  table_scheme: any;
  scroll?: object;
  isSearchEnabled?: boolean;
  searchButtonText?: boolean;
  searchPlaceholder?: string;
  pagination?: object | boolean;
  title?: string;
  subtitle?: string;
  image?: string;
  indicators?: string[];
  download?: boolean;
  downloadButtonPosition?: 'top' | 'bottom' | undefined;
  bordered?: boolean;
  exportButtonText?: string;
  exportFilename?: string;
  exportFullData?: boolean;
  downloadMonitoringReport?: boolean;
  downloadMonitoringReportButtonText?: string;
  downloadMonitoringReportPattern?: string;
  businessName?: string;
  options: {
    expandArrayObjects: boolean;
    unwindArrays: boolean;
    excludeKeys: string[];
  };
  footerDescription?: string;
  deactivation?: boolean;
  deactivationButtonText?: string;
  deactivationButtonTooltip?: string;
  deactivationListName?: string;
  refetchContent: () => void;
  user: any;
  form_scheme?: object;
  refreshData: any;
  setRefreshContent?: () => void;
  styles: {
    size: {
      column: {
        start: number;
        end: number;
      };
      row: {
        start: number;
        end: number;
      };
    };
  };
}

const FrontendPaginatedTable = ({
  data,
  table_scheme,
  styles,
  scroll = { y: 290 },
  isSearchEnabled = false,
  searchButtonText = true,
  searchPlaceholder,
  pagination = false,
  title,
  subtitle,
  image,
  indicators,
  download = false,
  downloadButtonPosition = 'top',
  bordered = false,
  exportButtonText,
  exportFilename,
  exportFullData = false,
  downloadMonitoringReport = false,
  downloadMonitoringReportButtonText,
  downloadMonitoringReportPattern,
  businessName,
  options = { expandArrayObjects: true, unwindArrays: true, excludeKeys: [] },
  footerDescription,
  deactivation,
  deactivationButtonText = 'Deactivate traces',
  deactivationButtonTooltip,
  deactivationListName,
  refetchContent,
  form_scheme,
  user,
  refreshData,
  setRefreshContent,
}: TableProps) => {
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const { t } = useTranslation();

  const imageSrc = `${process.env.REACT_APP_BUCKET_NAME}/${businessName}/public/images/${image}`;

  const handleDeactivation = () => {
    setIsDeactivating(true);

    deactivatePerfectDuplicates(data, deactivationListName, t)
      .then(() => refetchContent())
      .finally(() => setIsDeactivating(false));
  };

  return (
    <StyledTableWrapper
      styles={styles}
      download={download && data.length > 0}
      $bordered={bordered}
    >
      {(title ||
        (download && data.length > 0 && downloadButtonPosition === 'top') ||
        (downloadMonitoringReport && downloadButtonPosition === 'top')) && (
        <div className={'header'}>
          {title && (
            <div>
              <div className={'title-wrapper'}>
                {image && (
                  <img
                    src={imageSrc}
                    className={'title-icon'}
                    alt="Table icon"
                  />
                )}
                <p className={'title'}>{title}</p>
              </div>
              {subtitle && <p className={'subtitle'}>{subtitle}</p>}
            </div>
          )}
          {indicators && (
            <div className={'indicators-wrapper'}>
              {indicators.map((item, index) => (
                <p key={`indicator-${index}`} className={'indicator'}>
                  {' '}
                  {item}{' '}
                </p>
              ))}
            </div>
          )}
          {download && downloadButtonPosition === 'top' && data.length > 0 && (
            <Button
              onClick={() =>
                downloadCsv(
                  data,
                  table_scheme,
                  exportFilename,
                  exportFullData,
                  options,
                  t
                )
              }
              className={'download-btn'}
            >
              <DownloadOutlined />
              {exportButtonText && <span>{exportButtonText}</span>}
            </Button>
          )}
          {downloadMonitoringReport && downloadButtonPosition === 'top' && (
            <DownloadMonitoringReport
              label={downloadMonitoringReportButtonText}
              icon="download"
              options={{
                pattern: downloadMonitoringReportPattern,
              }}
              className="download-btn"
            />
          )}
        </div>
      )}
      <React.Suspense
        fallback={<Spin style={{ display: 'block' }} size="large" />}
      >
        <FrontEndTableComponent
          dataSource={data}
          columns={table_scheme}
          pagination={pagination}
          getRowKey={(record: any) => record.ProjectId || record.id}
          onRowAction={({
            record,
            action,
          }: {
            record: object;
            action: string;
          }) =>
            tableActionHandler(action, record, {
              businessName,
              formSchema: form_scheme,
              isAdmin: user.role === 'Admin',
              refreshData: refreshData,
              setRefreshContent,
              user,
              t,
            })
          }
          isSearchEnabled={isSearchEnabled}
          searchButtonText={searchButtonText}
          searchPlaceholder={searchPlaceholder}
          scroll={scroll}
        />
      </React.Suspense>
      <div className={'footer'}>
        {footerDescription && (
          <p className="footer-description">{footerDescription}</p>
        )}
        {download && downloadButtonPosition === 'bottom' && data.length > 0 && (
          <Button
            onClick={() =>
              downloadCsv(
                data,
                table_scheme,
                exportFilename,
                exportFullData,
                options,
                t
              )
            }
            className="download-btn"
          >
            <DownloadOutlined />
            {exportButtonText && <span>{exportButtonText}</span>}
          </Button>
        )}
        {downloadMonitoringReport && downloadButtonPosition === 'bottom' && (
          <DownloadMonitoringReport
            label={downloadMonitoringReportButtonText}
            icon="download"
            options={{
              pattern: downloadMonitoringReportPattern,
            }}
            className="download-btn"
          />
        )}
        {deactivation && data.length > 0 && (
          <Spin spinning={isDeactivating}>
            <Button
              onClick={() => handleDeactivation()}
              className={'deactivate-btn'}
            >
              {deactivationButtonText && <span>{deactivationButtonText}</span>}
              {deactivationButtonTooltip && (
                <Tooltip
                  id="indicatorTooltip"
                  title={deactivationButtonTooltip}
                  color={'#ffffff'}
                >
                  <span className="info-icon">
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              )}
            </Button>
          </Spin>
        )}
      </div>
    </StyledTableWrapper>
  );
};

const mapStateToProps = ({
  auth: {
    currentUser: { attributes },
  },
}) => ({ user: attributes });

export default connect(mapStateToProps, null)(FrontendPaginatedTable);
