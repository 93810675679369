import { message } from 'antd';
import client from 'lib/apiClient';

const deactivation = async (
  traceId: string,
  type: 'trace' | 'object',
  id?: string,
  listName?: string,
) => {
  const traceData = await client.getTraceById(traceId);

  const { data } = traceData;
  const jsonData = JSON.parse(data[0]);

  jsonData.Deactivated = 'true';

  if (type === 'object') {
    await client.putCustomListItem({
      list_name: listName,
      id: id,
    })(jsonData);
  } else {
    await client.putTraces({
      data: JSON.stringify(jsonData),
      traceId,
    });
  }
};

export const deactivatePerfectDuplicates = async (tableData, listName, t) => {
  try {
    const perfectDuplicates = tableData.map((trace) => {
      const { ParentId, ErrorType, ParentType, DataqualityID } = trace;

      if (ErrorType?.startsWith('Perfect Duplicate with')) {
        const match = ErrorType.match(/Perfect Duplicate with\s(.*)/);
        const traceId = match ? match[1] : null;

        return {
          ParentId,
          traceId,
          ParentType,
          DataQualityTraceId: DataqualityID,
        };
      } else {
        return null;
      }
    });

    const actualPerfectDuplicates = await client.postDuplicates(perfectDuplicates);

    if (actualPerfectDuplicates.length === 0)
      return message.info(t('messages.noActualPerfectDuplicates'));

    await Promise.all(
      actualPerfectDuplicates.map(
        async ({ traceid, quality_traceid, quality_id }) => {
          await deactivation(traceid, 'trace');

          await deactivation(quality_traceid, 'object', quality_id, listName);
        }
      )
    );

    message.success(t('messages.perfectDuplicatesDeactivated'));
  } catch (e) {
    console.log('Error:', e.message);
    message.error(t('errors.somethingWentWrong'));
    throw new Error('Error while deactivation');
  }
};
