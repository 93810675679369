import { FC, useState } from 'react';
import { Button, Divider, Dropdown, message, Space } from 'antd';
import { QueryObserverResult, useQuery } from 'react-query';
import UniqIdsGenerator from 'lib/uniqIdsGenerator';
import { DropdownContainer } from './styled-components';
import apiClient from 'lib/apiClient';
import DropdownItem from './components/DropdownItem';
import { LoadingOutlined } from '@ant-design/icons';

const prefix_id = 'generic-header-actions';
const idGenerator = new UniqIdsGenerator(prefix_id);

interface ExportTemplatesButtonProps {
  label: string;
  icon?: FC;
  uploadType: 'trace' | 'object';
}

const ExportTemplatesButton: FC<ExportTemplatesButtonProps> = ({
  label,
  icon: Icon,
  uploadType,
}) => {
  const [traceTypes, setTraceTypes] = useState<any>(null);

  const { isFetching, refetch }: QueryObserverResult<any, any> = useQuery(
    ['getUploadTypes'],
    async () => {
      const response = await apiClient.getUploadKinds({
        upload_type: uploadType,
      });

      return response?.data;
    },
    {
      enabled: false, // only run when refetch is called
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (result) => {
        setTraceTypes(result);
      },
      onError: (error) => {
        console.log('Error while fetching upload types: ', error);
        message.error('Something went wrong.');
      },
    }
  );

  const handleClick = () => {
    if (!traceTypes && !isFetching) {
      refetch();
    }
  };

  return (
    <Dropdown
      menu={{ items: traceTypes }}
      trigger={['click']}
      placement="bottomRight"
      dropdownRender={(_menu) => {
        return (
          <DropdownContainer>
            <Space direction="vertical">
              {traceTypes &&
              typeof traceTypes === 'object' &&
              Object.keys(traceTypes).length > 0 ? (
                <>
                  {Object.keys(traceTypes).map((key, index) => {
                    return (
                      <DropdownItem
                        key={`${key}-${index}`}
                        label={key}
                        traceType={key}
                      />
                    );
                  })}
                  <Divider />
                  <DropdownItem key={'All'} label={'All'} />
                </>
              ) : isFetching ? (
                <LoadingOutlined />
              ) : (
                'No data'
              )}
            </Space>
          </DropdownContainer>
        );
      }}
    >
      <Button
        onClick={() => handleClick()}
        id={idGenerator.getTrimmedString(label)}
        className={`header-button`}
      >
        <Space size={4}>
          {Icon && <Icon />}
          {label}
        </Space>
      </Button>
    </Dropdown>
  );
};

export default ExportTemplatesButton;
