import { FC } from 'react';
import { SSortersWrapper } from './styled-components';
import { Arrow } from 'components/library/Icons';

interface SortArrowsProps {
  order: 'ascend' | 'descend';
}

const SortArrows: FC<SortArrowsProps> = ({ order }) => {
  return (
    <SSortersWrapper className="custom-table-sorters">
      <span className={`sort-arrow up ${order === 'ascend' ? 'active' : ''}`}>
        <Arrow type="up" />
      </span>
      <span
        className={`sort-arrow down ${order === 'descend' ? 'active' : ''}`}
      >
        <Arrow />
      </span>
    </SSortersWrapper>
  );
};

export default SortArrows;
